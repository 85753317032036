// message.js
import Vue from 'vue'
import reconfirm from './main.vue'

const ReconfirmConstructor = Vue.extend(reconfirm)
let instance

const CbReconfirm = function(options = {}) {
  return new Promise((resolve, reject) => {
    // 初始化参数
    instance = new ReconfirmConstructor({
      data: options
    })
    // 销毁实例 删除节点
    const destroyEl = () => {
      instance.$destroy(true)
      instance.$el.parentNode.removeChild(instance.$el)
    }
    // 注册取消的方法
    instance.close = () => {
      destroyEl()
      reject('')
    }
    // 注册确定
    instance.confirm = () => {
      destroyEl()
      resolve()
    }
    // 挂载
    instance.$mount()
    document.body.appendChild(instance.$el)
  })
}
export default CbReconfirm
