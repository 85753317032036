// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}

const routerMap = {
  wagesIndex: {
    name: 'wagesIndex',
    // subName: '薪酬',
    component: view.blank
  },
  wagePayroll: {
    name: 'wagePayroll',
    // subName: '计薪',
    component: () => import('@/pages/wage/wagePayroll')
  },
  wageArchives: {
    name: 'wageArchives',
    // subName: '档案',
    component: () => import('@/pages/wage/wageArchives')
  },
  wageTaxes: {
    name: 'wageTaxes',
    // subName: '个税',
    component: () => import('@/pages/wage/wageTaxes')
  },
  wageSlip: {
    name: 'wageSlip',
    // subName: '工资条',
    component: () => import('@/pages/wage/wageSlip')
  },
  wageAnnualBonus: {
    name: 'wageAnnualBonus',
    // subName: '年终奖',
    component: () => import('@/pages/wage/wageAnnualBonus')
  },
  wageReportForm: {
    name: 'wageReportForm',
    // subName: '报表',
    component: () => import('@/pages/wage/wageReportForm')
  },
  // wageAnalysis: {
  //   name: 'wageAnalysis',
  //   // subName: '分析',
  //   component: () => import('@/pages/wage/wageAnalysis')
  // },
  wageSetting: {
    name: 'wageSetting',
    // subName: '设置',
    component: () => import('@/pages/wage/wageSetting')
  }
}

export default routerMap
