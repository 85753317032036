<template>
  <div
    class="text-row"
    :class="{
      'wkflow-error-tip': !!record.options.baseInfoList[0].labelErrorMsg || record.hasErrorValid
    }"
  >
    <template v-for="(item, index) in record.options.baseInfoList">
      <div :key="`baseInfoList${index}`" class="baseInfoItemBox">
        <div class="label" :class="{ 'require': item.isRequire}">{{ item.label }}</div>
        <div class="right">
          <div class="value">{{ item.placeholder }}</div>
          <a-icon v-if="item.placeholder.indexOf('请选择') > -1" type="right" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import mixins from '../mixins'
export default {
  name: 'Dept',
  mixins: [mixins],
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="less" scoped>
.require{
  &::after{
    content: '*';
    color: red;
  }
}
.baseInfoItemBox{
  display: flex;
  justify-content: space-between!important;
  align-items: flex-start!important;
  .label{
    width: 120px;
  }
  .right{
    display: flex;
    align-items: center;
    width: 100%;
    color: @sc-grey-40;
    .value{
      width: 100%;
      text-align: right;
    }
  }
}
</style>
