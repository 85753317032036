<template>
  <div class="create-approval">
    <div class="create-approval-main">
      <div class="form-design">
        <div class="form-design-middle">
          <div class="form-design-middle-inner">
            <div class="phone-wrapper">
              <div class="phone-wrapper-screen">
                <div class="field-list">
                  <div class="field-list-header">{{ flowSetting.flowName || '未命名审批' }}</div>
                  <div class="field-list-drop-area">
                    <div class="field-list-empty">
                      <div class="field-list-empty-inner">
                        <div class="field-list-tip">
                          <span v-show="dataListLength === 0" class="field-list-tip-info">选择左侧控件，拖动到此处</span>
                          <template>
                            <slot />
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhonePanl',
  props: {
    dataListLength: {
      type: Number,
      default: 0
    },
    flowSetting: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.create-approval {
	// width: 100%;
	height: 100%;
	// min-width: 1200px;
	min-height: 460px;
	.create-approval-main{
    // height: calc(100% - 84px);
    height: 100%;
	}
}
.form-design {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: #fff;
	.form-design-middle {
    width: 100%;
    height: 100%;
		.form-design-middle-inner {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			height: 86%;
			top: 7%;
		}
	}
}
.phone-wrapper{
	height: 100%;
	box-shadow: 2px 4px 12px 0 rgba(0, 0, 0,0.16), inset 0 -1px 1px 0 #c9cbd5, inset -1px 0 4px 0 rgba(57, 63, 89,0.09);
	padding: 12px 12px 28px;
	border: 1px solid #eee;
	border-radius: 24px;
	background-color: #fff;
	.phone-wrapper-screen {
    height: 100%;
    width: 285px;
    max-width: 300px;
    border-radius: 16px;
    border: 1px solid rgba(0,0,0,.1);
    background-color: @sc-greyBg-20;
		.field-list {
			height: 100%;
			border-radius: 16px;
			background-color: #f5f6f7;
			.field-list-header {
				font-family: PingFang SC,Microsoft YaHei;
				font-size: 14px;
				margin-bottom: 4px;
				width: 100%;
				height: 42px;
				padding: 0 1em;
				border-top-left-radius: 16px;
				border-top-right-radius: 16px;
				line-height: 42px;
				text-align: center;
				font-weight: 500;
				color: #1f2329;
				background-color: #fff;
				box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.field-list-drop-area {
				width: 100%;
				height: calc(100% - 46px);
				background-color: @sc-greyBg-50;
				overflow-x: visible;
				border-bottom-left-radius: 16px;
				border-bottom-right-radius: 16px;
				.field-list-empty {
					position: relative;
					height: 100%;
					// padding: 12px;
					.field-list-empty-inner {
						width: 100%;
						height: 100%;
						font-family: PingFang SC,Microsoft YaHei;
						font-size: 16px;
						line-height: 24px;
						color: #1f2329;
						border: 1px dashed #a1a5ad;
						border-radius: 2px;
						background-color: #eff0f1;
						.field-list-tip {
              text-align: center;
              height: 100%;
              position: relative;
							.field-list-tip-info{
                position: absolute;
                top: 0;
                left: 45px;
								display: inline-block;
								font-family: PingFang SC,Microsoft YaHei;
								font-size: 14px;
								line-height: 20px;
								color: #646a73;
								text-align: center;
                margin-top: 144px;
                z-index: 9;
							}
						}
					}
				}
			}
		}
	}
}
</style>
