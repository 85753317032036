// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}

const routerMap = {
  organization: {
    name: 'organization',
    // subName: '组织',
    component: view.blank
  },
  framework: {
    name: 'organizationFramework',
    // subName: '结构',
    component: () => import('@/pages/organization/framework')
  },
  post: {
    name: 'organizationPost',
    // subName: '岗位',
    component: () => import('@/pages/organization/post')
  },
  rank: {
    name: 'organizationRank',
    // subName: '职级',
    component: () => import('@/pages/organization/rank')
  },
  duties: {
    name: 'organizationDuties',
    // subName: '职务',
    component: () => import('@/pages/organization/duties')
  },
  orgReportForm: {
    name: 'organizationOrgReportForm',
    // subName: '报表',
    component: () => import('@/pages/organization/reportForm')
  }
}

export default routerMap
