// 百分号
export function getData1(num) {
  if (!num && num !== 0) return
  num = num.toString()
  var array = num.toString().split('')
  var index = -3
  while (array.length + index > 0) {
    array.splice(index, 0, ',')
    index -= 4
  }
  return array.join('')
}

// 添加百分号和两位小数
export function getData(data, len) {
  let num = toFixedFun(data, len)
  if (!num && num !== 0) return
  var decimalPart = ''
  var decimalPart1 = ''
  var decimalPart2 = ''
  num = num.toString()
  if (num.indexOf('-') !== -1) {
    num = num.split('-')[1]
    decimalPart2 = '-'
  }
  if (num.indexOf('.') !== -1) {
    // num.split('.')[1].length==1?num.split('.')[1].join('')+'0' : num.split('.')[1].join('')+'00'
    decimalPart1 = num.split('.')[1].length === 1 ? num.split('.')[1] + '0' : num.split('.')[1]
    decimalPart = '.'
    // decimalPart = '.' + num.split('.')[1].length==1?num.split('.')[1].join('')+'0' : num.split('.')[1].join('')+'00';
    num = parseInt(num.split('.')[0])
  }
  var array = num.toString().split('')
  var index = -3
  while (array.length + index > 0) {
    array.splice(index, 0, ',')
    index -= 4
  }
  return decimalPart2 + array.join('') + (decimalPart ? decimalPart + decimalPart1 : '.00')
}

// 保留小数 len确定位数
export function toFixedFun(data, len) {
  // debugger
  const number = Number(data)
  if (isNaN(number) || number >= Math.pow(10, 21)) {
    return number.toString()
  }
  if (typeof (len) === 'undefined' || len === 0) {
    return (Math.round(number)).toString()
  }
  let result = number.toString()
  const numberArr = result.split('.')

  if (numberArr.length < 2) {
    // 整数的情况
    return padNum(result)
  }
  const intNum = numberArr[0] // 整数部分
  const deciNum = numberArr[1]// 小数部分
  const lastNum = deciNum.substr(len, 1)// 最后一个数字

  if (deciNum.length === len) {
    // 需要截取的长度等于当前长度
    return result
  }
  if (deciNum.length < len) {
    // 需要截取的长度大于当前长度 1.3.toFixed(2)
    return padNum(result)
  }
  // 需要截取的长度小于当前长度，需要判断最后一位数字
  result = `${intNum}.${deciNum.substr(0, len)}`
  if (parseInt(lastNum, 10) >= 5) {
    // 最后一位数字大于5，要进位
    const times = Math.pow(10, len) // 需要放大的倍数
    let changedInt = Number(result.replace('.', ''))// 截取后转为整数
    changedInt++ // 整数进位
    changedInt /= times// 整数转为小数，注：有可能还是整数
    result = padNum(`${changedInt}`)
  }
  return result
  // 对数字末尾加0
  function padNum(num) {
    const dotPos = num.indexOf('.')
    if (dotPos === -1) {
      // 整数的情况
      num += '.'
      for (let i = 0; i < len; i++) {
        num += '0'
      }
      return num
    } else {
      // 小数的情况
      const need = len - (num.length - dotPos - 1)
      for (let j = 0; j < need; j++) {
        num += '0'
      }
      return num
    }
  }
}

// 数字金额转大写金额 100.00转成壹佰元
export function numerAmount(values) {
  if (values === null || values === '') {
    return ''
  }
  values = values.toString()
  const reg = new RegExp(',')
  values = values.replace(reg, '')
  const capArr = values.split('.')
  if (capArr.length < 2) {
    capArr[1] = '00'
  } else if (capArr[1].length === 1) {
    capArr[1] = capArr[1][0] + '0'
  }

  const len = capArr[0].length
  const len2 = capArr[1].length
  let arr = []
  let arr2 = []
  const chin_list = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'] // 所有的数值对应的汉字
  const chin_lisp = ['仟', '佰', '拾', '兆', '仟', '佰', '拾', '亿', '仟', '佰', '拾', '万', '仟', '佰', '拾'] // 进制
  const chin_lisp2 = ['角', '分']

  // 数字转大写，添加进制
  for (let i = 0; i < len; i++) {
    arr.push(parseInt(capArr[0][i]))
    arr[i] = chin_list[arr[i]]
  }
  for (let i = len - 1, j = 1; i > 0; i--) {
    arr.splice(i, 0, chin_lisp[chin_lisp.length - j++]) // j=2
  }
  for (let n = 0; n < len2; n++) {
    arr2.push(parseInt(capArr[1][n]))
    arr2[n] = chin_list[arr2[n]]
  }
  for (let i = len2, j = 1; i > 0; i--) {
    arr2.splice(i, 0, chin_lisp2[chin_lisp2.length - j++]) // j=2
  }
  arr = arr.join('')
  // 整数位处理
  if (len >= 1) {
    arr += '元'
  }
  arr = arr
    .replace(/零[仟佰拾]/g, '零')
  arr = arr.replace(/零{2,}/g, '零')
  arr = arr.replace(/零([兆|亿|万|元])/g, '$1')
  arr = arr.replace(/亿零{0,3}万/, '亿')
  arr = arr.replace(/兆零{0,3}亿/, '兆')
  arr = arr.replace(/^元/, '零元')
  arr2 = arr2.join('')
  arr = arr + arr2
  arr = arr.replace(/零角/, '零')
  arr = arr.replace(/零{1,2}分/, '整')
  return arr
}
// 数字转千分位
export function stateFormatNum(value) {
  if (!value || !Number(value)) return 0
  const intPart = Math.trunc(value)
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  if (value < 0) {
    intPartFormat = '-' + 0
  }
  let floatPart = ''
  const valueArray = value.toString().split('.')
  if (valueArray.length === 2) {
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + floatPart
}
