/*
 * @Date: 2022-04-25 15:23:07
 * @descriotion: 考勤
 */
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}
const routerMap = {
  attendance: {
    name: 'attendance',
    // subName: '考勤',
    component: view.blank
  },
  attend: {
    name: 'attendanceAttend',
    // subName: '出勤',
    component: view.blank
  },
  arrange: {
    name: 'attendanceArrange',
    // subName: '排班',
    component: view.blank
  },
  vacation: {
    name: 'attendanceVacation',
    // subName: '假期',
    component: view.blank
  },
  manage: {
    name: 'attendanceManage',
    // subName: '管理',
    component: () => import('@/pages/attendance/manage')
  },
  record: {
    name: 'attendanceRecord',
    // subName: '记录',
    component: () => import('@/pages/attendance/record')
  },
  atdReportForm: {
    name: 'attendanceAtdReportForm',
    // subName: '报表',
    component: () => import('@/pages/attendance/reportForm')
  },
  // analyse: {
  //   subName: '分析',
  //   icon: 'qrcode',
  //   component: () => import('@/pages/attendance/analyse')
  // },
  schedule: {
    name: 'attendanceSchedule',
    // subName: '排班表',
    component: () => import('@/pages/attendance/schedule')
  },
  changeClass: {
    name: 'attendanceChangeClass',
    // subName: '调班',
    component: () => import('@/pages/attendance/changeClass')
  },
  statistics: {
    name: 'attendanceStatistics',
    // subName: '统计',
    component: () => import('@/pages/attendance/statistics')
  },
  overview: {
    name: 'attendanceOverview',
    // subName: '概览',
    component: () => import('@/pages/attendance/overview/index')
  },
  adjustment: {
    name: 'attendanceAdjustment',
    // subName: '调整',
    component: () => import('@/pages/attendance/adjustment')
  },
  balance: {
    name: 'attendanceBalance',
    // subName: '余额',
    component: () => import('@/pages/attendance/balance')
  },
  setting: {
    name: 'attendanceSetting',
    // subName: '设置',
    component: () => import('@/pages/attendance/setting')
  }
}

export default routerMap
