<template>
  <a-select
    v-model="perTreeValueTemp"
    :default-value="perTreeValueTemp"
    :mode="modeType"
    style="width: 100%;"
    :placeholder="placeholder"
    dropdown-class-name="dropdownZIndex"
    show-search
    allow-clear
    :max-tag-count="maxTagCount"
    :options="perTreeDataList"
    option-filter-prop="children"
    :filter-option="filterOption"
    :disabled="disabled"
    :replace-fields="replaceFields"
    @change="handleChange"
    @focus="focus"
  />
</template>

<script>
import { getStaffVague } from '@/services/dropDownInput/index.js'
export default {
  name: 'PerTreeSelect',
  model: {
    prop: 'perTreeValue',
    event: 'getPerTreeValue'
  },
  props: {
    perTreeData: {
      type: Function,
      default: null
    },
    perTreeValue: {
      type: [Array, String],
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    modeType: {
      type: String,
      default: 'default'
    },
    perTreeType: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    replaceFields: {
      type: Object,
      default: () => {}
    },
    maxTagCount: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      perTreeDataList: [],
      perTreeValueTemp: undefined
    }
  },
  watch: {
    perTreeValue: {
      handler: function(val) {
        this.perTreeValueTemp = val
      },
      immediate: true
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    focus() {
      // this.getDataList()
    },
    async getDataList() {
      const data = await this.perTreeData ? this.perTreeData() : this.getStaffVague()
      const res = await data
      this.perTreeDataList = res ? res.map(item => {
        return {
          ...item,
          id: item.onJobId,
          name: item.staffName,
          jobNumber: item.staffNum,
          value: item.onJobId,
          label: this.perTreeType === 'staffNum' ? item.staffName + (item.staffNum ? '(' + item.staffNum + ')' : '') : item.staffName + (item.phoneNum ? '(' + item.phoneNum + ')' : '')
        }
      }) : []
    },
    async getStaffVague() {
      const res = await getStaffVague()
      return res.data
    },
    handleChange(value) {
      this.$emit('getPerTreeValue', value)
      if (this.modeType === 'default') {
        let userName = ''
        let staffId = ''
        this.perTreeDataList.forEach(item => {
          if (item.id === value) {
            userName = item.name
            staffId = item.staffId
          }
        })
        this.$emit('change', [{ label: userName, value, staffId }])
        this.$emit('getUserInfo', [{ label: userName, value, staffId }])
      } else {
        const userList = []
        value.forEach(item => {
          for (let i = 0; i < this.perTreeDataList.length; i++) {
            const key = this.perTreeDataList[i]
            if (key.id === item) {
              userList.push({
                name: key.name,
                id: item,
                staffId: key.staffId
              })
              break
            }
          }
        })
        this.$emit('change', userList)
        this.$emit('getUserInfo', userList)
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style>
  .dropdownZIndex{
    z-index: 2022;
  }
</style>
