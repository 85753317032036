<template>
  <div>
    <!-- k-form中使用 -->
    <a-select
      v-if="record && record.options"
      v-model="value"
      :default-value="record.options.defaultValue"
      :placeholder="record.options.placeholder"
      :disabled="record.options.disabled"
      :allow-clear="record.options.clearable"
      :mode="record.options.multiple ? 'multiple' : 'default'"
      :show-search="record.options.showSearch"
      :get-popup-container="(triggerNode) => triggerNode.parentNode"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-opt-group v-for="item in rankData" :key="item.jobTypeId" :label="item.jobTypeName">
        <a-select-option v-for="it in item.jobGradeList" :key="it.jobGradeId" :value="it.jobGradeId">
          {{ it.jobTypeCode }}{{ it.jobGrade }}
        </a-select-option>
      </a-select-opt-group>
    </a-select>
    <!-- 正常引入的 -->
    <a-select
      v-else
      v-model="value"
      :default-value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :mode="mode"
      :allow-clear="clearable"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-opt-group v-for="item in rankData" :key="item.jobTypeId" :label="item.jobTypeName">
        <a-select-option v-for="it in item.jobGradeList" :key="it.jobGradeId" :value="it.jobGradeId">
          {{ it.jobTypeCode }}{{ it.jobGrade }}
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </div>
</template>

<script>
import { getJobSelectList } from '@/services/dropDownInput/index.js'
import { getRankGroupDropDownList } from '@/services/organization/rank.js'
export default {
  name: 'Rank',
  model: {
    prop: 'rankValue',
    event: 'getRankValue'
  },
  props: {
    rankValue: {
      type: [String, Array],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择职级'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    record: {
      type: Object,
      default: () => {}
    },
    hasRelation: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    },
    postId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: undefined,
      rankData: []
    }
  },
  watch: {
    value() {
      if (this.value === '') this.value = undefined
    },
    rankValue: {
      handler: function(val) {
        this.value = val
      },
      immediate: true
    },
    postId() {
      this.getJobSelectList()
    }
  },
  mounted() {
    this.getJobSelectList()
  },
  methods: {
    async getJobSelectList() {
      let res
      if (this.hasRelation) {
        if (!this.postId) return
        res = await getRankGroupDropDownList({ postId: this.postId })
      } else {
        res = await getJobSelectList()
      }
      this.rankData = res.data.filter((item) => {
        return item.jobGradeList.length > 0
      })
    },
    handleChange(value) {
      // 单选
      if (this.mode === 'default') {
        let name = ''
        this.rankData.forEach((item) => {
          item.jobGradeList.forEach((key) => {
            if (key.jobGradeId === value) {
              name = key.jobTypeCode + key.jobGrade
            }
          })
        })
        this.$emit('getName', name)
        this.$emit('getRankValue', value)
      } else {
        // 多选
        const res = []
        if (value && value.length > 0) {
          this.rankData.forEach((item) => {
            item.jobGradeList.forEach((key) => {
              if (value.includes(key.jobGradeId)) {
                res.push({
                  label: key.jobTypeCode + key.jobGrade,
                  value: key.jobGradeId
                })
              }
            })
          })
          this.$emit('getRankValue', res)
        }
      }
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped></style>
