// 薪酬-设置-企业账号
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 查询报税主体
export async function getEndyearBounsList(params) {
  return request(`${WAGE}/taxpaySubject/getTaxpaySubjectList`, METHOD.GET, params)
}

// 创建报税主体
export async function createTaxpaySubject(params) {
  return request(`${WAGE}/taxpaySubject/createTaxpaySubject`, METHOD.POST, params)
}

// 编辑报税主体
export async function updateTaxpaySubject(params) {
  return request(`${WAGE}/taxpaySubject/updateTaxpaySubject`, METHOD.POST, params)
}

// 删除报税主体
export async function deleteTaxpaySubject(params) {
  return request(`${WAGE}/taxpaySubject/deleteTaxpaySubject`, METHOD.POST, params)
}

// 获取全部报税主体
export async function getContractCompanyList(params) {
  return request(`${WAGE}/contractCompany/getList`, METHOD.GET, params)
}

// 获取报税周期
export async function getTaxpayPeriodList(params) {
  return request(`${WAGE}/taxpaySubject/getTaxpayPeriodList`, METHOD.GET, params)
}

// 报税主体添加员工
export async function addTaxpaySubjectStaff(params) {
  return request(`${WAGE}/taxpaySubject/addTaxpaySubjectStaff`, METHOD.POST, params)
}

// 获取报税周期
export async function getStaffNotSetFixedSalary(params) {
  return request(`${WAGE}/archives/fixedSalary/getStaffNotSetFixedSalary`, METHOD.GET, params)
}
