<!--
 * @Descripttion:
 * @Author: kcz
 * @Date: 2021-05-02 16:04:02
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-06-22 08:50:31
-->
<template>
  <a-form-item
    :label="record.labelHide ? '' : record.label"
    :label-col="
      formConfig.layout === 'horizontal'
        ? formConfig.labelLayout === 'flex'
          ? { style: `width:${formConfig.labelWidth}px` }
          : formConfig.labelCol
        : {}
    "
    :wrapper-col="
      formConfig.layout === 'horizontal' ? (formConfig.labelLayout === 'flex' ? { style: 'width:auto;flex:1' } : formConfig.wrapperCol) : {}
    "
    :style="formConfig.layout === 'horizontal' && formConfig.labelLayout === 'flex' ? { display: 'flex' } : {}"
  >
    <component
      :is="customComponent"
      v-decorator="[
        record.model,
        {
          initialValue: record.options.defaultValue,
          rules: record.rules
        }
      ]"
      :record="record"
      :style="`width:${record.options.width};text-align: left;`"
      :disabled="disabled"
      :dynamic-data="dynamicData"
      :height="typeof record.options.height !== 'undefined' ? record.options.height : ''"
      @change="handleChange"
    />
  </a-form-item>
</template>
<script>
export default {
  name: 'CustomComponent',
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    formConfig: {
      type: Object,
      default: () => {}
    },
    dynamicData: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customComponent() {
      // 计算需要显示的组件
      const customComponentList = {}
      if (window.$customComponentList) {
        // 将数组映射成json
        window.$customComponentList.forEach((item) => {
          customComponentList[item.type] = item.component
        })
      }
      return customComponentList[this.record.type]
    }
  },
  methods: {
    handleChange(value, key) {
      this.$emit('change', value, key)
    }
  }
}
</script>
