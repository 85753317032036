<template>
  <transition name="msgbox-fade">
    <div class="CbReconfirm">
      <div class="modal-mask" />
      <div class="modal-wrap" @click="close">
        <div class="reconfirm-view" @click.stop>
          <div class="reconfirm-header">
            <div class="title">
              <a-icon type="info-circle" theme="filled" />
              <span class="title-name">{{ title }}</span>
            </div>
            <div class="close" @click="close">
              <a-icon type="close" />
            </div>
          </div>
          <div class="reconfirm-content" v-html="content" />
          <div class="reconfirm-footer">
            <a-button @click="close">
              取消
            </a-button>
            <a-button class="footer-primary" type="primary" @click="confirm">
              确认
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CbReconfirm',
  components: {},
  props: {},
  data() {
    return {
      content: '',
      Visible: false,
      title: ''
    }
  },
  methods: {
    close() {
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },
    confirm() {

    }
  }
}
</script>

<style lang="less" scoped>
.CbReconfirm {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2022;
  .modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: #00000073;
  }
  .modal-wrap {
    position: absolute;
    overflow: auto;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    outline: 0;
    display: flex;
    justify-content: center;
    z-index: 1001;
  }
  .reconfirm-view {
    width: 470px;
    background: #fff;
    // min-height: 188px;
    border-radius: 6px;
    .reconfirm-header {
      height: 48px;
      padding: 12px 24px;
      color: @sc-grey-20;
      background: #f0f0f0;
      border-bottom: 1px solid @sc-grey-10;
      border-radius: 4px 4px 0 0;
      display: flex;
      justify-content: space-between;
      .font-size(16px);
      color: @sc-grey-100;
      font-weight: 700;
      position: relative;
      .title {
        display: flex;
        align-items: center;
        .title-name {
          margin-left: 4px;
        }
        /deep/ .anticon-info-circle {
          width: 19px;
          height: 19px;
          color: @sc-primary-100;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 12px 24px;
        cursor: pointer;
        font-size: 14px;
      }
    }
    .reconfirm-content {
      padding: 20px 24px 32px;
      .font-size(14px);
      color: @sc-grey-100;
    }
    .reconfirm-footer {
      padding: 0 24px 24px;
      display: flex;
      justify-content: flex-end;
      /deep/ .ant-btn {
        padding: 5px 10px;
        margin-left: 12px;
        span {
          font-size: 14px;
        }
      }
    }
  }
}
.msgbox-fade-enter-active {
  animation: msgbox-fade-in .3s;
}

.msgbox-fade-leave-active {
  animation: msgbox-fade-out .3s;
}

@keyframes msgbox-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes msgbox-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}

</style>
