import org from './org'
import user from './user'
import processModule from './process'
import attendance from './attendance'
import globalSetting from './globalSetting'
import wages from './wages'
import relationship from './relationship'
import socialSecurity from './socialSecurity'
import culture from './culture'
const routeList = {
  // 首页的全局设置
  ...globalSetting,
  // 组织
  ...org,
  // 人员
  ...user,
  // 流程
  ...processModule,
  // 考勤
  ...attendance,
  // 薪酬模块儿
  ...wages,
  // 关系模块儿
  ...relationship,
  // 社保
  ...socialSecurity,
  // 文化
  ...culture
}

export default routeList
