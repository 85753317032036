import { DICTIONARY } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取租户合同公司列表
export async function contractCompany(params) {
  return request(`${DICTIONARY}/contractCompany/getList`, METHOD.GET, params)
}

// 获取租户办公地点列表
export async function workAddress(params) {
  return request(`${DICTIONARY}/workAddress/getList`, METHOD.GET, params)
}
