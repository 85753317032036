import { USER } from '../api'
import { METHOD, request } from '@/utils/request'

// 岗位下拉
export async function getPostSelectList(params) {
  return request(`${USER}/post/getPostGroupList`, METHOD.GET, params)
}

// 职级下拉
export async function getJobSelectList(params) {
  return request(`${USER}/rank/getRankGroupList`, METHOD.GET, params)
}

// 字典查询
export async function getTenantCode(params) {
  return request(`${USER}/tenantCode/getTenantCode`, METHOD.GET, params)
}

// 批量获取租户字典
export async function getTanantCodeBatch(params) {
  return request(`${USER}/tenantCode/getTanantCodeBatch`, METHOD.POST, params)
}

// 部门下拉
export async function getOrgTree(params) {
  return request(`${USER}/org/getOrgTree`, METHOD.GET, params)
}

// 根据部门ID获取直属员工列表（员工选择）
export async function getOrgStaff(params) {
  return request(`${USER}/staff/getOrgStaff`, METHOD.GET, params)
}
// 根据姓名、部门名称、工号模糊查询员工列表（员工选择）
export async function getStaffVague(params) {
  return request(`${USER}/staff/getStaffsVague`, METHOD.GET, params)
}

// 【员工查询】组织人员树组件
export async function getOrgStaffTree(params) {
  return request(`${USER}/staff/getOrgStaffTree`, METHOD.GET, params)
}
// 根据手机号查询
export async function getStaffInfoByPhoneNum(params) {
  return request(`${USER}/onJob/getStaffInfoByPhoneNum`, METHOD.GET, params)
}
