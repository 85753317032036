<template>
  <Rank
    v-model="data"
    :record="record"
    :has-relation="hasRelation"
    :post-id="idList.postId"
  />
</template>
<script>
import Rank from '@/components/CbDropDownInput/rank'
export default {
  name: 'SelectRank',
  components: {
    Rank
  },
  inject: ['hasRelation', 'idList', 'clearRankVal'],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    return {
      data: ''
    }
  },
  watch: {
    data() {
      this.$emit('change', this.data)
    },
    value() {
      this.data = this.value
    },
    'idList.postId'(val) {
      // 部门岗位职级强关联的情况下，查看是否需要清空数据
      // if (this.hasRelation && !val) {
      if (this.hasRelation) {
        this.data = ''
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.record.fieldCode === 'jobgrade') {
        this.data = this.record.fieldValue
      } else {
        this.data = this.value
      }
    })
  }
}
</script>
<style lang="less" scoped>

</style>
