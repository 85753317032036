<template>
  <div
    class="upload-img"
    :class="{
      'wkflow-error-tip': !!record.options.baseInfoList[0].labelErrorMsg || record.hasErrorValid
    }"
  >
    <template v-for="(item, index) in record.options.baseInfoList">
      <div :key="`baseInfoList${index}`" class="baseInfoItemBox">
        <div class="label" :class="{ 'require': record.isRequire}">{{ item.label }}</div>
        <div class="right">
          <div class="value">
            <a-icon type="plus" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UploadImg',
  components: {},
  props: {
    record: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  computed: {
    baseInfo() {
      const {
        record: {
          options: { baseInfoList = [] }
        }
      } = this
      return baseInfoList[0] || {}
    },
    options() {
      const {
        record: { options }
      } = this
      return options || {}
    }
  },
  created() {
    console.log(this.record)
  },
  methods: {
    afterRead(flie) {
      console.log(flie)
    },
    oversize() {
      return this.$message.error('上传文件过大')
    }
  }
}
</script>

<style lang="less" scoped>
.require{
  &::after{
    content: '*';
    color: red;
  }
}
.baseInfoItemBox{
  display: flex;
  justify-content: flex-end;
  justify-content: space-between!important;
  align-items: flex-start!important;
  .label{
    width: 120px;
  }
  .right{
    display: flex;
    align-items: center;
    width: auto;
    color: @sc-grey-40;
    .value{
      width: 48px;
      height: 48px;
      background-color: @sc-greyBg-20;
      margin: 6px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
