// 考勤-设置-规则
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 补卡规则列表
export async function getAppendPunchListApi(params) {
  return request(`${ATTENDACE}/config/appendPunchRule/appendPunchRuleList`, METHOD.GET, params)
}

// 补卡规则新增
export async function addPunchRuleApi(params) {
  return request(`${ATTENDACE}/config/appendPunchRule/addPunchRule`, METHOD.POST, params)
}

// 补卡规则编辑
export async function updatePunchRuleApi(params) {
  return request(`${ATTENDACE}/config/appendPunchRule/updatePunchRule`, METHOD.POST, params)
}

// 补卡规则详情
export async function detailPunchRuleApi(params) {
  return request(`${ATTENDACE}/config/appendPunchRule/detailPunchRule`, METHOD.GET, params)
}

// 补卡规则删除
export async function deletePunchRuleApi(params) {
  return request(`${ATTENDACE}/config/appendPunchRule/deletePunchRule`, METHOD.GET, params)
}

// 加班规则列表
export async function getoOvertimeRuleListApi(params) {
  return request(`${ATTENDACE}/config/overtimeRule/overtimeRuleList`, METHOD.GET, params)
}

// 加班规则详情
export async function detailOvertimeRuleApi(params) {
  return request(`${ATTENDACE}/config/overtimeRule/detailOvertimeRule`, METHOD.GET, params)
}

// 加班规则删除
export async function deleteOvertimeRuleApi(params) {
  return request(`${ATTENDACE}/config/overtimeRule/deleteOvertimeRule`, METHOD.GET, params)
}

// 加班规则新增
export async function addOvertimeRuleApi(params) {
  return request(`${ATTENDACE}/config/overtimeRule/addOvertimeRule`, METHOD.POST, params)
}

// 加班规则编辑
export async function updateOvertimeRuleApi(params) {
  return request(`${ATTENDACE}/config/overtimeRule/updateOvertimeRule`, METHOD.POST, params)
}

// 外勤规则列表
export async function getOutsideRuleListApi(params) {
  return request(`${ATTENDACE}/config/outsideRule/outsideRuleList`, METHOD.GET, params)
}

// 外勤规则新增
export async function addOutsideRuleApi(params) {
  return request(`${ATTENDACE}/config/outsideRule/addOutsideRule`, METHOD.POST, params)
}

// 外勤规则详情
export async function detailOutsideRuleApi(params) {
  return request(`${ATTENDACE}/config/outsideRule/detailOutsideRule`, METHOD.GET, params)
}

// 外勤规则编辑
export async function updateOutsideRuleApi(params) {
  return request(`${ATTENDACE}/config/outsideRule/updateOutsideRule`, METHOD.POST, params)
}

// 外勤规则删除
export async function deleteOutsideRuleApi(params) {
  return request(`${ATTENDACE}/config/outsideRule/deleteOutsideRule`, METHOD.GET, params)
}
