<template>
  <DictionariesInput v-model="data" :record="record" :parameter="record.options.parameter" />
</template>
<script>
// import { getTenantCode } from '@/services/dropDownInput/index.js'
import DictionariesInput from '@/components/CbDropDownInput/dictionariesInput'
export default {
  name: 'SelectDictionaries',
  components: {
    DictionariesInput
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    const data = this.record.options.multiple ? [] : ''
    return {
      data
    }
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('change', this.data)
      }
    },
    value() {
      this.data = this.value
    }
  },
  mounted() {
    this.data = this.value
  }
}
</script>
