import Vue from 'vue'
import AButton from '@/components/Button/index.js'

// 解决TimePicker随页面滚动
// TimePicker.props.getPopupContainer.default = triggerNode => { return triggerNode.parentNode }
import {
  // Button,
  Anchor,
  Avatar,
  Alert,
  AutoComplete,
  Badge,
  Breadcrumb,
  Calendar,
  Card,
  Collapse,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  TreeSelect,
  message,
  Menu,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Spin,
  Steps,
  Switch,
  Table,
  Tree,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Drawer,
  Comment,
  ConfigProvider,
  Empty,
  Descriptions,
  PageHeader,
  Space } from 'ant-design-vue'

const components = [
  AButton,
  Anchor,
  Alert,
  Avatar,
  AutoComplete,
  Badge,
  Breadcrumb,
  //   Button,
  Calendar,
  Card,
  Collapse,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  TreeSelect,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Spin,
  Steps,
  Switch,
  Table,
  Tree,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Drawer,
  Comment,
  // ColorPicker,
  ConfigProvider,
  Empty,
  Descriptions,
  PageHeader,
  Space
]
components.map(component => {
  Vue.use(component)
})
// 解决Select随页面滚动
Select.props.getPopupContainer.default = triggerNode => { return triggerNode.parentNode }

// 解决TreeSelect随页面滚动
TreeSelect.props.getPopupContainer.default = triggerNode => { return triggerNode.parentNode }

// 解决DatePicker随页面滚动
DatePicker.props.getCalendarContainer.default = triggerNode => { return triggerNode.parentNode }

// Button.beforeMount = function mounted() {
//   window.addEventListener('keydown', (e) => {
//     if (e.key === 'Enter') {
//       this.$emit('enterPress', e)
//     }
//   })
// }
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$destroyAll = Modal.destroyAll
