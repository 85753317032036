export default {
  components: {
    // 部门选择
    DepTreeSelect: () => import ('./depTreeSelect/index.vue'),
    // 人员选择
    PerTreeSelect: () => import ('./perTreeSelect/index.vue'),
    // 人员部门混选
    PerDepModel: () => import ('./perDepModel/index.vue'),
    // 人员弹窗选择
    PerModelSelect: () => import ('./perModelSelect/index.vue')
  }
}
