<!--计算公式-->
<template>
  <div
    class="autocompute"
    :class="{
      'wkflow-error-tip': !!record.options.baseInfoList[0].labelErrorMsg || record.hasErrorValid
    }"
  >
    <template v-for="(item, index) in record.options.baseInfoList">
      <div :key="`baseInfoList${index}`" class="baseInfoItemBox">
        <div class="label" :class="{ 'require': record.isRequire}">{{ item.label }}</div>
        <div class="right">
          <div class="value">自动计算</div>
        </div>
      </div>
    </template>
    <a-modal v-model="record.options.visible" title="计算公式" @ok="handleOk">
      <div class="autocompute-form-p">
        <div>计算公式：</div>
        <div class="formula-p" :style="{ borderColor: formulaErr ? 'red' : '#d8d8d8' }">
          <div class="formula-content">
            <span>计算公式=</span>
            <template v-if="exps.length > 0">
              <span
                v-for="(item, index) in exps"
                :key="index"
                :style="{ color: validArg(item) ? 'inherit' : 'red' }"
                class="formula-item"
              >
                {{ item | matchArg(that) }}
              </span>
            </template>
            <template v-else>
              <span class="formula-content-placeholder">
                请在下方选择计算对象和计算符号完成编辑
              </span>
            </template>
          </div>
          <div class="formula-operations">
            <div class="opt-del" @click="delExp">删除</div>
            <div class="opt-clear" @click="clearExp">清空</div>
          </div>
        </div>
        <div v-show="formulaErr">
          <a-alert message="编辑的计算公式为空或不符合计算法则，无法计算" type="error" />
        </div>
        <div>
          <a-tooltip>
            <template slot="title">
              仅数字、金额控件可用于计算
            </template>
            <a-icon type="info-circle" theme="filled" />
          </a-tooltip>
          控件：<br>
          <div class="autocompute-comp">
            <div
              v-for="(item, index) in argsList"
              :key="index"
              class="autocompute-comp-key"
              @click="addArg(item.model)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div>
          数字：<br>
          <div class="autocompute-nums">
            <div
              v-for="(item, index) in numbers"
              :key="index"
              class="autocompute-nums-key"
              @click="addOption(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div>
          符号：<br>
          <div class="autocompute-symbol">
            <div
              v-for="(item, index) in symbols"
              :key="index"
              class="autocompute-symbol-key"
              @click="addOption(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import mixins from '../mixins'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'AutoCompute',
  filters: {
    'matchArg': function(val, that) {
      if (val.indexOf('${') > -1) {
        const index = val.replace('${', '').replace('}', '')
        return (
          that.argsList.filter((v) => {
            return v.model === that.args[index]
          })[0] || { name: '无效目标' }
        ).name
      } else {
        return val
      }
    }
  },
  mixins: [mixins],
  // eslint-disable-next-line vue/require-prop-types
  props: ['record'],
  inject: ['dragComponentList'],
  data() {
    return {
      that: this,
      args: [], // 已使用的组件对象数组
      argsList: [], // 所有金额、数字组件对象数组
      exps: [], // 表达式数组
      symbols: ['+', '-', '*', '/', '(', ')', '.'],
      numbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      formulaErr: false // 公式是否正确
    }
  },
  computed: {
    ...mapGetters('designer', ['getAutoComputeRelation'])
  },
  watch: {
    'record.options.visible': function() {
      this.initComponents()
    }
  },
  methods: {
    ...mapMutations('designer', ['setAutoComputeRelation']),
    initComponents() {
      const data = this.dragComponentList()
      // 获取金额、数字组件
      const amountComponents = data.list.filter((v) => {
        return ['WKNumber', 'WKAmount'].includes(v.type)
      })
      this.argsList = amountComponents.map((v) => {
        return {
          name: v.options.baseInfoList[0].label,
          model: v.model
        }
      })
      // 获取明细中的金额、数字组件
      const wordTableComponents = data.list.filter((v) => {
        return ['WKWordTable'].includes(v.type)
      })
      for (let i = 0; i < wordTableComponents.length; i++) {
        const nums = wordTableComponents[i].list.map((v) => {
          return {
            name: `${wordTableComponents[i].options.baseInfoList[0].label}.${v.options.baseInfoList[0].label}`,
            model: `${wordTableComponents[i].model}.${v.model}`
          }
        })
        this.argsList = this.argsList.concat(nums)
      }
      this.args = [...this.record.options.args]
      this.exps = this.resolveDataArray(this.record.options.exp.split(''))
    },
    handleOk() {
      if (this.validExps(this.exps.join(''))) {
        this.formulaErr = false
        // 全局缓存和计算相关的组件的model，先减去原有的，再加上现在有的
        const copyAutoComputeRelation = JSON.parse(JSON.stringify(this.getAutoComputeRelation))
        this.record.options.args.forEach(item => {
          copyAutoComputeRelation[item] -= 1
        })
        this.args.forEach(item => {
          if (copyAutoComputeRelation[item]) {
            copyAutoComputeRelation[item] += 1
          } else {
            copyAutoComputeRelation[item] = 1
          }
        })
        this.setAutoComputeRelation(copyAutoComputeRelation)
        this.record.options.args = [...this.args]
        this.record.options.exp = this.exps.join('')
        this.$emit('update:record', this.record)
        this.record.options.visible = false
      } else {
        this.formulaErr = true
      }
    },
    // 增加控件
    addArg(arg) {
      if (this.args.includes(arg)) {
        // 重复增加
        const index = this.args.indexOf(arg)
        this.exps.push('${' + index + '}')
      } else {
        const index = this.args.length
        this.exps.push('${' + index + '}')
        this.args.push(arg)
      }
    },
    // 增加操作
    addOption(opert) {
      this.exps.push(opert)
    },
    // 删除操作
    delExp() {
      const shift = this.exps.pop()
      if (shift.indexOf('${') > -1 && !this.exps.includes(shift)) {
        const index = shift.replace('${', '').replace('}', '')
        this.args.splice(Number(index), 1)
      }
    },
    // 清空操作
    clearExp() {
      this.exps = []
      this.args = []
    },
    // 根据传来的json解析计算公式初始化
    resolveDataArray(strArr) {
      const newStrArr = strArr
      const startIndex = newStrArr.indexOf('$')
      const endIndex = newStrArr.indexOf('}')
      if (startIndex > -1) {
        let $content = ''
        for (let i = startIndex; i <= endIndex; i++) {
          $content += newStrArr[i]
        }
        newStrArr.splice(startIndex, endIndex - startIndex + 1, $content)
        if (newStrArr.indexOf('$') > -1) {
          return this.resolveDataArray(newStrArr)
        } else {
          return newStrArr
        }
      } else {
        return newStrArr
      }
    },
    // 验证是否是有效字段（计算公式中包含，但没有实际组件，则视为无效字段）
    validArg(val) {
      if (val.indexOf('${') > -1) {
        const index = val.replace('${', '').replace('}', '')
        return !!this.argsList[index]
      } else {
        return true
      }
    },
    //
    validExps(exps) {
      let valid = true
      try {
        const exp = exps
          .replace(/\$\{0\}/g, '1')
          .replace(/\$\{/g, '')
          .replace(/\}/g, '')
        eval(exp)
      } catch {
        valid = false
      }
      // // 错误情况，运算符连续
      // if (/[\-\*\+\/]{2,}/.test(exps)) {
      //   valid = false
      // }
      // // 空括号
      // if (valid && /\(\)/.test(exps)) {
      //   valid = false
      // }
      // if (valid) {
      //   // 错误情况，括号不配对
      //   const stack = []
      //   for (let i = 0, item; i < exps.length; i++) {
      //     item = exps.charAt(i)
      //     if (item === '(') {
      //       stack.push('(')
      //     } else if (item === ')') {
      //       if (stack.length > 0) {
      //         stack.pop()
      //       } else {
      //         valid = false
      //       }
      //     }
      //   }
      //   if (stack.length !== 0) {
      //     valid = false
      //   }
      // }
      // // 错误情况，(后面是运算符
      // if (valid && /\([\-\*\+\/]/.test(exps)) {
      //   valid = false
      // }
      // // 错误情况，)前面是运算符
      // if (valid && /[\-\*\+\/\)]\)/.test(exps)) {
      //   valid = false
      // }
      // // 错误情况，(前面不是运算符
      // if (valid && /[^\-\*\+\/]\(/.test(exps)) {
      //   valid = false
      // }
      // // 错误情况，)后面不是运算符
      // if (valid && /\)[^\-\*\+\/\)]/.test(exps)) {
      //   valid = false
      // }
      // // 错误情况，不能除0
      // if (valid && /\/0/.test(exps)) {
      //   valid = false
      // }
      // // 错误情况，.后面不能是运算符和括号
      // if (valid && /\.[\-\*\+\/\)\(]/.test(exps)) {
      //   valid = false
      // }
      // // 错误情况，.前面不能是运算符和括号
      // if (valid && /[\-\*\+\/\)\(]\./.test(exps)) {
      //   valid = false
      // }
      // // 错误情况，出现2.2.2等多小数点
      // if (valid) {
      //   const exp = exps.split(/[-|*|+|/]{1}/)
      //   for (let i = 0; i < exp.length; i++) {
      //     if (exp[i].indexOf('.') > -1 && exp[i].indexOf('.') !== exp[i].lastIndexOf('.')) {
      //       valid = false
      //     }
      //   }
      // }
      // console.log(valid)
      return valid
    }
  }
}
</script>
<style lang="less">
.baseInfoItemBox{
  display: flex;
  justify-content: space-between!important;
  align-items: flex-start!important;
  .label{
    width: 120px;
  }
  .right{
    display: flex;
    align-items: center;
    width: 100%;
    color: @sc-grey-40;
    .value{
      width: 100%;
      text-align: right;
    }
  }
}
.autocompute-form-p {
  .formula-p {
    margin-bottom: 10px;
    border: 1px solid #d8d8d8;
    padding: 8px 12px 14px 8px;
    border-radius: 6px;
    .formula-content {
      .formula-content-placeholder {
        font-size: 12px;
        line-height: 21px;
        color: #9e9e9e;
      }
    }
    .formula-operations {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .opt-del {
        margin-right: 12px;
        height: 24px;
        font-size: 14px;
        cursor: pointer;
        line-height: 24px;
      }
      .opt-clear {
        height: 24px;
        color: rgb(245, 30, 30);
        font-size: 14px;
        cursor: pointer;
        line-height: 24px;
      }
    }
  }
  .autocompute-comp {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    .autocompute-comp-key {
      margin: 0 12px 12px 0;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 6px;
      text-align: center;
      color: #252834;
      background-color: #f4f5f6;
      cursor: pointer;
      word-break: break-all;
    }
  }
  .autocompute-nums {
    display: flex;
    padding-bottom: 10px;
    .autocompute-nums-key {
      width: 36px;
      height: 32px;
      margin: 0 12px 12px 0;
      font-size: 16px;
      line-height: 36px;
      border-radius: 6px;
      text-align: center;
      color: #252834;
      background-color: #f4f5f6;
      cursor: pointer;
    }
  }
  .autocompute-symbol {
    display: flex;
    .autocompute-symbol-key {
      width: 36px;
      height: 32px;
      margin: 0 12px 12px 0;
      font-size: 16px;
      line-height: 36px;
      border-radius: 6px;
      text-align: center;
      color: #252834;
      background-color: #f4f5f6;
      cursor: pointer;
    }
  }
}
</style>
