<template>
  <div class="properties-centent kk-checkbox">
    <div class="properties-body">
      <a-empty v-show="selectItem.key === ''" class="hint-box" description="未选择控件" />

      <a-form v-if="type === 'kForm'" v-show="selectItem.key !== ''">
        <a-form-item v-if="typeof selectItem.label !== 'undefined'" label="标签">
          <a-input v-model="selectItem.label" placeholder="请输入" />
        </a-form-item>

        <a-form-item v-if="!hideModel && typeof selectItem.model !== 'undefined'" label="数据字段">
          <a-input v-model="selectItem.model" placeholder="请输入" />
        </a-form-item>
        <!-- <a-form-item v-else-if="!hideModel && typeof selectItem.model !== 'undefined' && selectItem.type === 'selectDictionaries' " label="数据字段">
          <a-input v-model="options.model" placeholder="请输入" />
        </a-form-item> -->
        <!-- input type start -->
        <a-form-item v-if="selectItem.type === 'input'" label="输入框type">
          <a-input v-model="options.type" placeholder="请输入" />
        </a-form-item>
        <!-- input type end -->
        <a-form-item
          v-if="typeof options.rangePlaceholder !== 'undefined' && options.range"
          label="占位内容"
        >
          <a-input v-model="options.rangePlaceholder[0]" placeholder="请输入" />
          <a-input v-model="options.rangePlaceholder[1]" placeholder="请输入" />
        </a-form-item>

        <!-- 字典项的配置 -->
        <a-form-item
          v-if="['selectDictionaries', 'WKDictionary'].includes(selectItem.type)"
          label="字典项type配置"
        >
          <a-select v-model="selectItem.options.parameter" @change="dictionaryChange">
            <a-select-opt-group v-for="(key, index) in dictionaryList" :key="`Dictionaries${index}`">
              <span slot="label">{{ key.label }}</span>
              <a-select-option v-for="(item, listIndex) in key.value" :key="`listItem${listIndex}`" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-item>

        <a-form-item v-else-if="typeof options.placeholder !== 'undefined'" label="占位内容">
          <a-input v-model="options.placeholder" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'textarea'" label="自适应内容高度">
          <a-input-number v-model="options.minRows" style="width: 100%" placeholder="最小高度" />
          <a-input-number v-model="options.maxRows" style="width: 100%" placeholder="最大高度" />
        </a-form-item>
        <a-form-item v-if="typeof options.width !== 'undefined'" label="宽度">
          <a-input v-model="options.width" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="typeof options.height !== 'undefined'" label="高度">
          <a-input-number v-model="options.height" />
        </a-form-item>
        <a-form-item v-if="typeof options.step !== 'undefined'" label="步长">
          <a-input-number v-model="options.step" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="typeof options.min !== 'undefined'" label="最小值">
          <a-input-number v-model="options.min" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="typeof options.max !== 'undefined'" label="最大值">
          <a-input-number v-model="options.max" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="typeof options.maxLength !== 'undefined'" label="最大长度">
          <a-input-number v-model="options.maxLength" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="typeof options.tabBarGutter !== 'undefined'" label="标签间距">
          <a-input-number v-model="options.tabBarGutter" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="typeof options.precision !== 'undefined'" label="数值精度">
          <a-input-number v-model="options.precision" :min="0" :max="50" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="typeof options.dictCode !== 'undefined'" label="dictCode">
          <a-input v-model="options.dictCode" />
        </a-form-item>
        <!-- 选项配置及动态数据配置 start -->
        <a-form-item v-if="typeof options.options !== 'undefined'" label="选项配置">
          <a-radio-group v-model="options.dynamic" button-style="solid">
            <a-radio-button :value="false">静态数据</a-radio-button>
            <a-radio-button :value="true">动态数据</a-radio-button>
          </a-radio-group>

          <a-input
            v-show="options.dynamic"
            v-model="options.dynamicKey"
            placeholder="动态数据变量名"
          />

          <KChangeOption v-show="!options.dynamic" v-model="options.options" />
        </a-form-item>
        <!-- 选项配置及动态数据配置 end -->
        <!-- tabs配置 start -->
        <a-form-item
          v-if="['tabs', 'selectInputList'].includes(selectItem.type)"
          :label="selectItem.type === 'tabs' ? '页签配置' : '列选项配置'"
        >
          <KChangeOption v-model="selectItem.columns" type="tab" />
        </a-form-item>
        <!-- tabs配置 end -->
        <a-form-item v-if="selectItem.type === 'grid'" label="栅格间距">
          <a-input-number v-model="selectItem.options.gutter" placeholder="请输入" />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'grid'" label="列配置项">
          <KChangeOption v-model="selectItem.columns" type="colspan" />
        </a-form-item>

        <a-form-item v-if="selectItem.type === 'switch'" label="默认值">
          <a-switch v-model="options.defaultValue" />
        </a-form-item>
        <a-form-item v-if="['number', 'slider'].includes(selectItem.type) >= 0" label="默认值">
          <a-input-number
            v-model="options.defaultValue"
            :step="options.step"
            :min="options.min || -Infinity"
            :max="options.max || Infinity"
          />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'rate'" label="默认值">
          <a-rate
            v-model="options.defaultValue"
            :allow-half="options.allowHalf"
            :count="options.max"
          />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'select'" label="默认值">
          <a-select v-model="options.defaultValue" :options="options.options" />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'radio'" label="默认值">
          <a-radio-group v-model="options.defaultValue" :options="options.options" />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'checkbox'" label="默认值">
          <a-checkbox-group v-model="options.defaultValue" :options="options.options" />
        </a-form-item>
        <!-- 日期选择器默认值 start -->
        <a-form-item v-if="selectItem.type === 'date'" label="默认值">
          <a-input
            v-if="!options.range"
            v-model="options.defaultValue"
            :placeholder="typeof options.format === 'undefined' ? '' : options.format"
          />
          <a-input
            v-if="options.range"
            v-model="options.rangeDefaultValue[0]"
            :placeholder="typeof options.format === 'undefined' ? '' : options.format"
          />
          <a-input
            v-if="options.range"
            v-model="options.rangeDefaultValue[1]"
            :placeholder="typeof options.format === 'undefined' ? '' : options.format"
          />
        </a-form-item>
        <!-- 日期选择器默认值 start -->
        <a-form-item
          v-if="
            ![
              'number',
              'radio',
              'checkbox',
              'date',
              'rate',
              'select',
              'switch',
              'slider',
              'html'
            ].includes(selectItem.type) && typeof options.defaultValue !== 'undefined'
          "
          label="默认值"
        >
          <a-input
            v-model="options.defaultValue"
            :placeholder="typeof options.format === 'undefined' ? '请输入' : options.format"
          />
        </a-form-item>
        <!-- 修改html -->
        <a-form-item v-if="selectItem.type === 'html'" label="默认值">
          <a-textarea v-model="options.defaultValue" :auto-size="{ minRows: 4, maxRows: 8 }" />
        </a-form-item>
        <a-form-item v-if="typeof options.format !== 'undefined'" label="时间格式">
          <a-input v-model="options.format" placeholder="时间格式如：YYYY-MM-DD HH:mm:ss" />
        </a-form-item>

        <a-form-item v-if="typeof options.orientation !== 'undefined'" label="标签位置">
          <a-radio-group v-model="options.orientation" button-style="solid">
            <a-radio-button value="left">左</a-radio-button>
            <a-radio-button value="">居中</a-radio-button>
            <a-radio-button value="right">右</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- 页签位置 start -->
        <a-form-item v-if="selectItem.type === 'tabs'" label="页签位置">
          <a-radio-group v-model="options.tabPosition" button-style="solid">
            <a-radio value="top">top</a-radio>
            <a-radio value="right">right</a-radio>
            <a-radio value="bottom">bottom</a-radio>
            <a-radio value="left">left</a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- 页签位置 end -->
        <!-- 页签类型 start -->
        <a-form-item v-if="selectItem.type === 'tabs'" label="页签类型">
          <a-radio-group v-model="options.type" button-style="solid">
            <a-radio-button value="line">line</a-radio-button>
            <a-radio-button value="card">card</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- 页签类型 end -->
        <!-- 页签大小 start -->
        <a-form-item v-if="typeof options.size !== 'undefined'" label="大小">
          <a-radio-group v-model="options.size" button-style="solid">
            <a-radio-button value="large">large</a-radio-button>
            <a-radio-button value="default">default</a-radio-button>
            <a-radio-button value="small">small</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- 页签大小 end -->
        <a-form-item v-if="selectItem.type === 'button'" label="类型">
          <a-radio-group v-model="options.type" button-style="solid">
            <a-radio value="primary">Primary</a-radio>
            <a-radio value="default">Default</a-radio>
            <a-radio value="dashed">Dashed</a-radio>
            <a-radio value="danger">Danger</a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- 下载方式 start -->
        <a-form-item v-if="typeof options.downloadWay !== 'undefined'" label="下载方式">
          <a-radio-group v-model="options.downloadWay" button-style="solid">
            <a-radio-button value="a">a标签</a-radio-button>
            <a-radio-button value="ajax">ajax</a-radio-button>
            <a-radio-button value="dynamic">动态函数</a-radio-button>
          </a-radio-group>
          <a-input
            v-show="options.downloadWay === 'dynamic'"
            v-model="options.dynamicFun"
            placeholder="动态函数名"
          />
        </a-form-item>
        <!-- 下载方式 end -->
        <a-form-item v-if="selectItem.type === 'button'" label="按钮操作">
          <a-radio-group v-model="options.handle" button-style="solid">
            <a-radio-button value="submit">提交</a-radio-button>
            <a-radio-button value="reset">重置</a-radio-button>
            <a-radio-button value="dynamic">动态函数</a-radio-button>
          </a-radio-group>
          <a-input
            v-show="options.handle === 'dynamic'"
            v-model="options.dynamicFun"
            placeholder="动态函数名"
          />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'alert'" label="辅助描述">
          <a-input v-model="options.description" />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'alert'" label="类型">
          <a-radio-group v-model="options.type" button-style="solid">
            <a-radio value="success">success</a-radio>
            <a-radio value="info">info</a-radio>
            <a-radio value="warning">warning</a-radio>
            <a-radio value="error">error</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'alert'" label="操作属性">
          <kCheckbox v-model="options.showIcon" label="显示图标" />
          <kCheckbox v-model="options.banner" label="无边框" />
          <kCheckbox v-model="options.closable" label="可关闭" />
        </a-form-item>
        <!-- 上传图片 -->
        <a-form-item v-if="selectItem.type === 'uploadImg'" label="样式">
          <a-radio-group v-model="options.listType" button-style="solid">
            <a-radio-button value="text">text</a-radio-button>
            <a-radio-button value="picture">picture</a-radio-button>
            <a-radio-button value="picture-card">card</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- 上传数量 -->
        <a-form-item v-if="typeof options.limit !== 'undefined'" label="最大上传数量">
          <a-input-number v-model="options.limit" :min="1" />
        </a-form-item>

        <!-- scrollY -->
        <a-form-item v-if="typeof options.scrollY !== 'undefined'" label="scrollY">
          <a-input-number v-model="options.scrollY" :min="0" />
        </a-form-item>

        <!-- 上传地址 -->
        <a-form-item v-if="typeof options.action !== 'undefined'" label="上传地址">
          <a-input v-model="options.action" placeholder="请输入" />
        </a-form-item>

        <!-- 文件name -->
        <a-form-item v-if="typeof options.fileName !== 'undefined'" label="文件name">
          <a-input v-model="options.fileName" placeholder="请输入" />
        </a-form-item>
        <!-- 上传额外参数 -->
        <a-form-item v-if="typeof options.data !== 'undefined'" label="额外参数（JSON格式）">
          <a-textarea v-model="options.data" placeholder="严格JSON格式" />
        </a-form-item>
        <!-- 文字对齐方式 -->
        <a-form-item v-if="selectItem.type === 'text'" label="文字对齐方式">
          <a-radio-group v-model="options.textAlign" button-style="solid">
            <a-radio-button value="left">左</a-radio-button>
            <a-radio-button value="center">居中</a-radio-button>
            <a-radio-button value="right">右</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <!-- 文字字体 -->
        <a-form-item v-if="selectItem.type === 'text'" label="字体属性设置">
          <colorPicker v-model="options.color" />
          <a-select
            v-model="options.fontFamily"
            :options="familyOptions"
            style="width:36%;margin-left:2%;vertical-align:bottom;"
          />
          <a-select
            v-model="options.fontSize"
            :options="sizeOptions"
            style="width:35%;margin-left:2%;vertical-align:bottom;"
          />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'text'" label="操作属性">
          <kCheckbox v-model="options.showRequiredMark" label="显示必选标记" />
        </a-form-item>

        <a-form-item
          v-if="
            typeof options.hidden !== 'undefined' ||
              typeof options.disabled !== 'undefined' ||
              typeof options.readonly !== 'undefined' ||
              typeof options.clearable !== 'undefined' ||
              typeof options.multiple !== 'undefined' ||
              typeof options.range !== 'undefined' ||
              typeof options.showTime !== 'undefined' ||
              typeof options.allowHalf !== 'undefined' ||
              typeof options.showInput !== 'undefined' ||
              typeof options.animated !== 'undefined'
          "
          label="操作属性"
        >
          <kCheckbox
            v-if="typeof options.hidden !== 'undefined'"
            v-model="options.hidden"
            label="隐藏"
          />
          <kCheckbox
            v-if="typeof options.disabled !== 'undefined'"
            v-model="options.disabled"
            label="禁用"
          />
          <kCheckbox
            v-if="typeof options.readonly !== 'undefined'"
            v-model="options.readonly"
            label="只读"
          />
          <kCheckbox
            v-if="typeof options.clearable !== 'undefined'"
            v-model="options.clearable"
            label="可清除"
          />
          <kCheckbox
            v-if="typeof options.multiple !== 'undefined'"
            v-model="options.multiple"
            label="多选"
          />
          <kCheckbox
            v-if="typeof options.range !== 'undefined'"
            v-model="options.range"
            label="范围选择"
          />
          <kCheckbox
            v-if="typeof options.showTime !== 'undefined'"
            v-model="options.showTime"
            label="时间选择器"
          />
          <kCheckbox
            v-if="typeof options.allowHalf !== 'undefined'"
            v-model="options.allowHalf"
            label="允许半选"
          />
          <kCheckbox
            v-if="typeof options.showInput !== 'undefined'"
            v-model="options.showInput"
            label="显示输入框"
          />
          <kCheckbox
            v-if="typeof options.showLabel !== 'undefined'"
            v-model="options.showLabel"
            label="显示Label"
          />
          <kCheckbox
            v-if="typeof options.chinesization !== 'undefined'"
            v-model="options.chinesization"
            label="汉化"
          />
          <kCheckbox
            v-if="typeof options.hideSequence !== 'undefined'"
            v-model="options.hideSequence"
            label="隐藏序号"
          />
          <kCheckbox
            v-if="typeof options.drag !== 'undefined'"
            v-model="options.drag"
            label="允许拖拽"
          />
          <kCheckbox
            v-if="typeof options.showSearch !== 'undefined'"
            v-model="options.showSearch"
            label="可搜索"
          />
          <kCheckbox
            v-if="typeof options.treeCheckable !== 'undefined'"
            v-model="options.treeCheckable"
            label="可勾选"
          />
          <kCheckbox
            v-if="typeof options.animated !== 'undefined'"
            v-model="options.animated"
            label="动画切换"
          />
        </a-form-item>

        <a-form-item
          v-if="typeof selectItem.rules !== 'undefined' && selectItem.rules.length > 0"
          label="校验"
        >
          <kCheckbox v-model="selectItem.rules[0].required" label="必填" />
          <a-input v-model="selectItem.rules[0].message" placeholder="必填校验提示信息" />
          <KChangeOption v-model="selectItem.rules" type="rules" />
        </a-form-item>

        <!-- 表格选项 -->
        <a-form-item v-if="selectItem.type === 'table'" label="表格样式CSS">
          <a-input v-model="selectItem.options.customStyle" />
        </a-form-item>
        <a-form-item v-if="selectItem.type === 'table'" label="属性">
          <kCheckbox v-model="selectItem.options.bordered" label="显示边框" />
          <kCheckbox v-model="selectItem.options.bright" label="鼠标经过点亮" />
          <kCheckbox v-model="selectItem.options.small" label="紧凑型" />
        </a-form-item>

        <a-form-item v-if="selectItem.type === 'table'" label="提示">
          <p style="line-height: 26px">请点击右键增加行列，或者合并单元格</p>
        </a-form-item>

        <a-form-item v-if="typeof selectItem.help !== 'undefined'" label="帮助信息">
          <a-input v-model="selectItem.help" placeholder="请输入" />
        </a-form-item>

        <!-- 前缀 -->
        <a-form-item v-if="typeof options.addonBefore !== 'undefined'" label="前缀">
          <a-input v-model="options.addonBefore" placeholder="请输入" />
        </a-form-item>

        <!-- 后缀 -->
        <a-form-item v-if="typeof options.addonAfter !== 'undefined'" label="后缀">
          <a-input v-model="options.addonAfter" placeholder="请输入" />
        </a-form-item>
      </a-form>
      <!-- 表单设计器的属性面板开始 -->
      <a-form-model
        v-else
        v-show="selectItem.key !== ''"
        ref="ruleForm"
        :model="options"
        layout="inline"
        :label-col="{ style: 'width: 140px' }"
      >
        <!-- 基础属性 -->
        <template v-for="(key, index) in options.baseInfoList">
          <a-form-model-item
            v-if="
              (!['WKText', 'WKThreeLevel'].includes(selectItem.type) ||
                (['WKThreeLevel'].includes(selectItem.type) && index == 0))
                && !key.hide
            "
            :key="`formItemBaseInfoVal${index}`"
            :label="`名称${options.baseInfoList.length > 1 ? index + 1 : ''}`"
            :required="true"
            :prop="'baseInfoList.' + index + '.label'"
            :rules="[
              {
                required: true,
                message: `名称${options.baseInfoList.length > 1 ? index + 1 : ''}不能为空`,
                whitespace: true,
                trigger: ['blur', 'change']
              },
              {
                validator: (rule, value, callback) => {
                  if (!!key.labelErrorMsg) {
                    callback(`${key.labelErrorMsg}`)
                  } else {
                    callback()
                  }
                },
                trigger: 'blur'
              }
            ]"
          >
            <a-input
              v-model.trim="key.label"
              :max-length="key.labelMaxLength"
              :disabled="key.labelDisable"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="
              !['WKText', 'WKRadio', 'WKCheckbox', 'WKAutoCompute', 'WKThreeLevel'].includes(
                selectItem.type
              ) ||
                (['WKThreeLevel'].includes(selectItem.type) && index == 0) ||
                (['WKThreeLevel'].includes(selectItem.type) && index == 1 && key.show)
            "
            :key="`formItemBaseInfoLabel${index}`"
            :label="`提示文字${options.baseInfoList.length > 1 ? index + 1 : ''}`"
          >
            <a-input
              v-model.trim="key.placeholder"
              :max-length="key.placeholderMaxLength"
              :disabled="key.placeholderDisable"
              placeholder="请输入"
            />
          </a-form-model-item>
        </template>
        <!-- 数据字段 -->
        <a-form-model-item
          v-if="isDev && typeof selectItem.model !== 'undefined' && !typeList.includes(selectItem.type)"
          label="数据字段"
        >
          <a-input v-model="selectItem.model" placeholder="请输入" />
        </a-form-model-item>
        <!-- 文字说明 -->
        <template v-if="['WKText'].includes(selectItem.type)">
          <a-form-model-item key="formItemBaseInfoLabel" label="提示文字">
            <a-textarea
              v-model.trim="options.baseInfoList[0].placeholder"
              :max-length="options.baseInfoList[0].placeholderMaxLength"
              placeholder="请输入"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
        </template>
        <!-- 日期框 -->
        <template v-if="['WKDate', 'WKDateRange'].includes(selectItem.type)">
          <a-form-model-item label="日期类型">
            <a-radio-group v-model="selectItem.options.dataType" @change="dataTypeChange">
              <a-radio :value="0" :style="{ display: 'block' }">
                年-月-日
              </a-radio>
              <a-radio :value="1" :style="{ display: 'block' }">
                年-月-日 时:分
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </template>
        <!-- 字典项的配置 -->
        <a-form-item
          v-if="['selectDictionaries', 'WKDictionary'].includes(selectItem.type)"
          label="字典项type配置"
        >
          <a-select v-model="selectItem.options.parameter" @change="dictionaryChange">
            <a-select-opt-group v-for="(key, index) in dictionaryList" :key="`Dictionaries${index}`">
              <span slot="label">{{ key.label }}</span>
              <a-select-option v-for="(item, listIndex) in key.value" :key="`listItem${listIndex}`" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-item>
        <!-- 人事套件的选项配置 -->
        <a-form-item
          v-if="['WKPersonInfo'].includes(selectItem.type)"
          label="选项type配置"
        >
          <a-select v-model="selectItem.model" @change="personInfoChange">
            <a-select-option v-for="(item, listIndex) in personInfoList" :key="`listItem${listIndex}`" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 部门、人员、图片、附件选择框 -->
        <template v-if="['WKDept', 'WKEmployee', 'WKDictionary', 'WKUploadFile', 'WKUploadImg'].includes(selectItem.type)">
          <a-form-model-item label="多选">
            <a-switch v-model="selectItem.options.multiple" />
          </a-form-model-item>
        </template>
        <!-- 单位（金额和数字框使用） -->
        <a-form-model-item v-if="['WKNumber', 'WKAmount'].includes(selectItem.type)" label="单位">
          <a-select v-model="selectItem.options.companyVal" placeholder="请选择单位">
            <a-select-option v-for="key in selectItem.options.companyList" :key="key">
              {{ key }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 公式（计算公式使用） -->
        <a-form-model-item v-if="['WKAutoCompute'].includes(selectItem.type)" label="公式">
          <div class="form-autocompute" @click="selectItem.options.visible = true">
            <span>计算公式=</span>
            <span v-html="validArgs" />
          </div>
        </a-form-model-item>
        <!-- 金额的格式配置 -->
        <template v-if="['WKAmount', 'WKAutoCompute', 'WKNumber'].includes(selectItem.type)">
          <a-form-model-item label="格式">
            <a-checkbox-group v-model="options.ruleType">
              <a-checkbox v-if="!['WKNumber'].includes(selectItem.type)" :value="0">
                显示大写数字
              </a-checkbox>
              <a-checkbox v-if="!['WKNumber'].includes(selectItem.type)" :value="1">
                显示千分位分隔符
              </a-checkbox>
              <a-checkbox :value="2">
                显示
                <a-input
                  v-model="options.floatNum"
                  size="small"
                  style="width: 40px"
                  :disabled="!options.ruleType.includes(2)"
                  @change="
                    (e) =>
                      (options.floatNum =
                        e.target.value > 2 ? 2 : e.target.value < 0 ? 0 : e.target.value)
                  "
                />位小数位数
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
        </template>
        <KChangeOption
          v-if="options.options && options.options.length > 0"
          v-model="options.options"
          :max-length="options.optionLength"
          :type="selectItem.type"
        />
        <a-form-model-item v-if="selectItem.showSummari" label="是否汇总">
          <a-switch v-model="options.summari" />
        </a-form-model-item>
        <!-- 针对内置流程是否为摘要字段的配置 -->
        <a-form-model-item v-if="isDev && !typeList.includes(selectItem.type)" label="是否为摘要字段">
          <a-switch v-model="selectItem.isSummary" />
        </a-form-model-item>
        <!-- 针对时间区间的【时长】计算是否为自动生成 -->
        <a-form-model-item v-if="selectItem.type === 'WKDateRange'" label="是否自动计算时长">
          <a-switch v-model="selectItem.autoCalc" />
        </a-form-model-item>
        <a-form-model-item
          v-if="!['WKText', 'WKAutoCompute'].includes(selectItem.type) && !typeList.includes(selectItem.type)"
          label="是否必填"
        >
          <a-switch v-model="selectItem.isRequire" :disabled="selectItem.requireDisable" />
        </a-form-model-item>
        <a-form-item v-if="!typeList.includes(selectItem.type)" label="是否打印">
          <a-switch v-model="selectItem.isPrint" />
        </a-form-item>
        <a-form-model-item
          v-if="['WKThreeLevel'].includes(selectItem.type)"
          label="详细地址是否展示"
        >
          <a-switch v-model="options.baseInfoList[1].show" />
        </a-form-model-item>
        <a-form-model-item
          v-if="['WKThreeLevel'].includes(selectItem.type) && options.baseInfoList[1].show"
          label="详细地址是否必填"
        >
          <a-switch v-model="selectItem.isAddrRequire" :disabled="selectItem.addrRequireDisable" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import KChangeOption from '../../KChangeOption/index.vue'
import kCheckbox from '../../KCheckbox/index.vue'
import { suiteTypeList } from '../../core/suite'
export default {
  name: 'FormItemProperties',
  components: {
    KChangeOption,
    kCheckbox
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    hideModel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'kForm'
    }
  },
  data() {
    return {
      dictionaryList: require('@/components/CbDropDownInput/dictionariesInput/index.json'),
      typeList: suiteTypeList(),
      familyOptions: [
        // 字体选择设置
        {
          value: 'SimSun',
          label: '宋体'
        },
        {
          value: 'FangSong',
          label: '仿宋'
        },
        {
          value: 'SimHei',
          label: '黑体'
        },
        {
          value: 'PingFangSC-Regular',
          label: '苹方'
        },
        {
          value: 'KaiTi',
          label: '楷体'
        },
        {
          value: 'LiSu',
          label: '隶书'
        }
      ],
      sizeOptions: [
        // 字号选择设置
        {
          value: '26pt',
          label: '一号'
        },
        {
          value: '24pt',
          label: '小一'
        },
        {
          value: '22pt',
          label: '二号'
        },
        {
          value: '18pt',
          label: '小二'
        },
        {
          value: '16pt',
          label: '三号'
        },
        {
          value: '15pt',
          label: '小三'
        },
        {
          value: '14pt',
          label: '四号'
        },
        {
          value: '12pt',
          label: '小四'
        },
        {
          value: '10.5pt',
          label: '五号'
        },
        {
          value: '9pt',
          label: '小五'
        }
      ],
      personInfoList: [
        {
          label: '计划转正日期',
          value: 'planFormalDate'
        },
        {
          label: '汇报上级',
          value: 'reportId'
        },
        {
          label: '部门',
          value: 'orgName'
        },
        {
          label: '岗位',
          value: 'postName'
        },
        // {
        //   label: '职务',
        //   value: 'duty'
        // },
        {
          label: '职级',
          value: 'jobGradeId'
        },
        {
          label: '工作地点',
          value: 'workplaceId'
        },
        {
          label: '聘用形式',
          value: 'engageModeName'
        },
        {
          label: '年龄',
          value: 'age'
        },
        {
          label: '司龄',
          value: 'companyAge'
        },
        {
          label: '性别',
          value: 'sex'
        },
        {
          label: '民族',
          value: 'nation'
        },
        {
          label: '籍贯',
          value: 'nativePlace'
        },
        {
          label: '生日',
          value: 'birthday'
        },
        {
          label: '婚姻情况',
          value: 'maritalStatus'
        },
        {
          label: '生育情况',
          value: 'fertilityStatus'
        },
        {
          label: '毕业院校',
          value: 'school'
        },
        {
          label: '专业',
          value: 'major'
        },
        {
          label: '最高学历',
          value: 'education'
        },
        {
          label: '毕业时间',
          value: 'endDate'
        },
        {
          label: '证件类型',
          value: 'idType'
        },
        {
          label: '证件号码',
          value: 'idNum'
        },
        {
          label: '合同公司',
          value: 'companyName'
        },
        {
          label: '入职日期',
          value: 'entryDate'
        }
      ]
    }
  },
  computed: {
    options() {
      return this.selectItem.options || {}
    },
    // 是否是开发环境
    isDev() {
      return process.env.VUE_APP_ENV === 'development'
    },
    amountComponents: function() {
      let list = []
      // 获取金额、数字组件
      const amountComponents = this.data.list.filter((v) => {
        return ['WKNumber', 'WKAmount'].includes(v.type)
      })
      list = amountComponents.map((v) => {
        return {
          name: v.options.baseInfoList[0].label,
          model: v.model
        }
      })
      // 获取明细中的金额、数字组件
      const wordTableComponents = this.data.list.filter((v) => {
        return ['WKWordTable'].includes(v.type)
      })
      for (let i = 0; i < wordTableComponents.length; i++) {
        const nums = wordTableComponents[i].list.map((v) => {
          return {
            name: `${wordTableComponents[i].options.baseInfoList[0].label}.${v.options.baseInfoList[0].label}`,
            model: `${wordTableComponents[i].model}.${v.model}`
          }
        })
        list = list.concat(nums)
      }
      return list
    },
    validArgs: function() {
      let val = this.selectItem.options.exp
      const args = this.selectItem.options.args
      if (args && args.length > 0) {
        for (let i = 0; i < args.length; i++) {
          const comp = this.amountComponents.filter((v) => {
            return v.model === args[i]
          })
          val = val.replace(
            new RegExp('\\${' + i + '\\}', 'g'),
            (comp[0] || { name: '<span style="color:red;">无效目标</span>' }).name
          )
        }
      }
      return val
    }
  },
  watch: {
    selectItem: {
      handler: function(val) {
        if (this.selectItem.key) {
          this.validForm()
        }
      }
    }
  },
  methods: {
    dictionaryChange(value) {
      // this.selectItem.options.parameter = value
      this.dictionaryList.forEach(item => {
        item.value.forEach(key => {
          if (key.value === value) {
            this.selectItem.options.baseInfoList[0].label = key.label
          }
        })
      })
    },
    personInfoChange(value) {
      // this.selectItem.options.model = value
      this.selectItem.model = value
      this.personInfoList.forEach(item => {
        if (item.value === value) {
          this.selectItem.options.baseInfoList[0].label = item.label
        }
      })
    },
    dataTypeChange() {
      const obj = {
        0: '天',
        1: '小时'
      }
      const { dataType } = this.selectItem.options
      this.selectItem.options.companyVal = obj[dataType]
    },
    // 验证当前的表单项
    validForm() {
      if (!this.$refs.ruleForm) return
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.selectItem.hasErrorValid = false
          } else {
            this.selectItem.hasErrorValid = true
          }
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.form-autocompute {
  font-family: PingFang SC, Microsoft YaHei;
  font-size: 14px;
  line-height: 20px;
  line-height: 22px;
  padding: 4px 11px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  word-break: break-all;
  cursor: pointer;
}
</style>
