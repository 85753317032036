// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}

const routerMap = {
  staffIndex: {
    name: 'user',
    // subName: '员工',
    component: view.blank
  },
  archive: {
    name: 'userArchive',
    // subName: '档案',
    component: () => import('@/pages/user/archive')
  },
  certificate: {
    name: 'userCertificate',
    // subName: '证明',
    component: () => import('@/pages/user/certificate')
  },
  induction: {
    name: 'userInduction',
    // subName: '入职',
    component: () => import('@/pages/user/Induction')
  },
  quit: {
    name: 'userQuit',
    // subName: '离职',
    component: () => import('@/pages/user/quit')
  },
  userReportForm: {
    name: 'userReportForm',
    // subName: '报表',
    component: () => import('@/pages/user/reportForm')
  },
  roster: {
    name: 'userRoster',
    // subName: '花名册',
    component: () => import('@/pages/user/roster')
  },
  transfer: {
    name: 'userTransfer',
    // subName: '调动',
    component: () => import('@/pages/user/transfer')
  },
  worker: {
    name: 'userWorker',
    // subName: '转正',
    component: () => import('@/pages/user/worker')
  },
  userSetting: {
    name: 'userSetting',
    // subName: '设置',
    // redirect: 'userSetting/settingUserInfo',
    component: () => import('@/pages/user/setting')
  },
  userDetail: {
    name: 'userDetail',
    // subName: '用户详情',
    component: () => import('@/pages/user/detail')
  }
}

export default routerMap
