<template>
  <a-modal
    title="预览"
    :visible="visible"
    ok-text="获取数据"
    cancel-text="关闭"
    :destroy-on-close="true"
    :centered="true"
    :dialog-style="dialogStyle"
    :body-style="bodyStyle"
    :width="`${previewWidth}px`"
    :footer="null"
    @ok="handleGetData"
    @cancel="handleCancel"
  >
    <div class="phone-panel">
      <div class="scroll-p">
        <k-form-build ref="KFormBuild" :value="jsonData" @submit="handleSubmit" />
      </div>
    </div>
    <jsonModel ref="jsonModel" />
  </a-modal>
</template>
<script>
/*
 * author kcz
 * date 2019-11-20
 */
import jsonModel from '../KFormDesign/module/jsonModal'
export default {
  name: 'KFormPreview',
  components: {
    jsonModel
  },
  data() {
    return {
      visible: false,
      previewWidth: 850,
      jsonData: {},
      dialogStyle: {
        position: 'absolute',
        right: '150px',
        left: '150px',
        minWidth: '700px',
        width: 'auto',
        top: '30px',
        bottom: '30px',
        minHeight: '500px'
      },
      bodyStyle: {
        height: 'calc(100% - 50px)',
        overflow: 'auto',
        backgroundColor: '#eee'
      }
    }
  },
  methods: {
    handleSubmit(p) {
      p.then((res) => {
        console.log(res, '获取数据成功')
        this.$refs.jsonModel.jsonData = res
        this.$refs.jsonModel.visible = true
      }).catch((err) => {
        console.error(err, '获取数据失败')
      })
    },
    handleGetData() {
      this.$refs.KFormBuild.getData()
        .then((res) => {
          console.log(res, '获取数据成功')
          this.$refs.jsonModel.jsonData = res
          this.$refs.jsonModel.visible = true
        })
        .catch((err) => {
          console.log(err, '获取数据失败')
        })
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.ant-modal-root::v-deep .ant-modal-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ant-modal-root::v-deep .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  background: rgba(0, 0, 0, 0.2);
  scrollbar-arrow-color: red;
}
.ant-modal-root::v-deep .ant-modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.ant-modal-root::v-deep .ant-modal-content {
  height: 100%;
}
.phone-panel {
  position: relative;
  width: 390px;
  height: 790px;
  background-image: url('../../../../assets/img/iPhone13.png');
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 30px;
  padding: 50px 15px 30px;
  overflow-y: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .scroll-p {
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
  }
}
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
</style>
