<template>
  <div class="option-change-container">
    <a-row v-if="type === 'option' || type === 'tab'" :gutter="8">
      <div v-for="(val, index) in value" :key="index" class="option-change-box">
        <a-col :span="9"><a-input v-model="val.label" placeholder="名称" :disabled="disabled" /></a-col>
        <a-col :span="9"><a-input v-model="val.value" placeholder="值" :disabled="disabled" /></a-col>
        <a-col v-if="!disabled" :span="6">
          <div class="option-delete-box" @click="handleDelete(index)">
            <a-icon type="delete" />
          </div>
        </a-col>
      </div>
      <a-col v-if="!disabled" :span="24"><a @click="handleAdd">添加</a></a-col>
    </a-row>

    <div v-if="type === 'WKRadio'">
      <a-radio-group v-model="selectValue" :disabled="disabled" @change="selectDefault">
        <a-radio
          v-for="(key, index) in value"
          :key="`radio${index}`"
          class="radioItem"
          :value="index"
        >
          <a-input v-model="key.label" :max-length="50" class="int" placeholder="选项名称" />
          <a-icon
            v-show="value.length > 1"
            type="delete"
            class="delBtn"
            @click="handleDelete(index)"
          />
        </a-radio>
      </a-radio-group>
      <div v-if="!disabled" v-show="value.length < maxLength">
        <a @click="handleAdd">添加选项</a>
      </div>
    </div>
    <div v-if="type === 'WKCheckbox'">
      <a-checkbox-group v-model="selectValue" :disabled="disabled" @change="selectDefault">
        <a-checkbox
          v-for="(key, index) in value"
          :key="`checkbox${index}`"
          class="radioItem"
          :value="index"
        >
          <a-input v-model="key.label" :max-length="50" class="int" placeholder="选项名称" />
          <a-icon
            v-show="value.length > 1"
            type="delete"
            class="delBtn"
            @click="handleDelete(index)"
          />
        </a-checkbox>
      </a-checkbox-group>
      <div v-if="!disabled" v-show="value.length < maxLength">
        <a @click="handleAdd">添加选项</a>
      </div>
    </div>
    <div v-if="type === 'WKMultiSelect' || type === 'WKSelect'">
      <a-form-model-item
        label="选项"
        class="radioItem"
      >
        <a-form-model-item
          v-for="(key, index) in value"
          :key="`select${index}`"
          label=""
          :prop="'options.' + index + '.label'"
          :rules="{
            required: true,
            message: '选项不能为空',
            trigger: 'blur'
          }"
        >
          <a-input v-model="key.label" :max-length="50" :disabled="disabled" class="int" placeholder="选项名称" />
          <a-icon
            v-if="!disabled"
            v-show="value.length > 1"
            type="delete"
            class="delBtn"
            @click="handleDelete(index)"
          />
        </a-form-model-item>
      </a-form-model-item>
      <div v-if="!disabled" v-show="value.length < maxLength">
        <a @click="handleAdd">添加选项</a>
      </div>
    </div>

    <a-row v-if="type === 'rules'" :gutter="8">
      <span v-for="(val, index) in value" :key="index">
        <div v-if="index !== 0" class="option-change-box">
          <a-col :span="18"><a-input v-model="val.message" placeholder="提示信息" /></a-col>
          <a-col :span="18"><a-input v-model="val.pattern" placeholder="正则表达式pattern" /></a-col>
          <a-col :span="6">
            <div class="option-delete-box" @click="handleDelete(index)">
              <a-icon type="delete" />
            </div>
          </a-col>
        </div>
      </span>
      <a-col :span="24"><a @click="handleAddRules">增加校验</a></a-col>
    </a-row>
    <a-row v-else-if="type === 'colspan'" :gutter="8">
      <div v-for="(val, index) in value" :key="index" class="option-change-box">
        <a-col :span="18">
          <a-input-number v-model="val.span" style="width:100%" :max="24" placeholder="名称" />
        </a-col>
        <a-col :span="6">
          <div class="option-delete-box" @click="handleDelete(index)">
            <a-icon type="delete" />
          </div>
        </a-col>
      </div>
      <a-col :span="24"><a @click="handleAddCol">添加</a></a-col>
    </a-row>
  </div>
</template>
<script>
/*
 * author kcz
 * date 2019-11-20
 * description 修改多选、下拉、单选等控件options的组件，添加移除校验规制的组件
 */
export default {
  name: 'KChangeOption',
  props: {
    value: {
      type: Array,
      required: true
    },
    maxLength: {
      type: Number,
      default: 10
    },
    type: {
      type: String,
      default: 'option'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectValue: undefined
    }
  },
  watch: {
    type: {
      immediate: true,
      handler: function(val) {
        if (val === 'WKCheckbox' || val === 'WKMultiSelect') {
          this.selectValue = []
        } else {
          this.selectValue = -1
        }
      }
    }
  },
  methods: {
    handleAdd() {
      // 添加
      const addData = [
        ...this.value,
        {
          value: `${this.value.length + 1}`,
          label: '选项' + (this.value.length + 1),
          list: this.type === 'tab' ? [] : undefined
        }
      ]
      this.$emit('input', addData)
    },
    handleAddCol() {
      // 添加栅格Col
      const addData = [
        ...this.value,
        {
          span: 12,
          list: []
        }
      ]
      this.$emit('input', addData)
    },
    handleAddRules() {
      const addData = [
        ...this.value,
        {
          pattern: '',
          message: ''
        }
      ]
      this.$emit('input', addData)
    },
    handleDelete(deleteIndex) {
      // 删除
      this.$emit(
        'input',
        this.value.filter((val, index) => index !== deleteIndex)
      )
    },
    selectDefault() {
      this.$emit('selectDefault', this.selectValue)
    }
  }
}
</script>
<style lang="less" scoped>
.option-change-container {
  width: calc(100% - 8px);
}
.option-change-box {
  height: 38px;
  padding-bottom: 6px;
  .option-delete-box {
    margin-top: 3px;
    background: #ffe9e9;
    color: #f22;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.3s;
    &:hover {
      background: #f22;
      color: #fff;
    }
  }
}
.radioItem {
  width: 100%;
  padding: 10px 0;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  .int {
    width: 85%;
  }
  .delBtn {
    margin-left: 10px;
  }
}
</style>
