// 考勤-设置-假期
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 假期-法定节假日
// 查询法定节假列表
export async function selectFestival(params) {
  return request(`${ATTENDACE}/config/legalHoliday/selectFestival`, METHOD.GET, params)
}

// 创建法定节假
export async function createFestival(params) {
  return request(`${ATTENDACE}/config/legalHoliday/createFestival`, METHOD.POST, params)
}

// 编辑法定节假
export async function updateFestival(params) {
  return request(`${ATTENDACE}/config/legalHoliday/updateFestival`, METHOD.POST, params)
}

// 编辑法定节假启用状态
export async function updateFestivalStatus(params) {
  return request(`${ATTENDACE}/config/legalHoliday/updateFestivalStatus`, METHOD.POST, params)
}

// 查询节假详情
export async function selectFestivalDetail(params) {
  return request(`${ATTENDACE}/config/legalHoliday/selectFestivalDetail`, METHOD.GET, params)
}

// 查询年份列表
export async function selectYearList(params) {
  return request(`${ATTENDACE}/config/legalHoliday/yearList`, METHOD.GET, params)
}

// 假期-假期类型
// 分页查询假期类型
export async function getVacationTypeList(params) {
  return request(`${ATTENDACE}/config/vacationType/getList`, METHOD.GET, params)
}

// 查询假期类型字典列表 - 下拉使用
export async function getVacationType(params) {
  return request(`${ATTENDACE}/config/vacationType/dictList`, METHOD.GET, params)
}

// 新增假期类型
export async function createVacationType(params) {
  return request(`${ATTENDACE}/config/vacationType/create`, METHOD.POST, params)
}

// 修改假期类型
export async function updateVacationType(params) {
  return request(`${ATTENDACE}/config/vacationType/update`, METHOD.POST, params)
}

// 删除假期类型
export async function deleteVacationType(params) {
  return request(`${ATTENDACE}/config/vacationType/delete`, METHOD.POST, params)
}

// 更新假期类型启用状态
export async function updateVacationTypeStatus(params) {
  return request(`${ATTENDACE}/config/vacationType/updateEnableStatus`, METHOD.POST, params)
}

// 假期-假期方案
// 查询假期方案列表
export async function getVacationRuleList(params) {
  return request(`${ATTENDACE}/config/vacationRule/getList`, METHOD.GET, params)
}

// 查询假期方案详情
export async function getRuleDetail(params) {
  return request(`${ATTENDACE}/config/vacationRule/getDetail`, METHOD.GET, params)
}

// 创建假期方案
export async function createVacationRule(params) {
  return request(`${ATTENDACE}/config/vacationRule/create`, METHOD.POST, params)
}

// 修改假期方案
export async function updateVacationRule(params) {
  return request(`${ATTENDACE}/config/vacationRule/update`, METHOD.POST, params)
}

// 删除假期方案
export async function deleteVacationRule(params) {
  return request(`${ATTENDACE}/config/vacationRule/delete`, METHOD.POST, params)
}

// 删除假期方案
export async function getVacationUnitApi(params) {
  return request(`${ATTENDACE}/config/vacationRule/getVacationUnit`, METHOD.GET, params)
}

// 考勤-假期-概览

// 日历查询
export async function vacationcalendar(params) {
  return request(`${ATTENDACE}/vacationView/vacationCalendar`, METHOD.GET, params)
}

// 列表查询
export async function vacationList(params) {
  return request(`${ATTENDACE}/staffApplyVacation/vacationList`, METHOD.GET, params)
}

// 日历查询统计汇总
export async function vacationCalendarCollect(params) {
  return request(`${ATTENDACE}/vacationView/vacationCalendarCollect`, METHOD.GET, params)
}

// 添加请假记录-单条
export async function addVacation(params) {
  return request(`${ATTENDACE}/staffApplyVacation/addVacation`, METHOD.POST, params)
}

// 考勤-假期-调整

// 查询假期调整
export async function selectAdjustment(params) {
  return request(`${ATTENDACE}/vacationAdjustment/page`, METHOD.GET, params)
}
// 假期单个调整
export async function addVacationBalanceAdjustment(params) {
  return request(`${ATTENDACE}/vacationAdjustment/addVacationBalanceAdjustment`, METHOD.POST, params)
}
// 查询当前员工假期余额
export async function getStaffVacationBalance(params) {
  return request(`${ATTENDACE}/vacationAdjustment/getStaffVacationBalance`, METHOD.GET, params)
}
// 修改假期调整记录
export async function updateVacationBalanceAdjustment(params) {
  return request(`${ATTENDACE}/vacationAdjustment/updateVacationBalanceAdjustment`, METHOD.POST, params)
}
// 导出假期调整记录
export async function exportVacationAdjustment(params) {
  return request(`${ATTENDACE}/vacationAdjustment/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 导入假期调整记录
export async function getImportTemplate(params) {
  return request(`${ATTENDACE}/vacationAdjustment/getImportTemplate`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 批量调整假期
export async function batchImportVacationAdjustment(params) {
  return request(`${ATTENDACE}/vacationAdjustment/batchImport`, METHOD.POST, params)
}
// 批量删除
export async function deleteVacationAdjustment(params) {
  return request(`${ATTENDACE}/vacationAdjustment/deleteVacationBalanceAdjustment`, METHOD.POST, params)
}

// 考勤-假期-余额

// 分页查询假期余额
export async function selectBalance(params) {
  return request(`${ATTENDACE}/vacationBalance/selectVacationBalanceList`, METHOD.GET, params)
}
// 假期余额记录
export async function balanceRecord(params) {
  return request(`${ATTENDACE}/vacationBalance/selectBalanceRecordList`, METHOD.GET, params)
}
// 导出假期余额列表
export async function exportVacationBalance(params) {
  return request(`${ATTENDACE}/vacationBalance/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 下载批量修改假期余额数据模板
export async function getImportTemplateVacationBalance(params) {
  return request(`${ATTENDACE}/vacationBalance/getImportTemplate`, METHOD.GET, params)
}
// 批量修改假期余额
export async function batchImportVacationBalance(params) {
  return request(`${ATTENDACE}/vacationBalance/batchImport`, METHOD.POST, params)
}

// 假期-假期提醒
// 假期提醒列表
export async function getTenantConfigList(params) {
  return request(`${ATTENDACE}/config/tenantConfig/getTenantConfigList`, METHOD.POST, params)
}

export async function updateTenantConfigApi(params) {
  return request(`${ATTENDACE}/config/tenantConfig/updateTenantConfig`, METHOD.POST, params)
}

// 假期提醒开关
export async function updateTenantConfig(params) {
  return request(`${ATTENDACE}/config/tenantConfig/updateTenantConfig`, METHOD.POST, params)
}

