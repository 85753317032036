const client = require('webpack-theme-color-replacer/client')
const { theme } = require('../config')
const {
  getMenuColors,
  getAntdColors,
  getThemeToggleColors,
  getFunctionalColors,
  batchTransHexWithOpacitys
} = require('../utils/colors')
const { ANTD } = require('../config/default')

function getThemeColors(color, $theme) {
  const _color = color || theme.color
  const mode = $theme || theme.mode
  const replaceColors = getThemeToggleColors(_color, mode)
  const customColors = batchTransHexWithOpacitys(_color, theme.colorOpacitys)
  const customGreyColors = batchTransHexWithOpacitys(theme.grey, theme.greyOpacitys)
  const customGreyBgColors = batchTransHexWithOpacitys(theme.greyBg, theme.greyBgOpacitys)
  const themeColors = [
    ...customColors,
    ...customGreyColors,
    ...customGreyBgColors,
    ...replaceColors.mainColors,
    ...replaceColors.subColors,
    ...replaceColors.menuColors,
    ...replaceColors.contentColors,
    ...replaceColors.rgbColors,
    ...replaceColors.functionalColors.success,
    ...replaceColors.functionalColors.warning,
    ...replaceColors.functionalColors.error
  ]
  return themeColors
}

function changeThemeColor(newColor, $theme) {
  const promise = client.changer.changeColor({
    newColors: getThemeColors(newColor, $theme)
  })
  return promise
}

function modifyVars(color) {
  const _color = color || theme.color
  const palettes = getAntdColors(_color, theme.mode)
  const menuColors = getMenuColors(_color, theme.mode)
  const { success, warning, error, grey, greyBg } = getFunctionalColors(theme.mode)
  const primary = palettes[5]
  const customColors = batchTransHexWithOpacitys(_color, theme.colorOpacitys)
  const customGreyColors = batchTransHexWithOpacitys(grey, theme.greyOpacitys)
  const customGreyBgColors = batchTransHexWithOpacitys(greyBg, theme.greyBgOpacitys)
  return {
    'sc-primary-100': customColors[0],
    'sc-primary-80': customColors[1],
    'sc-primary-60': customColors[2],
    'sc-primary-40': customColors[3],
    'sc-primary-10': customColors[4],
    'sc-grey-100': customGreyColors[0],
    'sc-grey-80': customGreyColors[1],
    'sc-grey-60': customGreyColors[2],
    'sc-grey-40': customGreyColors[3],
    'sc-grey-20': customGreyColors[4],
    'sc-grey-10': customGreyColors[5],
    'sc-greyBg-100': customGreyBgColors[0],
    'sc-greyBg-50': customGreyBgColors[1],
    'sc-greyBg-20': customGreyBgColors[2],
    'sc-greyBg-10': customGreyBgColors[3],
    'primary-color': primary,
    'primary-1': palettes[0],
    'primary-2': palettes[1],
    'primary-3': palettes[2],
    'primary-4': palettes[3],
    'primary-5': palettes[4],
    'primary-6': palettes[5],
    'primary-7': palettes[6],
    'primary-8': palettes[7],
    'primary-9': palettes[8],
    'primary-10': palettes[9],
    'info-color': primary,
    'success-color': success[5],
    'warning-color': warning[5],
    'error-color': error[5],
    'alert-info-bg-color': palettes[0],
    'alert-info-border-color': palettes[2],
    'alert-success-bg-color': success[0],
    'alert-success-border-color': success[2],
    'alert-warning-bg-color': warning[0],
    'alert-warning-border-color': warning[2],
    'alert-error-bg-color': error[0],
    'alert-error-border-color': error[2],
    'processing-color': primary,
    'menu-dark-submenu-bg': menuColors[0],
    'layout-header-background': menuColors[1],
    'layout-trigger-background': menuColors[2],
    'btn-danger-bg': error[4],
    'btn-danger-border': error[4],
    'layout-color': '#9867f7',
    'table-padding-vertical': '10px',
    'table-padding-horizontal': '8px',
    ...ANTD.theme[theme.mode]
  }
}

function loadLocalTheme(localSetting) {
  if (localSetting && localSetting.theme) {
    let { color, mode } = localSetting.theme
    color = color || theme.color
    mode = mode || theme.mode
    changeThemeColor(color, mode)
  }
}

/**
 * 获取本地保存的配置
 * @param load {boolean} 是否加载配置中的主题
 * @returns {Object}
 */
function getLocalSetting(loadTheme) {
  let localSetting = {}
  try {
    const localSettingStr = localStorage.getItem(process.env.VUE_APP_SETTING_KEY)
    localSetting = JSON.parse(localSettingStr)
  } catch (e) {
    console.error(e)
  }
  if (loadTheme) {
    loadLocalTheme(localSetting)
  }
  return localSetting
}

module.exports = {
  getThemeColors,
  changeThemeColor,
  modifyVars,
  loadLocalTheme,
  getLocalSetting
}
