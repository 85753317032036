/*
 * @Date: 2022-07-04 14:20:10
 * @descriotion: 社保
 */
// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}
const routerMap = {
  socialSecurity: {
    name: 'socialSecurity',
    // subName: '社保',
    component: view.blank
  },
  bill: {
    name: 'socialSecurityBill',
    // subName: '账单',
    component: () => import('@/pages/socialSecurity/bill')
  },
  securityArchives: {
    name: 'socialSecurityArchives',
    // subName: '档案',
    component: () => import('@/pages/socialSecurity/archives')
  },
  supplementaryPayment: {
    name: 'supplementaryPayment',
    // subName: '补缴',
    component: () => import('@/pages/socialSecurity/supplementaryPayment')
  },
  securityReportForm: {
    name: 'socialSecurityReportForm',
    // subName: '报表',
    component: () => import('@/pages/socialSecurity/reportForm')
  },
  securitySetting: {
    name: 'socialSecuritySetting',
    // subName: '设置',
    component: () => import('@/pages/socialSecurity/setting')
  },
  securityArchivesDetail: {
    name: 'socialSecurityDetail',
    // icon: 'qrcode',
    component: () => import('@/pages/socialSecurity/archives/detail')
  }
}

export default routerMap
