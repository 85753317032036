<template>
  <DepTreeSelect
    v-model="data"
    :placeholder="record.options.placeholder"
    :disabled="record.options.disabled"
    :multiple="record.options.multiple"
    :has-relation="hasRelation"
    :id-list="idList"
    :clear-post-val="clearPostVal"
    :clear-rank-val="clearRankVal"
    :disabled-rank="disabledRank"
    :disabled-post="disabledPost"
  />
</template>
<script>
import depTreeSelect from '@/components/CbPerDepSelection/index.js'
export default {
  name: 'SelectDepartment',
  mixins: [depTreeSelect],
  // components: {
  //   DepTreeSelect: depTreeSelect
  // },
  inject: ['hasRelation', 'idList', 'clearPostVal', 'clearRankVal', 'disabledRank', 'disabledPost'],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    return {
      data: ''
    }
  },
  watch: {
    data(val) {
      if (this.hasRelation) {
        this.idList.orgId = val ? val[0].value : ''
        if (!val) {
          this.disabledPost(true)
          this.disabledRank(true)
          this.$emit('change', this.data)
          return
        }
        this.disabledPost(!val || val.length === 0)
        this.disabledRank(this.idList && !this.idList.postId)
      }
      this.$emit('change', this.data)
    },
    value() {
      this.data = this.value
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.data = this.value
      if (this.hasRelation) {
        if (this.value && this.value.length === 0) {
          this.clearPostVal()
          this.disabledPost()
        }
        if (this.idList && !this.idList.postId) {
          this.clearRankVal()
          this.disabledRank()
        }
      }
    })
  }
}
</script>
<style lang="less" scoped>

</style>
