module.exports = {
  messages: {
    CN: {
      home: { name: '首页' }
    },
    US: {
      home: { name: 'home' }
    },
    HK: {
      home: { name: '首頁' },
      dashboard: {
        name: 'Dashboard',
        workplace: { name: '工作台' },
        analysis: { name: '分析頁' }
      },
      form: {
        name: '表單頁',
        basic: { name: '基礎表單' },
        step: { name: '分步表單' },
        advance: { name: '分步表單' }
      },
      list: {
        name: '列表頁',
        query: { name: '查詢表格' },
        primary: { name: '標準列表' },
        card: { name: '卡片列表' },
        search: {
          name: '搜索列表',
          article: { name: '文章' },
          application: { name: '應用' },
          project: { name: '項目' }
        }
      },
      details: {
        name: '詳情頁',
        basic: { name: '基礎詳情頁' },
        advance: { name: '高級詳情頁' }
      },
      result: {
        name: '結果頁',
        success: { name: '成功' },
        error: { name: '失敗' }
      },
      exception: {
        name: '異常頁',
        404: { name: '404' },
        403: { name: '403' },
        500: { name: '500' }
      },
      components: {
        name: '小組件',
        taskCard: { name: '任務卡片' },
        palette: { name: '顏色複選框' }
      }
    }
  }
}
