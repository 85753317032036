// 薪酬-薪资组
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 薪资组列表
export async function listGroup(params) {
  return request(`${WAGE}/salaryGroup/listGroup`, METHOD.GET, params)
}

// 给单个员工设置薪资组
export async function setStaffGroup(params) {
  return request(`${WAGE}/salaryGroup/setStaffGroup`, METHOD.POST, params)
}

// 新增薪资组
export async function addGroup(params) {
  return request(`${WAGE}/salaryGroup/addGroup`, METHOD.POST, params)
}

// 更新薪资组基本信息
export async function updateBaseInfo(params) {
  return request(`${WAGE}/salaryGroup/updateBaseInfo`, METHOD.POST, params)
}

// 更新薪资组薪资规则
export async function updateRule(params) {
  return request(`${WAGE}/salaryGroup/updateRule`, METHOD.POST, params)
}

// 查询薪资组基本信息
export async function getBaseInfo(params) {
  return request(`${WAGE}/salaryGroup/getBaseInfo`, METHOD.GET, params)
}

// 查询薪资组规则
export async function getRule(params) {
  return request(`${WAGE}/salaryGroup/getRule`, METHOD.GET, params)
}

// 复制薪资组
export async function copyGroup(params) {
  return request(`${WAGE}/salaryGroup/copyGroup`, METHOD.POST, params)
}

// 删除薪资组
export async function deleteGroup(params) {
  return request(`${WAGE}/salaryGroup/deleteGroup`, METHOD.POST, params)
}

// 清空薪资组人员
export async function clearUser(params) {
  return request(`${WAGE}/salaryGroup/clearUser`, METHOD.POST, params)
}

// 薪资组-薪资项
// 新增薪资项分类
export async function addType(params) {
  return request(`${WAGE}/salaryItem/addType`, METHOD.POST, params)
}

// 更新薪资项分类
export async function updateType(params) {
  return request(`${WAGE}/salaryItem/updateType`, METHOD.POST, params)
}

// 删除薪资项分类
export async function deleteType(params) {
  return request(`${WAGE}/salaryItem/deleteType`, METHOD.POST, params)
}

// 删除薪资项
export async function deleteItem(params) {
  return request(`${WAGE}/salaryItem/deleteItem`, METHOD.POST, params)
}

// 列出薪资组下的薪资项
export async function listItem(params) {
  return request(`${WAGE}/salaryItem/listItem`, METHOD.GET, params)
}

// 查询薪资分类下未配置的薪资项
export async function getUnconfigItems(params) {
  return request(`${WAGE}/salaryItem/getUnconfigItems`, METHOD.GET, params)
}

// 新增薪资项查询系统规则
export async function getItemConfig(params) {
  return request(`${WAGE}/salaryItem/getItemConfig`, METHOD.GET, params)
}

// 查询没有明细的薪资项计算规则
export async function getSingleItemConfig(params) {
  return request(`${WAGE}/salaryItem/getSingleItemConfig`, METHOD.GET, params)
}

// 查询已配置的薪资项详情
export async function getItemDetail(params) {
  return request(`${WAGE}/salaryItem/getItemDetail`, METHOD.GET, params)
}

// 保存薪资项
export async function saveItem(params) {
  return request(`${WAGE}/salaryItem/saveItem`, METHOD.POST, params)
}

// 启用/停用薪资项分类
export async function enableType(params) {
  return request(`${WAGE}/salaryItem/enableType`, METHOD.POST, params)
}

// 更新薪资分类排序
export async function updateTypeOrder(params) {
  return request(`${WAGE}/salaryItem/updateTypeOrder`, METHOD.POST, params)
}

// 查询公式用到的指标项
export async function getIndexItem(params) {
  return request(`${WAGE}/formulaCalc/getIndexItem`, METHOD.GET, params)
}

// 系统公式测算
export async function formulaCalc(params) {
  return request(`${WAGE}/formulaCalc/system`, METHOD.POST, params)
}

// 删除薪资固定项
export async function deleteSalaryItem(params) {
  return request(`${WAGE}/salaryItem/deleteSalaryItem`, METHOD.POST, params)
}
