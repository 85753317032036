<template>
  <div
    class="commonBox"
    :class="{
      'wkflow-error-tip': !!record.options.baseInfoList[0].labelErrorMsg || record.hasErrorValid
    }"
  >
    <template v-if="record.type === 'WKThreeLevel'">
      <template v-for="(item, index) in record.options.baseInfoList">
        <template v-if="[0,3].includes(index)">
          <div :key="`baseInfoList${index}`" class="baseInfoItemBox">
            <div class="label" :class="{ 'require': record.isRequire}">{{ item.label }}</div>
            <div class="right">
              <div class="value">{{ item.placeholder }}</div>
              <a-icon v-if="isSelect" type="right" />
            </div>
          </div>
        </template>
      </template>
    </template>
    <template v-else>
      <template v-for="(item, index) in record.options.baseInfoList">
        <div :key="`baseInfoList${index}`" class="baseInfoItemBox">
          <div class="label" :class="{ 'require': record.isRequire}">{{ item.label }}</div>
          <div class="right">
            <div class="value">{{ item.placeholder }}</div>
            <a-icon v-if="isSelect" type="right" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import mixins from '../mixins'
export default {
  name: 'Amount',
  mixins: [mixins],
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Object],
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    parentDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectTypeList: ['WKSelect', 'WKMultiSelect', 'WKDate', 'WKDateRange']
    }
  },
  computed: {
    isSelect() {
      return this.selectTypeList.includes(this.record.type)
    },
    text() {
      return this.selectTypeList.includes(this.record.type) ? '请选择' : '请输入'
    }
  }
}
</script>
<style lang="less" scoped>
.require{
  &::after{
    content: '*';
    color: red;
  }
}
.baseInfoItemBox{
  display: flex;
  justify-content: space-between!important;
  align-items: flex-start!important;
  .label{
    width: 120px;
  }
  .right{
    display: flex;
    align-items: center;
    width: 100%;
    color: @sc-grey-40;
    .value{
      width: 100%;
      text-align: right;
    }
  }
}
</style>
