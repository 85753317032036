import jschardet from 'jschardet'
import Papa from 'papaparse'
import html2canvas from 'html2canvas'
import jsPDF from './jsPdf.js'
import { message } from 'ant-design-vue'

/**
 * csv file to 2D arr
 * */
// 检查编码，引用了 jschardet
export function checkEncoding(base64Str) {
  // 这种方式得到的是一种二进制串
  var str = atob(base64Str.split(';base64,')[1])
  // console.log(str);
  // 要用二进制格式
  var encoding = jschardet.detect(str)
  encoding = encoding.encoding
  // console.log( encoding );
  if (encoding === 'windows-1252') {
    // 有时会识别错误（如UTF8的中文二字）
    encoding = 'ANSI'
  }
  return encoding
}

/**
 * csv文件转json
 * @param {*} file file对象或文件流
 * @returns
 */
export function csv(file) {
  return new Promise((resolve, reject) => {
    // let file = this.$refs.csvData.files[0]
    const fReader = new FileReader()
    fReader.readAsDataURL(file)
    fReader.onload = function(evt) {
      const data = evt.target.result
      // console.log( data );
      const encoding = checkEncoding(data)
      // console.log(encoding);
      // 转换成二维数组，需要引入Papaparse.js
      Papa.parse(file, {
        encoding: encoding,
        complete: function(results) {
          // UTF8 \r\n与\n混用时有可能会出问题
          // console.log(results)
          const res = results.data
          if (res[res.length - 1] === '') {
            // 去除最后的空行
            res.pop()
          }
          resolve(res)
        }
      })
    }
  })
}

/**
 * blob转file
 * @param {*} blob 文件流
 * @param {*} filename 文件名
 */
export function blobToFile(blob, filename) {
  return new File([blob], filename)
}

// 格式化文件流下载content-disposition的filename值
// headers为请求返回的header
export function formatFileName(headers) {
  const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
  const contentDisposition = decodeURIComponent(
    headers['content-disposition'] || headers['Content-Disposition']
  )
  const result = patt.exec(contentDisposition)
  let fileName = result[1]
  fileName = fileName.replace(/\"/g, '')
  return fileName
}

// blob文件下载
// blob为返回文件流；filename为文件名信息
export function downloadFile(blob, fileName) {
  if (window.navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName)
  } else {
    // var link = document.createElement('a')
    // link.href = window.URL.createObjectURL(blob)
    var link = document.createElement('a')
    try {
      var objectUrl = URL.createObjectURL(blob)
      link.href = objectUrl
    } catch {
      link.href = blob
    }
  }
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(objectUrl)
  document.body.removeChild(link)
}

/**
 * 将file对象（.json文件）转为json对象
 * @param {File} file file对象
 */
export function fileToJson(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (res) => {
      try {
        const { result } = res.target // 得到字符串
        const data = JSON.parse(result) // 解析成json对象
        resolve(data)
      } catch {
        resolve({})
      }
    } // 成功回调
    reader.onerror = (err) => {
      reject(err)
    } // 失败回调
    reader.readAsText(new Blob([file]), 'utf-8') // 按照utf-8编码解析
  })
}

/**
 * 将dom导出pdf
 * @param {String} dom dom对象
 * @param {String} name 下载文件名称
 */
export function exportToPDF(dom, name) {
  try {
    const shareContent = document.querySelector(dom) // 需要截图的包裹的（原生的）DOM 对象
    const width = shareContent.clientWidth // 获取dom 宽度
    const height = shareContent.clientHeight // 获取dom 高度
    const canvas = document.createElement('canvas') // 创建一个canvas节点
    const scale = 1 // 定义任意放大倍数 支持小数
    canvas.width = width * scale // 定义canvas 宽度 * 缩放
    canvas.height = height * scale // 定义canvas高度 *缩放
    canvas.style.width = shareContent.clientWidth * scale + 'px'
    canvas.style.height = shareContent.clientHeight * scale + 'px'
    canvas.getContext('2d').scale(scale, scale) // 获取context,设置scale
    const opts = {
      scale: scale, //  添加的scale 参数
      canvas: canvas, // 自定义 canvas
      logging: false, // 日志开关，便于查看html2canvas的内部执行流程
      width: width, // dom 原始宽度
      height: height,
      useCORS: true //  【重要】开启跨域配置
      //  allowTaint: true,
      //  taintTest: true,
      //  logging: true,
    }

    html2canvas(shareContent, opts).then(() => {
      console.log(123)
      var contentWidth = canvas.width
      var contentHeight = canvas.height
      // 一页pdf显示html页面生成的canvas高度;
      var pageHeight = (contentWidth / 592.28) * 841.89
      // 未生成pdf的html页面高度
      var leftHeight = contentHeight
      // 页面偏移
      var position = 0
      // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      var imgWidth = 595.28
      var imgHeight = (592.28 / contentWidth) * contentHeight
      var pageData = canvas.toDataURL('image/jpeg', 1.0)
      // eslint-disable-next-line
      var PDF = new jsPDF('', 'pt', 'a4')
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      } else {
        while (leftHeight > 0) {
          PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight -= pageHeight
          position -= 841.89
          if (leftHeight > 0) {
            PDF.addPage()
          }
        }
      }
      PDF.save(name + '.pdf') //  这里是导出的文件名
      // Message.success('导出成功')
    })
  } catch (error) {
    message.error('导出失败')
  }
}
