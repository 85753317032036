// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}
import registerRoutes from './module/index.js'
// 路由组件注册
const routerMap = {
  login: {
    authority: '*',
    path: '/login',
    subName: '登录',
    component: () => import('@/pages/login')
  },
  root: {
    path: '/',
    name: 'root',
    subName: '根页面',
    redirect: '/login',
    component: view.tabs
  },
  home: {
    path: '/home',
    name: 'home',
    // subName: '工作台',
    // icon: 'home',
    component: () => import('@/pages/home/index')
  },
  // 定义返回的路由集合
  ...registerRoutes,
  result: {
    name: 'result',
    subName: '结果页',
    icon: 'check-circle-o',
    component: view.page
  },
  exception: {
    name: 'exception',
    subName: '异常页',
    icon: 'warning',
    component: view.blank
  },
  exp403: {
    authority: '*',
    name: 'exp403',
    subName: 'exp403',
    path: '403',
    component: () => import('@/pages/exception/403')
  },
  exp404: {
    name: 'exp404',
    subName: 'exp404',
    path: '404',
    component: () => import('@/pages/exception/404')
  },
  exp500: {
    name: 'exp500',
    subName: 'exp500',
    path: '500',
    component: () => import('@/pages/exception/500')
  }
}
export default routerMap
