<!--
 * @Description: 时间选择器
 * @Author: kcz
 * @Date: 2020-01-11 17:30:48
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-13 09:36:44
 -->
<template>
  <a-time-picker
    :style="`width:${record.options.width}`"
    :disabled="record.options.disabled || parentDisabled"
    :allow-clear="record.options.clearable"
    :placeholder="record.options.placeholder"
    :format="record.options.format"
    :disabled-hours="disabledHours"
    :disabled-minutes="disabledMinutes"
    :disabled-seconds="disabledSeconds"
    :value="time"
    @change="handleSelectChange"
  />
</template>
<script>
import moment from 'moment'
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['record', 'value', 'parentDisabled'],
  computed: {
    time() {
      if (!this.value) {
        return undefined
      } else {
        return moment(this.value, this.record.options.format)
      }
    }
  },
  methods: {
    // 禁用小时的list
    disabledHours() {
      return this.record.options.disabledHours || []
    },
    // 禁用分钟的list
    disabledMinutes(selectHours) {
      const { disabledMinutes, disabledHours } = this.record.options
      if (!disabledMinutes) {
        return []
      }
      if (selectHours === disabledHours[0] - 1) {
        return disabledMinutes
      } else {
        return []
      }
    },
    // 禁用秒的list
    disabledSeconds(selectedHour, selectedMinute) {
      const { disabledMinutes, disabledHours, disabledSeconds } = this.record.options
      if (!disabledSeconds) {
        return []
      }
      if (selectedHour >= disabledHours[disabledHours.length - 1] && selectedMinute >= disabledMinutes[disabledMinutes.length - 1]) {
        return disabledSeconds
      } else {
        return []
      }
    },
    handleSelectChange(val) {
      let time
      if (!val) {
        time = ''
      } else {
        time = val.format(this.record.options.format)
      }
      this.$emit('change', time)
      this.$emit('input', time)
    }
  }
}
</script>
