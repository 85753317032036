// 人员模块-证明
import {
  USER,
  BASE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 待开具列表
export async function waitIssueList(params) {
  return request(`${USER}/applyProve/getWaitIssueList`, METHOD.GET, params)
}

// 已开具列表
export async function alreadyIssueList(params) {
  return request(`${USER}/applyProve/getAlreadyIssueList`, METHOD.GET, params)
}
// 离职删除
export async function deleteCertificateEntry(params) {
  return request(`${USER}/applyProve/deleteStaffApplyProve`, METHOD.POST, params)
}
// 获取模板类型
export async function getTemType(params) {
  return request(`${USER}/proveTemp/getProveTempListByStatus`, METHOD.GET, params)
}
// 批量开具
export async function openerProveBatch(params) {
  return request(`${USER}/applyProve/openerProveBatch`, METHOD.POST, params)
}

// 开局证明
export async function openerProve(params) {
  return request(`${USER}/applyProve/openerProve`, METHOD.POST, params)
}

// 已开具证明详情
export async function getProveDetail(params) {
  return request(`${USER}/applyProve/getProveDetail`, METHOD.GET, params)
}
// 更新证明文件
export async function updateProveContent(params) {
  return request(`${USER}/applyProve/updateProveContent`, METHOD.POST, params)
}

// 待开具导出
export async function exportWaitIssue(params) {
  return request(`${USER + '/applyProve/exportWaitIssue'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 已开具导出
export async function exportAlreadyIssue(params) {
  return request(`${USER + '/applyProve/exportAlreadyIssue'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 根据文本内容导出pdf
export async function exportPdf(params) {
  return request(`${BASE + '/template/bigtext/exportPdf'}`, METHOD.POST, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 更新证明开具状态为已开具
export async function updateIssueStatus(params) {
  return request(`${USER + '/applyProve/updateIssueStatus'}`, METHOD.POST, params)
}
//  批量追加批量开具证明的申请数据
export async function addProveBatch(params) {
  return request(`${USER + '/applyProve/addProveBatch'}`, METHOD.POST, params)
}
