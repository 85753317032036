// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}

const routerMap = {
  culture: {
    name: 'culture',
    // subName: '文化',
    component: view.blank
  },
  cultureNotice: {
    name: 'cultureNotice',
    // subName: '公告',
    component: () => import('@/pages/culture/cultureNotice/index.vue')
  },
  cultureSystem: {
    name: 'cultureSystem',
    // subName: '制度',
    component: () => import('@/pages/culture/cultureSystem/index.vue')
  },
  cultureDynamic: {
    name: 'cultureDynamic',
    // subName: '动态',
    component: () => import('@/pages/culture/cultureDynamic/index.vue')
  },
  cultureCare: {
    name: 'cultureCare',
    // subName: '关怀',
    component: () => import('@/pages/culture/cultureCare/index.vue')
  }
}

export default routerMap
