// 自定义配置，参考 ./default/setting.config.js，需要自定义的属性在这里配置即可
module.exports = {
  theme: {
    color: '#507FF7',
    mode: 'light',
    success: '#00A870', // 成功色
    warning: '#ED7B2F', // 警告色
    error: '#E34D59', // 错误色
    grey: '#202A40', // 灰度色，用于字体颜色
    greyBg: '#8F93A1', // 灰度品牌色，用于表格背景色块、文本框、线条、搜索框。
    colorOpacitys: [1, 0.8, 0.6, 0.4, 0.1], // 主题色透明度分类
    greyOpacitys: [1, 0.8, 0.6, 0.4, 0.2, 0.1], // 主题灰色透明度分类
    greyBgOpacitys: [1, 0.5, 0.2, 0.1] // 主题品牌灰色透明度分类
  },
  multiPage: true,
  asyncRoutes: true, // 异步加载路由，true:开启，false:不开启
  animate: {
    name: 'lightSpeed',
    direction: 'left'
  }
}
