import CryptoJS from 'crypto-js'
const secretKeyOfWxh = 'YgAqeZq1eM#6#xTWkjtEGO%Ol4oxzBIlYI#k75HJml4bCr!F8YTqySDueKRY%1GB'
export const makePostSign = function(body, timestamp) {
  // make signature
  var str = 'body' + '=' + (body || {}) + '&' + 'timestamp' + '=' + timestamp + '&' + 'secret' + '=' + secretKeyOfWxh
  return CryptoJS.MD5(str).toString()
}

export const makeGetSign = function(object, timestamp) {
  // make signature
  var str = ''
  var keys = []
  for (var key in object) {
    if (object[key] !== undefined && object[key] !== null) {
      keys.push(key + '=' + object[key] + '&')
    }
  }
  keys.sort()
  for (var i = 0; i < keys.length; i++) {
    str += keys[i]
  }
  str += 'timestamp' + '=' + timestamp + '&' + 'secret' + '=' + secretKeyOfWxh
  return CryptoJS.MD5(str).toString()
}
