<!--
 * @Description: 将数据通过k-form-item组件解析，生成控件
 * @Author: kcz
 * @Date: 2019-12-30 00:37:05
 * @LastEditTime: 2022-06-18 15:32:11
 * @LastEditors: sueRimn
 * @FilePath: \k-form-design\packages\KFormDesign\module\formNode.vue
 -->
<template>
  <div
    class="drag-move-box"
    :class="{
      active: record.key === selectItem.key
    }"
    @click.stop="$emit('handleSelectItem', record)"
  >
    <div class="form-item-box">
      <kFormItem :form-config="config" :type="type" :record="record" />
    </div>
    <template v-if="env === 'development' || record.subType !== 'suite'">
      <div
        class="copy"
        :class="record.key === selectItem.key ? 'active' : 'unactivated'"
        @click.stop="$emit('handleCopy')"
      >
        <a-icon type="copy" />
      </div>
      <div
        class="delete"
        :class="record.key === selectItem.key ? 'active' : 'unactivated'"
        @click.stop="$emit('handleDelete')"
      >
        <a-icon type="delete" />
      </div>
    </template>
  </div>
</template>
<script>
/*
 * author kcz
 * date 2019-11-20
 * description 通过json生成的单个表单节点
 */
import kFormItem from '../../KFormItem/index'
export default {
  components: {
    kFormItem
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    selectItem: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      required: true
    },
    hideModel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'kForm'
    }
  },
  data() {
    return {
      env: process.env.VUE_APP_ENV
    }
  }
}
</script>
