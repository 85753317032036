
// 对浏览器样式进行清除
import './theme/reset.css'
import './theme/index.less'
// 全局loading的样式引入
import './assets/style/loading.less'
import 'vue-orgchart/dist/style.min.css'
import '@/utils/antd-design'
import store from './store'
import 'animate.css/source/animate.css'
// import Plugins from '@/plugins'
import { initI18n } from '@/utils/i18n'
import bootstrap from '@/bootstrap'
import { initRequest } from './utils/axios-interceptors'
import 'moment/locale/zh-cn'
import kFormDesign from './components/kFormDesign/packages/index'
import './components/kFormDesign/styles/form-design.less'
import './utils/lib-flexible'
// 颜色选择器
import vcolorpicker from 'vcolorpicker'
// 二次确认框
import CbReconfirm from '@/components/CbReconfirm'
import Vue from 'vue'
import App from './App.vue'
import * as filters from '@/utils/num-handle'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.$Reconfirm = CbReconfirm
Vue.use(vcolorpicker)
// workflow组织机构选择
// import FormControls from './components/workflow/FormControls/index.js'
import { initRouter } from './router'
const router = initRouter(store.state.setting.asyncRoutes)
const i18n = initI18n('CN', 'US')
Vue.config.productionTip = false
// Vue.use(Plugins)
Vue.use(kFormDesign)

// 打印
import Print from './utils/print'
Vue.use(Print)

bootstrap({ router, store, i18n, message: Vue.prototype.$message })
initRequest(router)
// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(oldString, newString) {
  return this.replace(new RegExp(oldString, 'gm'), newString)
}
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

// 回退的时候刷新页面，防止退出登录后点浏览器回退，还显示登录状态
window.onpageshow = function(event) {
  if (event.persisted) {
    window.location.reload()
  }
}
