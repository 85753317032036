<!--
 * @Date: 2022-05-23 09:19:21
 * @descriotion: 班次选择
-->
<template>
  <div>
    <!-- k-form中使用 -->
    <a-select
      v-if="record && record.options"
      v-model="value"
      :default-value="record.options.defaultValue"
      :placeholder="record.options.placeholder"
      :disabled="record.options.disabled"
      :allow-clear="record.options.clearable"
      :mode="record.options.multiple ? 'multiple' : 'default'"
      :show-search="record.options.showSearch"
      :get-popup-container="(triggerNode) => triggerNode.parentNode"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-option v-for="item in shiftData" :key="item.shiftId" :value="item.shiftId">
        {{ item.shiftName }}
      </a-select-option>
    </a-select>
    <!-- 正常引入的 -->
    <a-select
      v-else
      v-model="value"
      :default-value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :allow-clear="clearable"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-option v-for="item in shiftData" :key="item.shiftId" :value="item.shiftId">
        {{ item.shiftName }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getShiftListPage } from '@/services/attendance/shift.js'
export default {
  name: 'Shift',
  model: {
    prop: 'shiftValue',
    event: 'getShiftValue'
  },
  props: {
    shiftValue: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择班次'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      value: undefined,
      shiftData: []
    }
  },
  watch: {
    value() {
      if (this.value === '') this.value = undefined
    },
    shiftValue: {
      handler: function(val) {
        this.value = val
      },
      immediate: true
    }
  },
  mounted() {
    this.getShiftList()
  },
  methods: {
    async getShiftList() {
      const res = await getShiftListPage({ shiftEnable: 0 })
      this.shiftData = res.data
    },
    handleChange(value) {
      this.$emit('getShiftValue', value)
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped></style>
