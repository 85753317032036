<template>
  <a-select
    v-model="data"
    :mode="record.options.multiple?'multiple':'default'"
    :style="`width:${record.options.width}`"
    :disabled="record.options.disabled"
    :allow-clear="record.options.clearable"
    :placeholder="record.options.placeholder"
    :default-value="record.options.defaultValue || undefined"
    :show-search="record.options.showSearch"
    :get-popup-container="(triggerNode) => triggerNode.parentNode"
    :filter-option="filterOption"
    @change="handleChange"
  >
    <a-select-option v-for="item in rankData" :key="item.vacationTypeId" :value="item.vacationTypeId">
      {{ item.vacationTypeName }}
    </a-select-option>
  </a-select>
</template>
<script>
import { getVacationType } from '@/services/attendance/holidays.js'
export default {
  name: 'SelectVacation',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    return {
      rankData: [],
      data: undefined
    }
  },
  watch: {
    data() {

    },
    value() {
      this.data = this.value ? this.value : undefined
    }
  },
  mounted() {
    this.getJobSelectList()
  },
  methods: {
    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    async getJobSelectList() {
      const res = await getVacationType()
      this.rankData = res.data
    },
    handleChange(value) {
      this.$emit('change', value)
    }
  }
}
</script>
<style lang="less" scoped>

</style>
