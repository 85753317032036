/*
 * @Date: 2022年7月15日16:26:51
 * @descriotion:社保-档案
 */
import { INSURANCE } from '../api'
import { METHOD, request } from '@/utils/request'

// 在缴，待投保，待停保和停保的数量统计
export async function getInsureStatusCountApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getInsureStatusCount`, METHOD.GET, params)
}

// 获取待投保列表
export async function getWaitInsureListApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getWaitInsureList`, METHOD.GET, params)
}

// 忽略投保
export async function ignoreStaffInsureApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/ignoreStaffInsure`, METHOD.POST, params)
}

// 无需参保人员数量
export async function getIgnoreCountApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getIgnoreCount`, METHOD.GET, params)
}

// 获取无需参保人员列表
export async function getIgnoreInsureStaffListApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getIgnoreInsureStaffList`, METHOD.GET, params)
}

// 撤销忽略
export async function getTenantBillDetailApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/revocationIgnore`, METHOD.POST, params)
}

// 档案列表查询
export async function getArchivesListApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getArchivesList`, METHOD.GET, params)
}

// 获取参保记录年份
export async function getInsureRecordYearApi(params) {
  return request(`${INSURANCE}/insureBill/getInsureRecordYear`, METHOD.GET, params)
}

// 获取参保记录
export async function getInsureRecordApi(params) {
  return request(`${INSURANCE}/insureBill/getInsureRecord`, METHOD.GET, params)
}

// 停保（批量停保）
export async function getStopInsureApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/stopInsure`, METHOD.POST, params)
}

// 调整（批量调整）
export async function updateInsureApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/updateInsure`, METHOD.POST, params)
}

// 获取参保城市
export async function getCityListApi(params) {
  return request(`${INSURANCE}/insureCompany/getCityList`, METHOD.GET, params)
}

// 根据城市获取参保主体
export async function getCompanyByCityApi(params) {
  return request(`${INSURANCE}/insureCompany/getCompanyByCity`, METHOD.GET, params)
}

// 根据城市和投保类型查询租户方案
export async function getSchemeListByCityApi(params) {
  return request(`${INSURANCE}/scheme/getSchemeListByCity`, METHOD.GET, params)
}
// 获取员工投保详细信息（个人档案信息查询）
export async function getStaffInsureDetailApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getStaffInsureDetail`, METHOD.GET, params)
}
// 获取员工参保信息(调整时查询参保信息使用)
export async function getStaffInsureInfoApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getStaffInsureInfo`, METHOD.GET, params)
}

// 获取员工投保分类的投保情况信息（增投保时使用）
export async function getStaffInsureClassifyApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getStaffInsureClassify`, METHOD.GET, params)
}

// 投保（批量投保）
export async function insureApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/insure`, METHOD.POST, params)
}

// 导出员工档案列表
export async function getArchivesListExportApi(params) {
  return request(`${INSURANCE + '/staffInsureScheme/getArchivesListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 查询参保主体列表
export async function getInsureCompanyListApi(params) {
  return request(`${INSURANCE}/insureCompany/getInsureCompanyList`, METHOD.GET, params)
}

// 下载员工在缴参保方案模板
export async function exportBatchInsureTemplate(params) {
  return request(`${INSURANCE}/staffInsureScheme/exportBatchInsureTemplate`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 批量导入员工在缴参保方案
export async function batchImportInsureScheme(params) {
  return request(`${INSURANCE}/staffInsureScheme/batchImportInsureScheme`, METHOD.POST, params)
}

// 批量导入员工在缴参保方案
export async function exportInsureBaseApi(params) {
  return request(`${INSURANCE + '/staffInsureScheme/exportInsureBase'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 校验调整方案的员工的本月账单状态
export async function checkChangStaffSchemeApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/checkChangStaffScheme`, METHOD.GET, params)
}

// 校验停保月份是否存在补缴调差
export async function checkStopMonthExistSupplyApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/checkStopMonthExistSupply`, METHOD.GET, params)
}

// 查询投保/停保时账单提醒状态
export async function getInsureBillRemindStatusApi(params) {
  return request(`${INSURANCE}/staffInsureScheme/getInsureBillRemindStatus`, METHOD.GET, params)
}
