<template>
  <button
    ref="btn"
    class="ant-btn"
    type="button"
    :class="type"
    :disabled="disabled"
    @click="handleClick"
  >
    <!-- @focus="focus" -->
    <svg v-if="type === 'iconBtn' && icon" class="iconpark-icon"><use :href="`#${icon}`" /></svg>
    <a-icon v-if="type === 'iconBtn' && anticon" :type="anticon" />
    <div v-else class="btn-content">
      <svg v-if="prefix" class="iconpark-icon prefix"><use :href="`#${prefix}`" /></svg>
      <a-icon v-if="antprefix" class="prefix" :type="antprefix" />
      <slot />
      <svg v-if="suffix" class="iconpark-icon suffix"><use :href="`#${suffix}`" /></svg>
      <a-icon v-if="antsuffix" class="suffix" :type="antsuffix" />
    </div>
  </button>
</template>

<script>
// import { debounce } from '@/utils/index.js'
export default {
  name: 'Button',
  props: {
    // 按钮类型: default: 默认按钮（白底黑字），primary：蓝底白字，link: 链接式文本按钮 蓝字
    // iconBtn: 图标按钮（有边框，只有图标没有文本）
    type: {
      type: String,
      default: 'default'
    },
    // 按钮是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 单图标按钮的图标名
    icon: { // iconpark
      type: String,
      default: ''
    },
    anticon: { // antd icon
      type: String,
      default: ''
    },
    // 前置图标
    prefix: { // iconpark
      type: String,
      default: ''
    },
    antprefix: { // antd icon
      type: String,
      default: ''
    },
    // 后置图标
    suffix: { // iconpark
      type: String,
      default: ''
    },
    antsuffix: { // antd icon
      type: String,
      default: ''
    },
    isBlur: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      clicked: false
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keydown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keydown, false)
  },
  methods: {
    focus() {
      console.log('focus', this.$refs.btn)
      if (this.isBlur) this.$refs.btn.$el.blur()
    },
    keydown(e) {
      if (e.code === 'Enter') {
        this.$emit('enter', e)
      }
    },
    handleClick(e) {
      this.clicked = true
      this.$emit('click', e)
      this.$refs.btn.addEventListener('click', this.throttle(this.moving, 80))
    },
    // handleClick: debounce(function(e) {
    //   this.$emit('click', e)
    //   this.clicked = true
    //   this.$refs.btn.addEventListener('click', this.moving)
    // }, 30),
    moving() {
      if (this.clicked) {
        this.$refs.btn.classList.add('move')
      } else {
        this.$refs.btn.classList.remove('move')
      }
    },
    throttle(fn, wait) { // 节流
      let previous = 0
      return function() {
        const that = this
        const args = arguments
        const now = new Date().getTime()
        if (now - previous > wait) {
          previous = now
          fn.apply(that, args)
        }
      }
    }
    // 按钮点按动画效果  防抖
    // debounce(fun, wait = 100) {
    //   let timer
    //   return function() {
    //     const that = this
    //     const arg = arguments
    //     if (timer) clearTimeout(timer)
    //     timer = setTimeout(() => {
    //       fun.apply(that, arg)
    //     }, wait)
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
button{
  border: none;
  outline: none;
  background: unset;
}
.ant-btn{
  .font-size(14px);
  font-weight: normal;
  padding: 5px 10px;
  display: inline-block;
  border: 1px solid @sc-greyBg-20;
  border-radius: 4px;
  cursor: pointer;
  transition: all .2s linear;
  vertical-align: middle;
  position: relative;
  box-sizing: border-box;
  box-shadow: unset;
  overflow: hidden;
  &[disabled]{
    cursor: not-allowed;
  }
  .btn-content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconpark-icon{
    width: 16px;
    height: 16px;
    margin-top: 0;
    vertical-align: top;
  }
  .anticon{
    font-size: 16px;
    vertical-align: baseline;
  }
  .prefix{
    margin-right: 4px;
  }
  .suffix{
    margin-left: 4px;
  }
}
.ant-btn::after{ // .move(动态类名)
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: @sc-grey-100;
  z-index: -1;
  opacity: 0.2;
}
@keyframes moves {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes moves {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.default::after{
  background: @sc-primary-100;
  z-index: 0;
  opacity: 0.1;
}
.primary::after{
  background: @sc-grey-100;
  z-index: 0;
  opacity: 0.2;
}
.default:active::after, .primary:active::after{
  animation: moves .06s ease-in-out forwards;
  -webkit-animation: moves .06s ease-in-out forwards;
}
.default[disabled]::after, .primary[disabled]::after{
  display: none;
}
.default{
  border-color: @sc-greyBg-20;
  background: #fff;
  color: @sc-grey-100;
  &:hover{
    border-color: @sc-primary-100;
    color: @sc-primary-100;
    .iconpark-icon, .anticon{
      color: @sc-primary-100 !important;
    }
  }
  &:active{
    border-color: @sc-primary-100;
    color: @sc-primary-100;
    background: transparent;
    .iconpark-icon, .anticon{
      color: @sc-primary-100 !important;
    }
  }
  &[disabled]{
    background: @sc-greyBg-10;
    border-color: @sc-greyBg-20;
    color: @sc-grey-40;
    .iconpark-icon, .anticon{
      color: @sc-grey-40 !important;
    }
  }
  .prefix{
    color: @sc-primary-100;
  }
}
.primary{
  border-color: @sc-primary-100;
  background: @sc-primary-100 !important;
  color: #fff !important;
  &:hover{
    border-color: @sc-primary-80;
    background: @sc-primary-80;
    .iconpark-icon, .anticon{
      color: #fff;
    }
  }
  &:active{
    background: @sc-primary-100;
    border-color: @sc-primary-100;
    color: #fff;
    .iconpark-icon, .anticon{
      color: #fff;
    }
  }
  &[disabled]{
    border-color: @sc-primary-40;
    background: @sc-primary-40;
    color: #fff;
    .iconpark-icon, .anticon{
      color: #fff;
    }
  }
  .iconpark-icon, .anticon{
    color: #fff;
  }
}
.iconBtn{
  border-color: @sc-greyBg-20;
  padding: 7px;
  &:hover{
    border-color: @sc-primary-100;
    color: @sc-primary-100;
    .iconpark-icon, .anticon{
      color: @sc-primary-100;
    }
  }
  &[disabled]{
    background: @sc-greyBg-10 !important;
    border-color: @sc-greyBg-20 !important;
    color: @sc-grey-40 !important;
    .iconpark-icon, .anticon{
      color: @sc-grey-40 !important;
    }
  }
  .iconpark-icon, .anticon{
    color: @sc-grey-80;
    margin: 0;
    vertical-align: baseline;
  }
}
.link{
  border: 0;
  color: @sc-primary-100;
  background: transparent;
  padding: 0;
  margin: 0;
  &:hover{
    color: @sc-primary-80;
    .iconpark-icon, .anticon{
      color: @sc-primary-80;
    }
  }
  &:active{
    color: @sc-primary-100;
    .iconpark-icon, .anticon{
      color: @sc-primary-100;
    }
  }
  &[disabled]{
    color: @sc-primary-40;
    background: transparent;
    .iconpark-icon, .anticon{
      color: @sc-primary-40;
    }
  }
  .iconpark-icon, .anticon{
    color: @sc-primary-100;
  }
}
.delete{
  color: @sc-error-100;
  &:hover{
    color: @sc-error-100;
    opacity: 0.8;
  }
  &[disabled]{
    color: @sc-error-50;
  }
  .iconpark-icon, .anticon{
    color: @sc-error-100;
  }
}
// 下拉菜单的下拉图标动态效果
.ant-dropdown-trigger{
  .suffix{
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
  }
}
.ant-dropdown-open .suffix{
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}
</style>
