// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}
const routerMap = {
  relationIndex: {
    name: 'relationIndex',
    // subName: '关系',
    component: view.blank
  },
  relationNotice: {
    name: 'relationNotice',
    // subName: '公告',
    component: () => import('@/pages/relationship/relationNotice')
  },
  relationSystem: {
    name: 'relationSystem',
    // subName: '制度',
    component: () => import('@/pages/relationship/relationSystem')
  },
  relationNews: {
    name: 'relationNews',
    // subName: '新闻',
    component: () => import('@/pages/relationship/relationNews')
  }
}

export default routerMap
