import { DICTIONARY } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取租户规模字典项
export async function getScale(params) {
  return request(`${DICTIONARY}/code/getScale`, METHOD.GET, params)
}

// 获取租户行业字典项
export async function getBusiness(params) {
  return request(`${DICTIONARY}/code/getBusiness`, METHOD.GET, params)
}
// 获取行业分组列表
export async function getBusinessList(params) {
  return request(`${DICTIONARY}/business/getBusinessList`, METHOD.GET, params)
}
// 获取租户地区字典项
export async function getArea(params) {
  return request(`${DICTIONARY}/code/getArea`, METHOD.GET, params)
}

// 获取树状地区
export async function getAreaTree(params, config = {}) {
  return request(`${DICTIONARY}/area/getAreaTree`, METHOD.GET, params, config)
}

// 条件查询地区
export async function getBaseArea(params) {
  return request(`${DICTIONARY}/area/getArea`, METHOD.GET, params)
}

