<template>
  <Shift :record="record" @getShiftValue="handleChange" />
</template>
<script>
import Shift from '@/components/CbDropDownInput/shift'
export default {
  name: 'SelectShift',
  components: {
    Shift
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    }
    // filterOption(value, option) {
    //   return option.componentOptions.children[0].text.indexOf(value) >= 0
    // }
  }
}
</script>
<style lang="less" scoped>

</style>
