import { ORGAN_DUTY } from '../api'
import { METHOD, request } from '@/utils/request'

// &获取职务列表
export async function getDutyList(params) {
  return request(`${ORGAN_DUTY}/getDutyList`, METHOD.GET, params)
}

// 获取职务下拉列表
export async function getSelectDutyList(params) {
  return request(`${ORGAN_DUTY}/getSelectDutyList`, METHOD.GET, params)
}

// &新增职务
export async function createDuty(params) {
  return request(`${ORGAN_DUTY}/createDuty`, METHOD.POST, params)
}

// &更新职务
export async function updateDuty(params) {
  return request(`${ORGAN_DUTY}/updateDuty`, METHOD.POST, params)
}

// &删除职务
export async function deleteDuty(params) {
  return request(`${ORGAN_DUTY}/deleteDuty`, METHOD.POST, params)
}

// &批量删除职务
export async function batchDeleteDuty(params) {
  return request(`${ORGAN_DUTY}/batchDeleteDuty`, METHOD.POST, params)
}

// &导出职务列表
export async function exportDutyList(params) {
  return request(`${ORGAN_DUTY}/exportDutyList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
