<template>
  <a-config-provider :locale="locale" :get-popup-container="popContainer">
    <router-view />
  </a-config-provider>
</template>

<script>
import { enquireScreen } from './utils/util'
import { mapState, mapMutations } from 'vuex'
import themeUtil from '@/utils/themeUtil'
import { getIconUrl } from '@/services/login'

export default {
  name: 'App',
  data() {
    return {
      locale: {}
    }
  },
  computed: {
    ...mapState('setting', ['layout', 'theme', 'weekMode', 'lang'])
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val)
    },
    lang(val) {
      this.setLanguage(val)
      this.setHtmlTitle()
    },
    $route() {
      this.setHtmlTitle()
    },
    'theme.mode': function(val) {
      const closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage)
    },
    'theme.color': function(val) {
      const closeMessage = this.$message.loading(`您选择了主题色 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage)
    },
    'layout': function() {
      window.dispatchEvent(new Event('resize'))
    }
  },
  created() {
    this.setHtmlTitle()
    this.setLanguage(this.lang)
    enquireScreen(isMobile => this.setDevice(isMobile))
    // 控制分辨率
    // this.setZoom()
  },
  mounted() {
    this.getIconUrl()
    this.setWeekModeTheme(this.weekMode)
    // this.$nextTick(() => {
    //   // 监听浏览器变化
    //   window.addEventListener('resize', () => {
    //     this.setZoom()
    //   })
    // })
  },
  methods: {
    ...mapMutations('setting', ['setDevice']),
    async getIconUrl() {
      const res = await getIconUrl()
      const script = document.createElement('script')
      script.src = res.data
      document.body.append(script)
    },
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add('week-mode')
      } else {
        document.body.classList.remove('week-mode')
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      switch (lang) {
        case 'CN':
          this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
          break
        case 'HK':
          this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
          break
        case 'US':
        default:
          this.locale = require('ant-design-vue/es/locale-provider/en_US').default
          break
      }
    },
    setHtmlTitle() {
      const route = this.$route
      const key = route.path === '/' ? '' : route.meta.subName
      document.title = process.env.VUE_APP_NAME + ' | ' + key
    },
    popContainer() {
      return document.getElementById('popContainer')
    },
    // 控制页面保持在100%分辨率
    setZoom() {
      // 根据电脑分辨率进行页面缩放 放大
      const m = this.detectZoom() // 电脑分辨率
      document.body.style.zoom = 100 / Number(m) // 100-是 100%分辨率
      const ua = navigator.userAgent.toLowerCase()
      const width = window.innerWidth
      if (ua.indexOf('mac') > -1) {
        document.body.style.zoom = 1
      }
      document.body.style.zoom = Number(width) / 1920
    },
    // 获取当前页面的缩放值
    detectZoom() {
      var ratio = 0
      var screen = window.screen
      var ua = navigator.userAgent.toLowerCase()
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio
      } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI
        }
      } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth
      }

      if (ratio) {
        ratio = Math.round(ratio * 100)
      }
      return ratio
    }
  }
}
</script>
