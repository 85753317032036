import config from '@/config'
import { ADMIN } from '@/config/default'
import { formatFullPath } from '@/utils/i18n'
import { filterMenu } from '@/utils/authority-utils'
import { getLocalSetting } from '@/utils/themeUtil'
import deepClone from 'lodash.clonedeep'

// 这个函数中配置了是否优先使用本地的数据
const localSetting = getLocalSetting(false)
const customTitlesStr = sessionStorage.getItem(process.env.VUE_APP_TBAS_TITLES_KEY)
const customTitles = (customTitlesStr && JSON.parse(customTitlesStr)) || []
const recentVisitsStr = localStorage.getItem(process.env.VUE_APP_RECENTVISITS)
const recentVisits = recentVisitsStr ? JSON.parse(recentVisitsStr) : ''
export default {
  namespaced: true,
  state: {
    isMobile: false,
    animates: ADMIN.animates,
    palettes: ADMIN.palettes,
    // 访问记录
    recentVisits: recentVisits,
    pageMinHeight: 0,
    menuData: [],
    activatedFirst: undefined,
    customTitles,
    subMenuData: [],
    showSet: false, // 是否显示三级菜单设置
    subMenuTile: '',
    // 1、账号密码  2、验证码登录 3、忘记密码 4、设置密码 5、注册
    loginType: 1,
    isRefresh: true,
    globalMenu_jump: undefined,
    // 缓存的字典表的数据
    cacheDictionaryObj: {},
    // 适配导航
    ...localSetting,
    ...config
  },
  getters: {
    getshowSet(state) {
      return state.showSet
    },
    getCacheDictionaryObj(state) {
      return state.cacheDictionaryObj
    },
    getGlobalMenu_jump(state) {
      return state.globalMenu_jump
    },
    getIsRefresh(state) {
      return state.isRefresh
    },
    getLoginType(state) {
      return state.loginType * 1
    },
    menuData(state, getters, rootState) {
      if (state.filterMenu) {
        const { permissions, roles } = rootState.account
        return filterMenu(deepClone(state.menuData), permissions, roles)
      }
      return state.menuData
    },
    firstMenu(state, getters) {
      const { menuData } = getters
      if (menuData.length > 0 && !menuData[0].fullPath) {
        formatFullPath(menuData)
      }
      return menuData.map(item => {
        const menuItem = { ...item }
        delete menuItem.children
        return menuItem
      })
    },
    subMenu(state) {
      const { menuData, activatedFirst } = state
      if (menuData.length > 0 && !menuData[0].fullPath) {
        formatFullPath(menuData)
      }
      const current = menuData.find(menu => menu.fullPath === activatedFirst)
      return current && current.children || []
    },
    getSubMenuData(state) {
      return state.subMenuData.length > 0 ? state.subMenuData : ''
    },
    getSubMenuTile(state) {
      console.log(state)
      return state.subMenuTile
    },
    getRecentVisits(state) {
      return state.recentVisits
    }
  },
  mutations: {
    clearCacheDictionaryObj(state) {
      state.cacheDictionaryObj = {}
    },
    setShowSet(state) {
      state.showSet = !state.showSet
    },
    setCacheDictionaryObj(state, info) {
      state.cacheDictionaryObj[info.key] = info.data
    },
    setGlobalMenu_jump(state, val) {
      state.globalMenu_jump = val
    },
    setRecentVisits(state, recentVisits) {
      state.recentVisits = recentVisits
    },
    setIsRefresh(state, isRefresh) {
      state.isRefresh = isRefresh
    },
    setLoginType(state, type) {
      state.loginType = type
    },
    setSubMenuData(state, fullPath) {
      let subMenuData = []
      state.menuData.forEach(item => {
        if (item.fullPath === fullPath) {
          subMenuData = item.children
        }
      })
      state.subMenuData = subMenuData
    },
    setSubMenuTile(state, subMenuData) {
      state.subMenuTile = subMenuData
    },
    setDevice(state, isMobile) {
      state.isMobile = isMobile
    },
    setTheme(state, theme) {
      state.theme = theme
    },
    setLayout(state, layout) {
      state.layout = layout
    },
    setMultiPage(state, multiPage) {
      state.multiPage = multiPage
    },
    setAnimate(state, animate) {
      state.animate = animate
    },
    setWeekMode(state, weekMode) {
      state.weekMode = weekMode
    },
    setFixedHeader(state, fixedHeader) {
      state.fixedHeader = fixedHeader
    },
    setFixedSideBar(state, fixedSideBar) {
      state.fixedSideBar = fixedSideBar
    },
    setLang(state, lang) {
      state.lang = lang
    },
    setHideSetting(state, hideSetting) {
      state.hideSetting = hideSetting
    },
    correctPageMinHeight(state, minHeight) {
      state.pageMinHeight += minHeight
    },
    setMenuData(state, menuData) {
      state.menuData = menuData
    },
    setAsyncRoutes(state, asyncRoutes) {
      state.asyncRoutes = asyncRoutes
    },
    setPageWidth(state, pageWidth) {
      state.pageWidth = pageWidth
    },
    setActivatedFirst(state, activatedFirst) {
      state.activatedFirst = activatedFirst
    },
    setFixedTabs(state, fixedTabs) {
      state.fixedTabs = fixedTabs
    },
    setCustomTitle(state, { path, title }) {
      if (title) {
        const obj = state.customTitles.find(item => item.path === path)
        if (obj) {
          obj.title = title
        } else {
          state.customTitles.push({ path, title })
        }
        sessionStorage.setItem(process.env.VUE_APP_TBAS_TITLES_KEY, JSON.stringify(state.customTitles))
      }
    }
  }
}
