let isRoot = false
const roleList = JSON.parse(localStorage.getItem('admin.roles'))
if (roleList && roleList instanceof Array && roleList.length > 0) {
  roleList.forEach(item => {
    if (item.roleId * 1 === 1) {
      isRoot = true
    }
  })
}
const state = {
  // 自动计算组件和其他组件的关联关系
  autoComputeRelation: {},
  // 缓存一份儿表单组件作为条件字段用到的数量，以条件节点里面的referName做key
  formConditionKeyList: {},
  predefine: false,
  isRoot // 是否是管理员
}
const mutations = {
  setAutoComputeRelation(state, status) {
    state.autoComputeRelation = status
  },
  setFormConditionKeyList(state, status) {
    state.formConditionKeyList = status
  },
  setPredefine(state, status) {
    state.predefine = status
  }
}
const getters = {
  getAutoComputeRelation(state) {
    return state.autoComputeRelation
  },
  getFormConditionKeyList(state) {
    return state.formConditionKeyList
  },
  getPredefine(state) {
    return state.predefine
  },
  getIsRoot(state) {
    return state.isRoot
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  getters
}
