export const suiteInfoList = [
  {
    type: 'WKLeaveSuite',
    label: '请假套件',
    model: 'leave',
    icon: 'icon-write',
    child: [
      {
        label: '请假类型',
        model: 'type',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        parameter: 'vacationType',
        condition: true,
        isRequire: false,
        isPrint: false,
        isSummary: false
      },
      {
        label: '开始时间',
        model: 'startDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '结束时间',
        model: 'endDate',
        placeholder: ' 请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '时长',
        model: 'duration',
        element: '小时',
        placeholder: '自动计算',
        dataType: 'object',
        subDataType: 'simple',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '请假事由',
        model: 'reason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'object',
        isRequire: true,
        isPrint: true,
        isSummary: false
      }
    ]
  },
  {
    type: 'WKDiMissionSuite',
    label: '离职套件',
    model: 'diMission',
    icon: 'icon-write',
    child: [
      {
        label: '期望离职日期',
        model: 'expectTime',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '离职原因',
        model: 'reason',
        placeholder: '请填写',
        dataType: 'object',
        subDataType: 'object',
        parameter: 'leave_reasons',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '离职备注',
        model: 'handover',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      }
    ]
  },
  {
    type: 'WKOvertimeSuite',
    label: '加班套件',
    model: 'overtime',
    icon: 'icon-write',
    child: [
      {
        label: '加班类型',
        model: 'overtimeType',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        parameter: 'overtimeRuleList',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '开始时间',
        model: 'startDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '结束时间',
        model: 'endDate',
        placeholder: ' 请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '加班时长',
        model: 'applyTime',
        element: '小时',
        placeholder: '自动计算',
        dataType: 'simple',
        subDataType: 'simple',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '加班原因',
        model: 'applyReason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      }
    ]
  },
  {
    type: 'WKAppendPunchSuite',
    label: '补卡套件',
    model: 'appendPunch',
    icon: 'icon-write',
    child: [
      {
        label: '补卡日期',
        model: 'punchDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '归属时段',
        model: 'timeFrame',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '缺卡原因',
        model: 'reason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKAdjustShiftSuite',
    label: '调班套件',
    model: 'adjustShift',
    icon: 'icon-write',
    child: [
      {
        label: '调班日期',
        model: 'originalDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '被调班日期',
        model: 'targetDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '调班班次',
        model: 'class',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        isRequire: true,
        isPrint: true,
        isSummary: false
      },
      {
        label: '调班原因',
        model: 'reason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKChangeWorkSuite',
    label: '换班套件',
    model: 'changeWork',
    icon: 'icon-write',
    child: [
      {
        label: '替班人',
        model: 'targetOnJobId',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '换班日期',
        model: 'originalDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '还班日期',
        model: 'repayDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '换班班次',
        model: 'class',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        isRequire: true,
        isPrint: true,
        isSummary: false
      },
      {
        label: '换班原因',
        model: 'reason',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKOutsideSuite',
    label: '外出套件',
    model: 'outside',
    icon: 'icon-write',
    child: [
      {
        label: '开始时间',
        model: 'startDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '结束时间',
        model: 'endDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '外出时长',
        model: 'duration',
        element: '小时',
        placeholder: '自动计算',
        dataType: 'object',
        subDataType: 'simple',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '外出事由',
        model: 'reason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKBusinessTripSuite',
    label: '出差套件',
    model: 'businessTrip',
    icon: 'icon-write',
    child: [
      {
        label: '开始时间',
        model: 'startDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '结束时间',
        model: 'endDate',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '出差时长',
        element: '小时',
        model: 'duration',
        placeholder: '自动计算',
        dataType: 'object',
        subDataType: 'simple',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '出差城市',
        model: 'startOffArea',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        isRequire: true,
        isPrint: true,
        isSummary: false
      },
      {
        label: '出差原因',
        model: 'reason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKOfficialSuite',
    label: '转正套件',
    model: 'official',
    icon: 'icon-write',
    child: [
      {
        label: '入职日期',
        model: 'joinTime',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '计划转正日期',
        model: 'plainTime',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKTransferSuite',
    label: '调动套件',
    model: 'transfer',
    icon: 'icon-write',
    child: [
      {
        label: '原部门',
        model: 'preDept',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '原岗位',
        model: 'prePost',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      // {
      //   label: '原职务',
      //   model: 'preDuty',
      //   placeholder: '自动获取',
      //   dataType: 'simple',
      //   subDataType: 'simple',
      //   isRequire: false,
      //   isPrint: true,
      //   isSummary: false
      // },
      {
        label: '原岗位职级',
        model: 'preJob',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '原合同公司',
        model: 'preCompany',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '原工作地点',
        model: 'preWorkplace',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '调动类型',
        model: 'transferType',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        parameter: 'transfer_type',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '调入部门',
        model: 'transferDept',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: false
      },
      {
        label: '调入岗位',
        model: 'transferPost',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: false
      },
      // {
      //   label: '新职务',
      //   model: 'transferDuty',
      //   placeholder: '请选择新职务',
      //   dataType: 'simple',
      //   subDataType: 'object',
      //   isRequire: false,
      //   isPrint: true,
      //   isSummary: false
      // },
      {
        label: '新岗位职级',
        model: 'transferJob',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        condition: true,
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '新合同公司',
        model: 'transferCompany',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        parameter: 'contract_company',
        condition: true,
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '新工作地点',
        model: 'jobAddress',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        parameter: 'work_address',
        condition: true,
        isRequire: false,
        isPrint: true,
        isSummary: false
      },
      {
        label: '生效日期',
        model: 'transferTime',
        placeholder: '请选择',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '调动原因',
        model: 'reason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKCertifySuite',
    label: '证明套件',
    model: 'certify',
    icon: 'icon-write',
    child: [
      {
        label: '证明类型',
        model: 'certifyType',
        placeholder: '请选择',
        dataType: 'object',
        subDataType: 'object',
        parameter: 'prove_temp',
        condition: true,
        isRequire: true,
        isPrint: true,
        isSummary: true
      },
      {
        label: '申请理由',
        model: 'certifyReason',
        placeholder: '请填写',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: true,
        isPrint: true,
        isSummary: true
      }
    ]
  },
  {
    type: 'WKPaySalarySuite',
    label: '发薪套件',
    model: 'certify',
    icon: 'icon-write',
    child: [
      {
        label: '薪资表周期',
        model: 'period',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: true
      },
      {
        label: '发薪人数',
        model: 'perNum',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: true
      },
      {
        label: '人力成本',
        model: 'laborCostSum',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true,
        isSummary: true
      },
      {
        label: '应发工资',
        model: 'plansSum',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true
      },
      {
        label: '实发工资',
        model: 'realSum',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true
      },
      {
        label: '社保总额',
        model: 'insuranceSum',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true
      },
      {
        label: '个税总额',
        model: 'taxSum',
        placeholder: '自动获取',
        dataType: 'simple',
        subDataType: 'simple',
        isRequire: false,
        isPrint: true
      },
      {
        label: '薪资明细',
        model: 'subDetailFileId',
        placeholder: '自动获取',
        dataType: 'list_object',
        subDataType: 'list_object',
        isRequire: false,
        isPrint: true
      }
    ]
  }
  // {
  //   label: '招聘套件',
  //   model: 'recruit',
  //   child: [
  //     {
  //       label: '证明类型',
  //       model: 'transferDept',
  //       placeholder: '请选择',
  //       isRequire: false
  //     },
  //     {
  //       label: '申请理由',
  //       model: 'transferDept',
  //       placeholder: '请填写',
  //       isRequire: false
  //     }
  //   ]
  // },
  // {
  //   label: '合同套件',
  //   model: ''
  // },
  // {
  //   label: '录用套件',
  //   model: ''
  // }
]
import suite from '../workFolowComponent/suite'
/**
 * @description 首字母转大写
 * @param {*} str
 */
function firstToUpper(str) {
  return str.trim().replace(str[0], str[0].toUpperCase())
}

export const dataFormate = () => {
  return suiteInfoList.map(item => {
    const { type, model, label, child } = item
    return {
      type,
      subType: 'suite',
      model,
      label,
      labelHide: true,
      key: '',
      component: suite,
      options: {
        baseInfoList: child.map(key => {
          return {
            ...key,
            // 每个组件拼接一个相对应的type，这里为了在审批流程的条件字段使用
            type: `${type}${firstToUpper(key.model)}`
          }
        })
      }
    }
  })
}

export const parameterTypeList = () => {
  const res = []
  dataFormate().forEach(item => {
    item.options.baseInfoList.forEach(key => {
      if (key.parameter) {
        res.push({
          type: `${item.model}${firstToUpper(key.model)}`,
          parameter: key.parameter
        })
      }
    })
  })
  return res
}

export const suiteTypeList = () => {
  return suiteInfoList.map(item => {
    return item.type
  })
}

export const modelList = () => {
  return suiteInfoList.map(item => {
    return {
      model: item.model,
      label: item.label,
      type: item.type
    }
  })
}
