<template>
  <PerTreeSelect
    v-model="data"
    :mode-type="record.options.modeType"
    :placeholder="record.options.placeholder"
    :disabled="record.options.disabled"
    @getPerTreeValue="handleChange"
  />
</template>
<script>
import PerTreeSelect from '@/components/CbPerDepSelection/perTreeSelect/index'
export default {
  name: 'SelectStaff',
  components: {
    PerTreeSelect
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    return {
      data: []
    }
  },
  watch: {
    data() {

    },
    value() {
      this.data = this.value ? this.value : undefined
    }
  },
  mounted() {
    this.data = this.value ? this.value : undefined
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val)
    }
  }
}
</script>
