import commonCom from './commonCom'
import uploadImg from './upload-img'
import AutoCompute from './autocompute'
// 添加套件的方法
import { dataFormate } from '../core/suite'
const baseComponent = [
  {
    type: 'WKInput', // 表单类型
    subType: 'WKInput',
    icon: 'icon-write',
    label: '单行文字',
    labelHide: true,
    component: commonCom, // 组件
    options: {
      type: 'text',
      width: '100%', // 宽度
      maxLength: 20, // 内容最大长度
      baseInfoList: [
        {
          label: '单行文字',
          placeholder: '请输入', // 没有输入时，提示文字
          dataType: 'simple',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 数据字段
    key: '',
    isRequire: false, // 是否必填
    requireDisable: false, // 必填禁用
    isPrint: true, // 是否打印
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKTextarea', // 表单类型
    subType: 'WKTextarea',
    label: '多行文字', // 标题文字
    labelHide: true,
    icon: 'icon-edit',
    component: commonCom,
    options: {
      width: '100%', // 宽度
      maxLength: 1000, // 内容最大长度
      clearable: false,
      hidden: false, // 是否隐藏，false显示，true隐藏
      disabled: false,
      baseInfoList: [
        {
          label: '多行文字',
          placeholder: '请输入', // 没有输入时，提示文字
          dataType: 'simple',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 50 // 提示文字最多字数
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 数据字段
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKSelect', // 表单类型
    subType: 'WKSelect',
    label: '单项选择', // 标题文字
    labelHide: true,
    icon: 'icon-danxuan-cuxiantiao',
    component: commonCom, // 组件
    serverUrl: '', // 远程资源地址
    options: {
      visible: false, // 弹出框显示隐藏
      width: '100%', // 宽度
      multiple: false, // 是否允许多选
      disabled: false, // 是否禁用
      clearable: false, // 是否显示清除按钮
      hidden: false, // 是否隐藏，false显示，true隐藏
      baseInfoList: [
        {
          label: '单项选择',
          placeholder: '请选择', // 没有输入时，提示文字
          dataType: 'object',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 50 // 提示文字最多字数
        }
      ],
      optionLength: 100, // 选项最大数量
      options: [
        // 下拉选择项配置
        {
          value: '1',
          label: '下拉框1'
        },
        {
          value: '2',
          label: '下拉框2'
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '',
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKMultiSelect', // 表单类型
    subType: 'WKMultiSelect',
    label: '多项选择', // 标题文字
    labelHide: true,
    icon: 'icon-duoxuan1',
    component: commonCom, // 组件
    serverUrl: '', // 远程资源地址
    options: {
      visible: false, // 弹出框显示隐藏
      width: '100%', // 宽度
      multiple: true, // 是否允许多选
      disabled: false, // 是否禁用
      clearable: false, // 是否显示清除按钮
      hidden: false, // 是否隐藏，false显示，true隐藏
      baseInfoList: [
        {
          label: '多项选择',
          placeholder: '请选择', // 没有输入时，提示文字
          dataType: 'list_object',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 50 // 提示文字最多字数
        }
      ],
      optionLength: 100, // 选项最大数量
      options: [
        // 下拉选择项配置
        {
          value: '1',
          label: '下拉框1'
        },
        {
          value: '2',
          label: '下拉框2'
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '',
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKDate', // 表单类型
    subType: 'WKDate',
    label: '日期', // 标题文字
    labelHide: true,
    icon: 'icon-calendar',
    component: commonCom, // 组件
    options: {
      visible: false, // 弹出框显示隐藏
      width: '100%', // 宽度
      disabled: false, // 是否禁用
      hidden: false, // 是否隐藏，false显示，true隐藏
      clearable: false, // 是否显示清除按钮
      dataType: 0, // 日期类型 0 年月日 1 年月日时分秒
      baseInfoList: [
        {
          dataType: 'simple',
          label: '日期',
          placeholder: '请选择', // 没有输入时，提示文字
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '',
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKDateRange', // 表单类型
    subType: 'WKDateRange',
    label: '日期区间', // 标题文字
    labelHide: true,
    icon: 'icon-calendar',
    component: commonCom, // 组件
    serverUrl: '', // 远程资源地址
    options: {
      startTimeVisible: false, // 弹出框显示隐藏
      endTimeVisible: false, // 弹出框显示隐藏
      width: '100%', // 宽度
      disabled: false, // 是否禁用
      hidden: false, // 是否隐藏，false显示，true隐藏
      clearable: false, // 是否显示清除按钮
      dataType: 0, // 日期类型 0 年月日 1 年月日时分
      companyVal: '天',
      baseInfoList: [
        {
          label: '开始时间',
          placeholder: '请选择', // 没有输入时，提示文字
          dataType: 'simple',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          model: 'startTime',
          placeholderMaxLength: 20 // 提示文字最多字数
        },
        {
          label: '结束时间',
          placeholder: '请选择', // 没有输入时，提示文字
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          model: 'endTime',
          dataType: 'simple',
          placeholderMaxLength: 20 // 提示文字最多字数
        },
        {
          label: '时长',
          placeholder: '自动计算', // 没有输入时，提示文字
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          model: 'duration',
          dataType: 'simple',
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '',
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    autoCalc: false, // 自动计算时长的字段
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKNumber', // 表单类型
    subType: 'WKNumber',
    label: '数字', // 标题文字
    labelHide: true,
    icon: 'icon-number',
    component: commonCom, // 组件
    options: {
      width: '100%', // 宽度
      hidden: false, // 是否隐藏，false显示，true隐藏
      disabled: false, // 是否禁用
      maxLength: 20, // 内容最大长度
      baseInfoList: [
        {
          label: '数字',
          placeholder: '请输入', // 没有输入时，提示文字
          dataType: 'object',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ],
      companyVal: '个',
      // 个、根、块、把、张、本、台、瓶、打、盒、捆、箱、辆、枚、人、盆、杯、部、册、场、顿、份、幅、面、小时、分钟、秒
      companyList: ['个', '根', '块', '把', '张', '本', '台', '瓶', '打', '盒', '捆', '箱',
        '辆', '枚', '人', '盆', '杯', '部', '册', '场', '顿', '份', '幅', '面', '小时', '分钟', '秒'],
      ruleType: [],
      // 小数点后保留几位小数0-3
      floatNum: 2
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 数据字段
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKAmount', // 组件类型
    subType: 'WKAmount',
    label: '金额', // 组件名称
    labelHide: true,
    component: commonCom, // 组件
    options: {
      disabled: false, // 可选值
      width: '100%',
      maxLength: 20, // 内容最大长度
      clearable: true, // 可选值
      baseInfoList: [
        {
          label: '金额',
          placeholder: '请输入', // 没有输入时，提示文字
          dataType: 'object',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ],
      companyVal: '人民币(元)',
      // 人民币、美元、日元
      companyList: ['人民币(元)', '美元', '日元'],
      ruleType: [],
      // 小数点后保留几位小数0-3
      floatNum: 2
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 可选值
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  // {
  //   type: 'WKUploadImg',
  //   subType: 'WKUploadImg',
  //   label: '上传图片',
  //   icon: 'icon-image',
  //   component: uploadImg, // 组件
  //   labelHide: true, // 是否隐藏，false显示，true隐藏
  //   options: {
  //     textAlign: 'left',
  //     showRequiredMark: false,
  //     color: 'rgb(0, 0, 0)',
  //     fontFamily: 'SimHei',
  //     fontSize: '16pt',
  //     maxSize: 0.1 * 1024 * 1024, // 单位byte
  //     maxCount: 9,
  //     multiple: true,
  //     baseInfoList: [
  //       {
  //         label: '上传图片',
  //         labelErrorMsg: '', // 名称错误提示
  //         dataType: 'list_object',
  //         placeholder: '上传图片',
  //         labelMaxLength: 10, // 标题最多字数
  //         placeholderMaxLength: 20 // 提示文字最多字数
  //       }
  //     ]
  //   },
  //   hasErrorValid: false, // 是否验证失败
  //   key: '',
  //   model: '', // 可选值
  //   isRequire: false,
  //   requireDisable: false, // 必填禁用
  //   isPrint: true
  // },
  {
    type: 'WKUploadFile',
    subType: 'WKUploadFile',
    label: '上传附件',
    icon: 'icon-image',
    component: uploadImg, // 组件
    options: {
      textAlign: 'left',
      hidden: false, // 是否隐藏，false显示，true隐藏
      showRequiredMark: false,
      color: 'rgb(0, 0, 0)',
      fontFamily: 'SimHei',
      fontSize: '16pt',
      maxSize: 0.1 * 1024 * 1024, // 单位byte
      maxCount: 9,
      multiple: true,
      baseInfoList: [
        {
          label: '上传附件',
          labelErrorMsg: '', // 名称错误提示
          dataType: 'list_object',
          placeholder: '上传附件',
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    key: '',
    model: '', // 可选值
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true
  },
  {
    type: 'WKWordTable',
    subType: 'WKWordTable',
    label: '明细',
    labelHide: true,
    icon: 'icon-qiapian',
    list: [],
    options: {
      hidden: false,
      baseInfoList: [
        {
          label: '明细',
          labelErrorMsg: '' // 名称错误提示
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    key: '',
    model: ''
  },
  {
    type: 'WKText',
    subType: 'WKText',
    label: '文字说明',
    labelHide: true,
    icon: 'icon-zihao',
    component: commonCom, // 组件
    options: {
      textAlign: 'left',
      hidden: false, // 是否隐藏，false显示，true隐藏
      showRequiredMark: false,
      maxLength: 250, // 内容最大长度
      color: 'rgb(0, 0, 0)',
      fontFamily: 'Microsoft YaHei',
      fontSize: '14px',
      baseInfoList: [
        {
          label: '文字说明',
          placeholder: '文字说明', // 没有输入时，提示文字
          dataType: 'simple',
          placeholderMaxLength: 255 // 提示文字最多字数
        }
      ]
    },
    key: '',
    model: '',
    isPrint: true,
    isSummary: false // 是否是摘要字段
  },
  {
    type: 'WKDept',
    subType: 'WKDept',
    label: '部门',
    labelHide: true,
    icon: 'icon-juxingkaobei',
    component: commonCom, // 组件
    options: {
      width: '100%', // 宽度
      hidden: false, // 是否隐藏，false显示，true隐藏
      disabled: false, // 是否禁用
      multiple: true,
      baseInfoList: [
        {
          label: '部门',
          placeholder: '请选择', // 没有输入时，提示文字
          dataType: 'object',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 数据字段
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true
  },
  {
    type: 'WKEmployee',
    subType: 'WKEmployee',
    label: '员工',
    labelHide: true,
    icon: 'icon-tree',
    component: commonCom, // 组件
    options: {
      width: '100%', // 宽度
      hidden: false, // 是否隐藏，false显示，true隐藏
      disabled: false, // 是否禁用
      multiple: true,
      baseInfoList: [
        {
          label: '员工',
          placeholder: '请选择', // 没有输入时，提示文字
          dataType: 'list_object',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 数据字段
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true
  },
  {
    type: 'WKThreeLevel', // 组件类型
    subType: 'WKThreeLevel',
    label: '地理位置', // 组件名称
    labelHide: true,
    component: commonCom, // 组件
    options: {
      visible: false, // 弹出框显示隐藏
      disabled: false, // 可选值
      width: '100%',
      clearable: true, // 可选值
      baseInfoList: [
        {
          label: '地理位置',
          labelDisable: false, // 名称禁用
          labelErrorMsg: '', // 名称错误提示
          placeholder: '请选择省市区', // 没有输入时，提示文字
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20, // 提示文字最多字数
          model: 'province',
          dataType: 'simple'
        },
        {
          label: '地理位置',
          hide: true,
          model: 'city',
          dataType: 'simple'
        },
        {
          label: '地理位置',
          hide: true,
          model: 'district',
          dataType: 'simple'
        },
        {
          label: '详细地址',
          show: true, // 是否展示详细地址
          placeholder: '请输入详细地址', // 没有输入时，提示文字
          model: 'detail',
          dataType: 'simple'
        }
      ]
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 可选值
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isAddrRequire: false, // 详细地址是否必填
    addrRequireDisable: false, // 详细地址必填禁用
    isPrint: true
  },
  {
    type: 'WKAutoCompute', // 组件类型
    subType: 'WKAutoCompute',
    label: '自动计算', // 组件名称
    labelHide: true,
    icon: 'icon-zhage',
    component: AutoCompute, // 组件
    options: {
      visible: false, // 弹出框显示隐藏
      disabled: false, // 可选值
      width: '100%',
      clearable: true, // 可选值
      exp: '', // 表达式
      args: [], // 表达式要计算的控件id
      baseInfoList: [
        {
          label: '计算公式',
          labelErrorMsg: '', // 名称错误提示
          dataType: 'object',
          labelDisable: false, // 名称禁用
          labelMaxLength: 10, // 标题最多字数
          placeholderMaxLength: 20 // 提示文字最多字数
        }
      ],
      ruleType: [],
      // 小数点后保留几位小数0-3
      floatNum: 2
    },
    hasErrorValid: false, // 是否验证失败
    model: '', // 可选值
    key: '',
    isRequire: false,
    requireDisable: false, // 必填禁用
    isPrint: true,
    isSummary: false // 是否是摘要字段
  }
]
// let allComponents = []
// if (process.env.VUE_APP_ENV === 'development') {
//   allComponents = [...baseComponent, ...dataFormate()]
// } else {
//   allComponents = baseComponent
// }
export default [...baseComponent, ...dataFormate()]
