import { ORGAN_RANK } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询职级分类列表
export async function getRankTypeList(params) {
  return request(`${ORGAN_RANK}/getRankTypeList`, METHOD.GET, params)
}

// 查询职级分类数量统计
export async function getRankStatistics(params) {
  return request(`${ORGAN_RANK}/getRankStatistics`, METHOD.GET, params)
}

// 查询职级列表
export async function getRankList(params) {
  return request(`${ORGAN_RANK}/getRankList`, METHOD.GET, params)
}

// 新增职级分类
export async function createRankType(params) {
  return request(`${ORGAN_RANK}/createRankType`, METHOD.POST, params)
}

// 修改职级分类
export async function updateRankType(params) {
  return request(`${ORGAN_RANK}/updateRankType`, METHOD.POST, params)
}

// 删除职级分类
export async function deleteRankType(params) {
  return request(`${ORGAN_RANK}/deleteRankType`, METHOD.POST, params)
}

// 新增职级
export async function createRank(params) {
  return request(`${ORGAN_RANK}/createRank`, METHOD.POST, params)
}

// 修改职级信息
export async function updateRank(params) {
  return request(`${ORGAN_RANK}/updateRank`, METHOD.POST, params)
}

// 修改职级状态
export async function changeRankStatus(params) {
  return request(`${ORGAN_RANK}/changeRankStatus`, METHOD.POST, params)
}

// 导出职级信息
export async function rankExport(params) {
  return request(`${ORGAN_RANK}/export`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 查询职级列表
export async function getRankGroupList(params) {
  return request(`${ORGAN_RANK}/getRankGroupList`, METHOD.GET, params)
}

// 查询职级列表
export async function getRankGroupDropDownList(params) {
  return request(`${ORGAN_RANK}/getRankGroupDropDownList`, METHOD.GET, params)
}

// 根据职类获取职级下拉接口
export async function getRankDropDownList(params) {
  return request(`${ORGAN_RANK}/getRankDropDownList`, METHOD.GET, params)
}

// 单个删除职级
export async function deleteRank(params) {
  return request(`${ORGAN_RANK}/deleteRank`, METHOD.POST, params)
}

// 批量删除职级
export async function batchDeleteRank(params) {
  return request(`${ORGAN_RANK}/batchDeleteRank`, METHOD.POST, params)
}

export default { getRankTypeList, getRankStatistics, getRankList }
