import { ORGAN_POST } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询岗位分类
export async function getPostTypeList(params) {
  return request(`${ORGAN_POST + '/getPostTypeList'}`, METHOD.GET, params)
}

// 新增岗位分类
export async function postCreatePostType(params) {
  return request(`${ORGAN_POST + '/createPostType'}`, METHOD.POST, params)
}

// 修改岗位分类
export async function postUpdatePostType(params) {
  return request(`${ORGAN_POST + '/updatePostType'}`, METHOD.POST, params)
}

// 删除岗位分类
export async function postDeletePostType(params) {
  return request(`${ORGAN_POST + '/deletePostType'}`, METHOD.POST, params)
}

// 查询岗位分类数量统计
export async function postGetPostStatistics(params) {
  return request(`${ORGAN_POST + '/getPostTypeStatistics'}`, METHOD.GET, params)
}

// 查询岗位列表
export async function postGetPostList(params) {
  return request(`${ORGAN_POST + '/getPostList'}`, METHOD.GET, params)
}

// 岗位新增
export async function postCreatePost(params) {
  return request(`${ORGAN_POST + '/createPost'}`, METHOD.POST, params)
}

// 修改岗位
export async function postUpdatePost(params) {
  return request(`${ORGAN_POST + '/updatePost'}`, METHOD.POST, params)
}

// 删除岗位
export async function postDeletePost(params) {
  return request(`${ORGAN_POST + '/deletePost'}`, METHOD.POST, params)
}

// 删除岗位（批量）
export async function postDeletePostList(params) {
  return request(`${ORGAN_POST + '/deletePostList'}`, METHOD.POST, params)
}

// 岗位分组下拉接口
export async function getPostDropDownList(params) {
  return request(`${ORGAN_POST + '/getPostGroupDropDownList'}`, METHOD.GET, params)
}
// 迭代-新增岗位
export async function createPostJobType(params) {
  return request(`${ORGAN_POST + '/createPostJobType'}`, METHOD.POST, params)
}

// 迭代-新增更新
export async function updatePostJobType(params) {
  return request(`${ORGAN_POST + '/updatePostJobType'}`, METHOD.POST, params)
}
