<template>
  <div>
    <a-select
      v-if="record && record.options"
      v-model="dicValue"
      :default-value="record.options.defaultValue"
      :placeholder="record.options.placeholder"
      :allow-clear="record.options.clearable"
      :disabled="record.options.disabled"
      :mode="record.options.multiple ? 'multiple' : 'default'"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-option v-for="item in dictionariesData" :key="`${parameter}-${item.codeKey}`" :value="item.codeKey">
        {{ item.content }}
      </a-select-option>
    </a-select>
    <a-select
      v-else
      v-model="dicValue"
      :get-popup-container="getPopupContainer"
      :placeholder="placeholder"
      :allow-clear="clearable"
      :disabled="disabled"
      :mode="mode"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-option v-for="item in dictionariesData" :key="`${parameter}-${item.codeKey}`" :value="item.codeKey">
        {{ item.content }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getTenantCode } from '@/services/dropDownInput/index.js'
import { contractCompany, workAddress } from '@/services/dictionary/index.js'
import { getTemType } from '@/services/user/certificate.js'
import { getVacationTypeList } from '@/services/attendance/holidays.js'
import { getoOvertimeRuleListApi } from '@/services/attendance/rule.js'
import { overtime_convert_type } from '@/pages/attendance/dict'
import { getEndyearBounsList } from '@/services/wage/account.js'
import { listGroup } from '@/services/wage/salaryGroup'
import { getInsureCompanyListApi } from '@/services/insurance/archives'
import { getSelectDutyList } from '@/services/organization/duty.js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'DictionariesInput',
  model: {
    prop: 'dictionariesValue',
    event: 'getDictionariesValue'
  },
  props: {
    dictionariesValue: {
      type: [String, Array, Number],
      default: ''
    },
    parameter: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    },
    record: {
      type: Object,
      default: () => {}
    },
    getPopupContainer: {
      type: Function,
      default: triggerNode => triggerNode.parentNode
    }
  },
  data() {
    function formate(list) {
      return list.map(item => {
        return {
          content: item.label,
          codeKey: item.value
        }
      })
    }
    return {
      dictionariesData: [],
      // 接口拉取数据
      pathList: {
        contract_company: {
          path: contractCompany,
          labelKey: 'companyName',
          valueKey: 'companyId'
        },
        work_address: {
          path: workAddress,
          labelKey: 'addressName',
          valueKey: 'addressId'
        },
        prove_status: {
          path: getTemType,
          labelKey: 'tempName',
          valueKey: 'proveTempId'
        },
        // 假期类型
        vacationType: {
          path: getVacationTypeList,
          labelKey: 'vacationTypeName',
          valueKey: 'vacationTypeId'
        },
        // 加班类型
        overtimeRuleList: {
          path: getoOvertimeRuleListApi,
          labelKey: 'ruleName',
          valueKey: 'overtimeRuleId'
        },
        // 报税主体
        getEndyearBounsList: {
          path: getEndyearBounsList,
          labelKey: 'tenantName',
          valueKey: 'taxpaySubjectId'
        },
        // 薪资组
        listGroup: {
          path: listGroup,
          labelKey: 'groupName',
          valueKey: 'groupId'
        },
        // 查询参保主体列表
        getInsureCompanyListApi: {
          path: getInsureCompanyListApi,
          labelKey: 'companyName',
          valueKey: 'companyId'
        },
        duty: {
          path: getSelectDutyList,
          labelKey: 'dutyName',
          valueKey: 'dutyId'
        }
      },
      // 固定数据
      fixDataList: {
        staff_status: [
          {
            content: '正式',
            codeKey: '1'
          },
          {
            content: '试用期',
            codeKey: '2'
          },
          {
            content: '离职',
            codeKey: '3'
          }
        ],
        // 加班补偿方式
        overtime_convert_type: formate(overtime_convert_type),
        entry_form_exam_status: [
          {
            content: '未填写',
            codeKey: '0'
          },
          {
            content: '审核中',
            codeKey: '1'
          },
          {
            content: '审核通过',
            codeKey: '2'
          }, {
            content: '审核驳回',
            codeKey: '3'
          }
        ]

      },
      dicValue: undefined
    }
  },
  computed: {
    ...mapGetters('setting', ['getCacheDictionaryObj']),
    paramType() {
      return this.parameter || this.record.options.parameter
    }
  },
  watch: {
    dictionariesValue(val) {
      this.defaultValRule()
    },
    // 切换类型重刷数据
    parameter() {
      this.init()
      this.defaultValRule()
    }
  },
  mounted() {
    this.init()
    this.defaultValRule()
  },
  methods: {
    ...mapMutations('setting', ['setCacheDictionaryObj']),
    defaultValRule() {
      if (!this.dictionariesValue && this.dictionariesValue !== 0) {
        this.dicValue = undefined
      } else if (this.dictionariesValue instanceof Array && this.dictionariesValue.length === 0) {
        this.dicValue = undefined
      } else {
        if (this.dictionariesValue instanceof Array) {
          this.dicValue = this.dictionariesValue.map((item) => String(item))
        } else {
          this.dicValue = String(this.dictionariesValue)
        }
      }
    },
    async init() {
      if (!this.paramType) return
      // 缓存字典值的内容，当重复获取相同的内容的时候减少拉取接口的数据
      if (this.getCacheDictionaryObj[this.paramType]) {
        this.dictionariesData = this.getCacheDictionaryObj[this.paramType]
      } else {
        // 非字典项
        const array = ['contract_company', 'prove_status', 'work_address', 'vacationType', 'overtimeRuleList', 'getEndyearBounsList', 'listGroup', 'getInsureCompanyListApi', 'duty']
        const fixDataArray = ['staff_status', 'shenpistatus', 'entry_form_exam_status']
        if (fixDataArray.includes(this.paramType)) {
          this.dictionariesData = this.fixDataList[this.paramType]
        } else if (array.includes(this.paramType)) {
          await this.getOptions()
        } else {
          await this.getDictionariesData()
          this.setCacheDictionaryObj({ key: this.paramType, data: this.dictionariesData })
        }
      }
    },
    async getOptions() {
      const res = await this.pathList[this.paramType]['path']()
      const { valueKey, labelKey } = this.pathList[this.paramType]
      this.dictionariesData = res.data.map((item, index) => {
        return {
          ...item,
          codeKey: String(item[valueKey]),
          content: item[labelKey]
        }
      })
      // 合同主体存在禁用状态，status 0 禁用 1 弃用  禁用不展示再下拉中
      if (this.parameter === 'contract_company') {
        this.dictionariesData = this.dictionariesData.filter(item => item.status)
      }
    },
    // 统一获取字典表
    async getDictionariesData() {
      const res = await getTenantCode({ codeName: this.paramType })
      this.dictionariesData = res.data.map((item) => {
        return {
          ...item,
          codeKey: String(item.codeKey)
        }
      })
    },
    handleChange(value) {
      this.$emit('getDictionariesValue', value)
      this.$emit('change', value)
      if (value instanceof Array) {
        const arr = []
        this.dictionariesData.forEach((item) => {
          if (value.includes(item.codeKey)) {
            arr.push({
              label: item.content,
              value: item.codeKey
            })
          }
        })
        // 第三个参数代表是否是多选
        this.$emit('getData', arr)
      } else {
        let label = ''
        this.dictionariesData.forEach((item) => {
          if (item.codeKey === value) {
            label = item.content
          }
        })
        this.$emit('getData', [{ value, label }])
      }
    }
  }
}
</script>
