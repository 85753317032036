<template>
  <div>
    <!-- 存在于k-form中 -->
    <a-select
      v-if="record && record.options"
      v-model="value"
      :default-value="record.options.defaultValue"
      :placeholder="record.options.placeholder"
      :allow-clear="record.options.clearable"
      :disabled="record.options.disabled"
      :mode="record.options.multiple ? 'multiple' : 'default'"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-opt-group v-for="item in postData" :key="item.postTypeId" :label="item.postTypeName">
        <a-select-option v-for="it in item.postList" :key="it.postId" :value="it.postId">
          {{ it.postName }}
        </a-select-option>
      </a-select-opt-group>
    </a-select>
    <!-- 正常引入使用 -->
    <a-select
      v-else
      v-model="value"
      :default-value="value"
      :placeholder="placeholder"
      :allow-clear="clearable"
      :disabled="disabled"
      :mode="mode"
      style="width: 100%"
      @change="handleChange"
    >
      <a-select-opt-group v-for="item in postData" :key="item.postTypeId" :label="item.postTypeName">
        <a-select-option v-for="it in item.postList" :key="it.postId" :value="it.postId">
          {{ it.postName }}
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </div>
</template>

<script>
import { getPostSelectList } from '@/services/dropDownInput/index.js'
import { getPostDropDownList } from '@/services/organization/post.js'
export default {
  name: 'Post',
  model: {
    prop: 'postValue',
    event: 'getPostValue'
  },
  props: {
    postValue: {
      type: [String, Array],
      default: undefined
    },
    placeholder: {
      type: String,
      default: '请选择岗位'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    record: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: 'default'
    },
    hasRelation: {
      type: Boolean,
      default: false
    },
    orgId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: undefined,
      postData: []
    }
  },
  watch: {
    value() {
      if (this.value === '') this.value = undefined
    },
    postValue: {
      handler: function(val) {
        this.value = !val ? undefined : val
      },
      immediate: true
    },
    orgId(newVal) {
      if (newVal) {
        this.getPostData()
        // this.$emit('clearPostInput', true)
      } else {
        this.postData = []
      }
    }
  },
  mounted() {
    this.getPostData()
  },
  methods: {
    async getPostData() {
      let res
      if (this.hasRelation) {
        if (!this.orgId) return
        res = await getPostDropDownList({ orgId: this.orgId })
      } else {
        res = await getPostSelectList()
      }
      this.postData = res.data.filter((item) => {
        return item.postList.length > 0
      })
    },
    handleChange(value) {
      // 单选
      if (this.mode === 'default') {
        let name = ''
        this.postData.forEach((item) => {
          item.postList.forEach((key) => {
            if (key.postId === value) {
              name = key.postName
            }
          })
        })
        this.$emit('getName', name)
        this.$emit('getPostValue', value)
      } else {
        // 多选
        const res = []
        if (value && value.length > 0) {
          this.postData.forEach((item) => {
            item.postList.forEach((key) => {
              if (value.includes(key.postId)) {
                res.push({
                  label: key.postName,
                  value: key.postId
                })
              }
            })
          })
          this.$emit('getPostValue', res)
        }
      }
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped></style>
