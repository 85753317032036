<template>
  <div>
    <div class="select-icon">
      <img v-if="isDev && type" :src="`${basePath}${type}.png`" alt="">
      <img v-else-if="!isDev && type" :src="`${basePath}${type}.png`" alt="">
      <!-- <svg v-else-if="isDev && type && type.includes('#')" class="iconpark-icon"><use :href="`${type}`" /></svg> -->
      <!-- <svg v-else class="iconpark-icon"><use :href="`${type}`" /></svg> -->
    </div>
    <a-popover v-model="iconVisible" trigger="click" placement="bottom" :auto-adjust-overflow="false">
      <template slot="content">
        <a-space>
          <template>
            <div v-for="(item, index) in iconList" :key="`icon${index}`" class="icon" @click="selectIcon(item.type)">
              <img v-if="isDev" :src="`${basePath}${item.type}.png`" alt="">
              <img v-else :src="`${basePath}${item.type}.png`" alt="">
              <!-- <svg v-else-if="isDev && item.type.includes('#')" class="iconpark-icon"><use :href="`${item.type}`" /></svg> -->
              <!-- <svg v-else class="iconpark-icon"><use :href="`${item.type}`" /></svg> -->
              <span v-if="isDev">{{ item.name }}</span>
            </div>
          </template>
        </a-space>
      </template>
      <a-button type="link" class="a-but">更改图标</a-button>
    </a-popover>
  </div>
</template>

<script>
export default {
  name: 'SelectIcon',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    return {
      type: undefined,
      iconVisible: false,
      basePath: process.env.VUE_APP_APP_STATIC + 'icons/'
    }
  },
  computed: {
    // 是否是开发环境
    isDev() {
      return process.env.VUE_APP_ENV === 'development'
    },
    iconList() {
      const predefineList = [
        {
          type: 'icon1',
          name: '报销'
        }, {
          type: 'icon12',
          name: '打卡'
        }, {
          type: 'icon2',
          name: '补卡'
        }, {
          type: 'icon3',
          name: '出差'
        }, {
          type: 'icon4',
          name: '调班'
        }, {
          type: 'icon5',
          name: '调岗'
        }, {
          type: 'icon6',
          name: '公章'
        }, {
          type: 'icon7',
          name: '加班'
        }, {
          type: 'icon18',
          name: '换班'
        }, {
          type: 'icon8',
          name: '离职'
        }, {
          type: 'icon9',
          name: '请假'
        }, {
          type: 'icon10',
          name: '外出'
        }, {
          type: 'icon11',
          name: '外勤'
        }, {
          type: 'icon20',
          name: '销出差'
        }, {
          type: 'icon13',
          name: '销假'
        }, {
          type: 'icon14',
          name: '销外出'
        }, {
          type: 'icon15',
          name: '证明'
        }, {
          type: 'icon16',
          name: '转正'
        }, {
          type: 'icon17',
          name: '调动'
        }, {
          type: 'icon19',
          name: '其他1'
        }, {
          type: 'icon21',
          name: '其他2'
        }, {
          type: 'icon22',
          name: '其他3'
        }
      ]
      const normalList = [
        {
          type: 'icon19',
          name: '其他1'
        }, {
          type: 'icon21',
          name: '其他2'
        }, {
          type: 'icon22',
          name: '其他3'
        }
      ]
      return this.isDev ? predefineList : normalList
    }
  },
  watch: {
    value() {
      this.type = this.value
    }
  },
  mounted() {
    this.type = this.value
  },
  methods: {
    selectIcon(type) {
      this.type = type
      this.iconVisible = false
      this.$emit('change', type)
    }
  }
}
</script>

<style lang="less" scoped>
.ant-space{
  display: flex;
  width: 200px;
  flex-wrap: wrap;
  .ant-space-item{
    width: 42px;
    height: 56px;
    margin-bottom: 15px;
    .icon{
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .iconpark-icon{
        width: 36px;
        height: 36px;
        display: inline-block;
        span{
          font-size: 12px;
        }
      }
      img{
        width: 36px;
      }
    }
    &:nth-child(4n){
      margin-right: 0 !important;
    }
    &:hover{
      .icon{
        color: @sc-primary-100;
      }
    }
  }
}
.a-but{
  vertical-align: top;
}
.select-icon{
  width: 32px;
  height: 32px;
  display: inline-flex;
  img{
    width: 100%;
  }
  .iconpark-icon{
    width: 32px;
    height: 32px;
  }
}
</style>
