<template>
  <div class="container" :style="{width: containerWidth}">
    <a-select v-model="ModelOptions.province" class="select_value" :disabled="provinceDisabled" placeholder="请选择" @change="handleProvinceChange">
      <a-select-option v-for="item in provinceList" :key="item.areaCode" :value="item.areaCode" :title="item.areaName">
        {{ item.areaName }}
      </a-select-option>
    </a-select>
    <a-select v-model="ModelOptions.city" class="select_value" :disabled="cityDisabled" placeholder="请选择" @change="handleCityChange">
      <a-select-option v-for="item in cityList" :key="item.areaCode" :value="item.areaCode" :title="item.areaName">
        {{ item.areaName }}
      </a-select-option>
    </a-select>
    <a-select v-if="isShowCounty" v-model="ModelOptions.county" class="select_value" :disabled="areaDisabled" placeholder="请选择" @change="handleCountyChange">
      <a-select-option v-for="item in countyList" :key="item.areaCode" :value="item.areaCode" :title="item.areaName">
        {{ item.areaName }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getBaseArea } from '@/services/dictionary/tenant.js'
// import { getAreaTree } from '@/services/dictionary/tenant.js'
export default {
  name: 'CbSelectArea',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 省禁用
    provinceDisabled: {
      type: Boolean,
      default: false
    },
    // 市禁用
    cityDisabled: {
      type: Boolean,
      default: false
    },
    // 区禁用
    areaDisabled: {
      type: Boolean,
      default: false
    },
    // 容器总宽度
    containerWidth: {
      type: String,
      default: '100%'
    },
    // 是否展示区
    areaShow: {
      type: Boolean,
      default: true
    },
    // 当只显示 省市 时 直辖市选择完省级自动填写市级  市级与省级的值相同（当areaShow为false时 governmentAuto的值生效 反之 governmentAuto的值恒为false）
    governmentAuto: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: () => {}
    },
    dataList: {
      type: Array,
      default: () => {}
    },
    value: {
      type: [Number, String, Object],
      default: ''
    }
  },
  data() {
    return {
      provinceList: [],
      cityList: [],
      countyList: [],
      ModelOptions: {
        province: this.dataList ? this.dataList[0] : undefined,
        city: this.dataList ? this.dataList[1] : undefined,
        county: this.dataList ? this.dataList[2] : undefined
      }
    }
  },
  computed: {
    isShowCounty() {
      return (this.record && Object.prototype.hasOwnProperty.call(this.record.options, 'areaShow') ? this.record.options.areaShow : this.areaShow)
    },
    government() {
      if (this.isShowCounty) {
        return false
      } else {
        return (this.record && Object.prototype.hasOwnProperty.call(this.record.options, 'governmentAuto') ? this.record.options.governmentAuto : this.governmentAuto)
      }
    }
  },
  watch: {
    dataList: {
      handler(val) {
        if (val) {
          const [province, city, county] = val
          this.dataEcho({ province, city, county }, true)
        }
      },
      immediate: true
    },
    // 编辑人员信息监听身份证变化调整省市区
    value: {
      handler(value) {
        if (this.dataType(value) === 'Object') {
          this.dataEcho(value, true)
        }
      },
      deep: true
    }
  },
  async created() {
    await this.initData()
    await this.kFormCom()
  },
  methods: {
    async initData() {
      const res = await getBaseArea({ level: 0 })
      if (res.code === 100) {
        this.provinceList = res.data
      }
    },
    async handleProvinceChange(value) {
      this.clearChangeValue(true)
      this.provinceList.forEach(item => {
        if (item.areaCode === value) {
          this.ModelOptions.province = item.areaCode
          this.ModelOptions.provinceName = item.areaName
        }
      })
      const res = await getBaseArea({ level: 1, parentCode: value })
      this.cityList = res.data
      const obj = {
        areaCode: this.ModelOptions.province,
        areaName: this.ModelOptions.provinceName
      }
      this.handlSpecial(obj, value)
      this.$emit('getChangeValue', this.ModelOptions.province)
    },
    async handleCityChange(value) {
      this.clearChangeValue(false)
      this.cityList.forEach(item => {
        if (item.areaCode === value) {
          this.ModelOptions.city = item.areaCode
          this.ModelOptions.cityName = item.areaName
        }
      })
      if (this.isShowCounty) {
        const res = await getBaseArea({ level: 2, parentCode: value })
        this.countyList = res.data
      }
      this.$emit('getChangeValue', this.ModelOptions.city)
      this.transmitData()
    },
    handleCountyChange(value) {
      this.countyList.forEach(item => {
        if (item.areaCode === value) {
          this.ModelOptions.county = item.areaCode
          this.ModelOptions.countyName = item.areaName
        }
      })
      this.$emit('getChangeValue', this.ModelOptions.area)
      this.transmitData()
    },
    // 传递地区编号数组 名称数组
    transmitData() {
      let codeStrandValue, stringStrandValue
      const { province, city, county, provinceName, cityName, areaName } = this.ModelOptions
      if (this.isShowCounty) {
        codeStrandValue = [province, city, county]
        stringStrandValue = [provinceName, cityName, areaName]
        this.$emit('change', county, codeStrandValue, stringStrandValue)
      } else {
        codeStrandValue = [province, city]
        stringStrandValue = [provinceName, cityName]
        this.$emit('change', city, codeStrandValue, stringStrandValue)
      }
      this.$emit('getStrandValue', codeStrandValue, stringStrandValue)
    },
    /**
     * 重新选择清空原来的值
     * @param {Boolean} pOc  true 清空 市 区   false 清空区
     */
    clearChangeValue(pOc) {
      this.countyList = []
      if (pOc) {
        this.cityList = []
        this.ModelOptions.city = undefined
        this.ModelOptions.cityName = undefined
      }
      this.ModelOptions.county = undefined
      this.ModelOptions.countyName = undefined
    },
    /**
     * @param {Object} obj
     */
    async handlSpecial(obj, value) {
      if (['110000', '120000', '310000', '500000'].includes(obj.areaCode)) {
        const res = await getBaseArea({ level: 1, parentCode: value })
        if (this.isShowCounty) {
          this.ModelOptions.city = obj.areaCode
          this.ModelOptions.cityName = obj.areaName
          this.countyList = res.data
          this.cityList = [obj]
        } else if (this.government) {
          this.ModelOptions.city = obj.areaCode
          this.ModelOptions.cityName = obj.areaName
          this.cityList = [obj]
        } else {
          this.ModelOptions.city = undefined
        }
      }
      this.transmitData()
    },
    closeClearValue() {
      this.ModelOptions = {
        province: undefined,
        city: undefined,
        county: undefined,
        provinceName: undefined,
        cityName: undefined,
        countyName: undefined
      }
      this.countyList = ''
      this.cityList = ''
    },
    async dataEcho(e, bool) {
      this.closeClearValue()
      let obj
      if (typeof e.province !== 'undefined') {
        if (bool) {
          obj = this.provinceList.find((i) => {
            return i.areaCode === e.province
          })
        } else {
          await getBaseArea({ level: 0, areaCode: e.province }).then((res) => {
            obj = res.data[0]
          })
        }
        // getBaseArea({ level: 0, areaCode: e.province }).then((res) => {
        this.ModelOptions.province = obj.areaCode
        this.ModelOptions.provinceName = obj.areaName
        if (['110000', '120000', '310000', '500000'].includes(e.province)) {
          if (this.isShowCounty) {
            this.cityList = [obj]
            this.ModelOptions.city = this.ModelOptions.province
            this.ModelOptions.cityName = this.ModelOptions.provinceName
            getBaseArea({ level: 1, parentCode: e.province }).then((res) => {
              this.countyList = res.data
              this.countyList.forEach((item) => {
                if (item.areaCode === e.county) {
                  this.ModelOptions.county = item.areaCode
                  this.ModelOptions.countyName = item.areaName
                }
              })
              this.transmitData()
            })
          } else {
            getBaseArea({ level: 1, parentCode: e.province }).then((res) => {
              if (e.province === e.city) {
                this.ModelOptions.city = this.ModelOptions.province
                this.ModelOptions.cityName = this.ModelOptions.provinceName
                this.cityList = [{ areaCode: this.ModelOptions.province, areaName: this.ModelOptions.provinceName }]
              } else {
                this.forfun(res, e.city)
              }
              this.transmitData()
            })
          }
        } else {
          getBaseArea({ level: 1, parentCode: e.province }).then((res) => {
            this.forfun(res, e.city)
            if (this.isShowCounty) {
              getBaseArea({ level: 2, parentCode: e.city }).then((res) => {
                this.countyList = res.data
                this.countyList.forEach((item) => {
                  if (item.areaCode === e.county) {
                    this.ModelOptions.county = item.areaCode
                    this.ModelOptions.countyName = item.areaName
                  }
                })
                this.transmitData()
              })
            } else {
              this.transmitData()
            }
          })
        }
        // })
      }
    },
    forfun(res, num) {
      this.cityList = res.data
      this.cityList.forEach((item) => {
        if (item.areaCode === num) {
          this.ModelOptions.city = item.areaCode
          this.ModelOptions.cityName = item.areaName
        }
      })
    },
    kFormCom() {
      if (this.ModelOptions.province) {
        return
      }
      if (this.dataType(this.value) === 'Object') {
        this.dataEcho(this.value, true)
      } else if (this.dataType(this.value) === 'String' && this.value) {
        const [province, city, county] = this.value.split(',')
        this.dataEcho({ province, city, county }, true)
      }
    },
    dataType(data) {
      return Object.prototype.toString.call(data).split(' ')[1].slice(0, -1)
    }
  }
}
</script>

<style scoped lang="less">
.container{
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  div:nth-child(n+2){
    margin-left: 4px;
  }
}
.select_value{
  width: 100%;
  overflow: hidden;
}
/deep/ .ant-select-selection{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/.ant-select-dropdown-menu{
  &::-webkit-scrollbar{
    width: 3px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: @sc-greyBg-20;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0,0,0,0);
    border-radius: 4px;
    background: #fff;
  }
}
</style>
