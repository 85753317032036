const varyColor = require('webpack-theme-color-replacer/client/varyColor')
const { generate } = require('@ant-design/colors')
const { ADMIN, ANTD } = require('../config/default')
const Config = require('../config')

const themeMode = ADMIN.theme.mode

// 获取 ant design 色系
function getAntdColors(color, mode) {
  const options = mode && mode === themeMode.NIGHT ? { theme: 'dark' } : undefined
  return generate(color, options)
}

// 获取功能性颜色
function getFunctionalColors(mode) {
  const options = mode && mode === themeMode.NIGHT ? { theme: 'dark' } : undefined
  let { success, warning, error, grey, greyBg } = ANTD.primary
  const { success: s1, warning: w1, error: e1, grey: g1, greyBg: gBg1 } = Config.theme
  success = success && s1
  warning = success && w1
  error = success && e1
  grey = grey && g1
  greyBg = greyBg && gBg1
  const successColors = generate(success, options)
  const warningColors = generate(warning, options)
  const errorColors = generate(error, options)
  return {
    success: successColors,
    warning: warningColors,
    error: errorColors,
    grey: [grey],
    greyBg: [greyBg]
  }
}

// 获取菜单色系
function getMenuColors(color, mode) {
  if (mode === themeMode.NIGHT) {
    return ANTD.primary.night.menuColors
  } else if (color === ANTD.primary.color) {
    return ANTD.primary.dark.menuColors
  } else {
    return [
      varyColor.darken(color, 0.93),
      varyColor.darken(color, 0.83),
      varyColor.darken(color, 0.73)
    ]
  }
}

// 获取主题模式切换色系
function getThemeToggleColors(color, mode) {
  // 主色系
  const mainColors = getAntdColors(color, mode)
  const primary = mainColors[5]
  // 辅助色系，因为 antd 目前没针对夜间模式设计，所以增加辅助色系以保证夜间模式的正常切换
  const subColors = getAntdColors(primary, themeMode.LIGHT)
  // 菜单色系
  const menuColors = getMenuColors(color, mode)
  // 内容色系（包含背景色、文字颜色等）
  const themeCfg = ANTD.theme[mode]
  let contentColors = Object.keys(themeCfg)
    .map((key) => themeCfg[key])
    .map((color) => (isHex(color) ? color : toNum3(color).join(',')))
  // 内容色去重
  contentColors = [...new Set(contentColors)]
  // rgb 格式的主题色
  const rgbColors = [toNum3(primary).join(',')]
  const functionalColors = getFunctionalColors(mode)
  return {
    primary,
    mainColors,
    subColors,
    menuColors,
    contentColors,
    rgbColors,
    functionalColors
  }
}

function toNum3(color) {
  if (isHex(color)) {
    return varyColor.toNum3(color)
  }
  let colorStr = ''
  if (isRgb(color)) {
    colorStr = color.slice(5, color.length)
  } else if (isRgba(color)) {
    colorStr = color.slice(6, color.lastIndexOf(','))
  }
  const rgb = colorStr.split(',')
  const r = parseInt(rgb[0])
  const g = parseInt(rgb[1])
  const b = parseInt(rgb[2])
  return [r, g, b]
}

function isHex(color) {
  return color.length >= 4 && color[0] === '#'
}

function isRgb(color) {
  return color.length >= 10 && color.slice(0, 3) === 'rgb'
}

function isRgba(color) {
  return color.length >= 13 && color.slice(0, 4) === 'rgba'
}

/**
 * 批量根据#颜色 和透明度，生成新的 #颜色
 * @param {*} hex 类似于#FFFFFF这种格式的颜色
 * @param {*} opacitys 透明度数组，如[1, 0.5, 0.2]
 * @returns 按透明度数组顺序，返回新hex颜色数组
 */
function batchTransHexWithOpacitys(hex, opacitys) {
  const hexList = []
  if (opacitys.length > 0) {
    for (let i = 0; i < opacitys.length; i++) {
      hexList.push(transHexWithOpacity(hex, opacitys[i]))
    }
  }
  return hexList
}

/**
 * 根据#颜色 和透明度，生成新的 #颜色
 * @param {*} hex 类似于#FFFFFF这种格式的颜色
 * @param {*} opacity 透明度，范围0-1
 * @returns 返回新的hex格式的颜色
 */
function transHexWithOpacity(hex, opacity) {
  let sColor = String(hex).toLowerCase()
  // 十六进制颜色值的正则表达式
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    const rgbaColor = 'rgba(' + sColorChange.join(',') + ',' + opacity + ')'
    return transRgbaToHex(rgbaColor)
  }
  return sColor
}

/**
 * rgba颜色转换成 #颜色
 * @param {*} color rgba格式的颜色，如rgba(255,255,255,1)
 * @returns hex格式颜色，如#FFFFFF
 */
function transRgbaToHex(color) {
  const values = color
    .replace(/rgba?\(/, '')
    .replace(/\)/, '')
    .replace(/[\s+]/g, '')
    .split(',')
  const a = parseFloat(values[3] || 1)
  const r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255)
  const g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255)
  const b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255)
  return (
    '#' +
    ('0' + r.toString(16)).slice(-2) +
    ('0' + g.toString(16)).slice(-2) +
    ('0' + b.toString(16)).slice(-2)
  )
}

module.exports = {
  isHex,
  isRgb,
  isRgba,
  toNum3,
  getAntdColors,
  getMenuColors,
  getThemeToggleColors,
  getFunctionalColors,
  transRgbaToHex,
  transHexWithOpacity,
  batchTransHexWithOpacitys
}
