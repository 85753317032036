export default {
  computed: {
    optionsLabel() {
      return this.record.options.baseInfoList[0].label
    },
    baseInfoList() {
      return this.record.options.baseInfoList
    }
  },
  watch: {
    'optionsLabel'(val) {
      this.record.label = val
    }
  }
}
