// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}

const routerMap = {
  process: {
    name: 'process',
    // subName: '审批',
    component: view.blank
  },
  processControl: {
    name: 'processControl',
    // subName: '审批管理',
    component: () => import('@/pages/process/control')
  },
  processCenter: {
    name: 'processCenter',
    // subName: '审批中心',
    component: () => import('@/pages/process/center')
  },
  processRole: {
    name: 'processRole',
    // subName: '审批角色',
    component: () => import('@/pages/process/role')
  }
}

export default routerMap
