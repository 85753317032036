<template>
  <Post
    v-model="data"
    :record="record"
    :has-relation="hasRelation"
    :org-id="idList && idList.orgId ? idList.orgId : ''"
  />
</template>
<script>
import Post from '@/components/CbDropDownInput/post'
export default {
  name: 'SelectPost',
  components: {
    Post
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  inject: ['hasRelation', 'idList', 'clearRankVal', 'disabledRank'],
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    return {
      data: ''
    }
  },
  watch: {
    data() {
      this.idList.orgId
      this.$emit('change', this.data)
    },
    value() {
      this.data = this.value
      if (this.hasRelation) {
        this.disabledRank(!this.value)
        this.clearRankVal()
      }
    },
    'idList.orgId'(val) {
      // 部门岗位职级强关联的情况下，查看是否需要清空数据
      // if (this.hasRelation && !val) {
      if (this.hasRelation) {
        this.data = ''
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.hasRelation) {
        if (this.record && this.record.options && !this.record.options.defaultValue) {
          this.disabledRank()
        }
        this.clearRankVal()
      }
      this.data = this.value
    })
  }
}
</script>
