// 跨域代理前缀
const API_PROXY_PREFIX = '/api'
const BASE_URL =
  process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : API_PROXY_PREFIX
// const BASE_URL = process.env.VUE_APP_API_BASE_URL
module.exports = {
  BASE: `${BASE_URL}`,
  LOGIN: `${BASE_URL}/hrsaas-auth`,
  ROUTES: `${BASE_URL}/routes`,
  DICTIONARY: `${BASE_URL}/hrsaas-base`,
  ORGAN_POST: `${BASE_URL}/hrsaas-base/post`, // 组织-岗位
  ORGAN_RANK: `${BASE_URL}/hrsaas-base/rank`, // 组织-职级
  ORGAN_ORG: `${BASE_URL}/hrsaas-base/org`, // 组织-架构
  ORGAN_DUTY: `${BASE_URL}/hrsaas-base/duty`, // 组织-职务
  ORGAN_REPORT: `${BASE_URL}/hrsaas-base/report`, // 组织-报表
  GLOBAL_SETTING: `${BASE_URL}/hrsaas-auth`, // 全局设置
  LOCATION_WORK: `${BASE_URL}/hrsaas-base`,
  ONESELF_SETTING: `${BASE_URL}/hrsaas-auth/oneself`, // 个人设置
  DISTRIBUTION: `${BASE_URL}/hrsaas-auth/licenses`, // 人数分配
  LOGICFLOW: `${BASE_URL}/process`,
  USER: `${BASE_URL}/hrsaas-base`,
  ROSTER: `${BASE_URL}/hrsaas-base/roster`, // 人员-花名册
  ATTENDACE: `${BASE_URL}/attendance`, // 考勤
  UPLOAD: `${BASE_URL}/hrsaas-storage`, // 上传
  MESSAGE_SETTING: `${BASE_URL}/hrsaas-notification`, // 消息模板
  IMG_PREVIEW: `${BASE_URL}/hrsaas-storage/image/thumbnail`, // 图片预览
  WAGE: `${BASE_URL}/payroll`,
  INSURANCE: `${BASE_URL}/insurance`, // 社保
  CULTURE: `${BASE_URL}/culture`, // 文化
  RECRUIT: `${BASE_URL}/recurit` // 招聘
}
