// 考勤-设置-假期
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取班次列表
export async function getShiftListPage(params) {
  return request(`${ATTENDACE}/config/shift/getShiftListPage`, METHOD.GET, params)
}

// 获取班次详情
export async function getShiftInfo(params) {
  return request(`${ATTENDACE}/config/shift/getShiftInfo`, METHOD.GET, params)
}

// 添加班次
export async function insertShift(params) {
  return request(`${ATTENDACE}/config/shift/insertShift`, METHOD.POST, params)
}

// 修改班次
export async function updateShift(params) {
  return request(`${ATTENDACE}/config/shift/updateShift`, METHOD.POST, params)
}

// 批量删除班次
export async function deleteShiftList(params) {
  return request(`${ATTENDACE}/config/shift/deleteShiftList`, METHOD.POST, params)
}

// 弹性设置文案
export async function getShiftFlexTypeDescribe(params) {
  return request(`${ATTENDACE}/config/shift/getShiftFlexTypeDescribe`, METHOD.POST, params)
}

// 计算工作时长
export async function getComputingWorkTime(params) {
  return request(`${ATTENDACE}/config/shift/getComputingWorkTime`, METHOD.POST, params)
}

// 班次列表-工作日/休息日
export async function getWorkShiftListApi(params) {
  return request(`${ATTENDACE}/config/shift/getShiftList`, METHOD.GET, params)
}

// 获取生效时间最晚日期
export async function getNextPeriodEndDateApi(params) {
  return request(`${ATTENDACE}/config/period/getNextPeriodEndDate`, METHOD.GET, params)
}
