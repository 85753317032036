<template>
  <div class="selectDataRange" @mouseover="mouseover" @mouseleave="mouseleave">
    <span
      tabindex="0"
      class="ant-calendar-picker timeRangeBox"
      min="-Infinity"
      max="Infinity"
      width="100%"
      range="true"
      clearable="true"
      placeholder="请选择"
      rangeplaceholder="开始时间,结束时间"
      format="YYYY-MM-DD"
      style="width: 100%;"
    >
      <span class="ant-calendar-picker-input ant-input">
        <a-date-picker
          v-model="startTime"
          :value-format="record.options.format || 'YYYY-MM-DD'"
          :format="record.options.format || 'YYYY-MM-DD'"
          :show-time="record.options.showTime?{ format: 'HH:mm' }:false "
          class="startTime"
          :disabled-date="startDisabledDate"
          :placeholder="record.options.rangePlaceholder[0]"
          style="min-width:44%"
          @change="onChange"
          @openChange="openChange"
        >
          <input
            :value="startTime ? startTime : ''"
            readonly="true"
            :placeholder="record.options.rangePlaceholder[0]"
            class="ant-calendar-range-picker-input"
          >
        </a-date-picker>
        <span class="ant-calendar-range-picker-separator"> ~ </span>
        <a-date-picker
          v-model="endTime"
          :format="record.options.format || 'YYYY-MM-DD'"
          :value-format="record.options.format || 'YYYY-MM-DD'"
          class="endTime"
          style="min-width:44%"
          :show-time="record.options.showTime?{ format: 'HH:mm' }:false"
          :disabled-date="endDisabledDate"
          :placeholder="record.options.rangePlaceholder[1]"
          @change="onChange"
          @openChange="openChange"
        >
          <input
            :value="endTime ? endTime : ''"
            readonly="true"
            :placeholder="record.options.rangePlaceholder[1]"
            class="ant-calendar-range-picker-input"
          >
        </a-date-picker>
        <span class="ant-calendar-picker-icon" />
      </span>
      <a-icon v-if="!closeHide" type="close-circle" theme="filled" class="clear" @click="clear" />
    </span>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'SelectDataRange',
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledData: {
      type: Boolean,
      default: false
    },
    dynamicData: {
      type: [Number, Object],
      default: () => {}
    }
  },
  data() {
    return {
      closeHide: true,
      startTime: undefined,
      endTime: undefined
    }
  },
  watch: {
    value() {
      this.startTime = this.value[0]
      this.endTime = this.value[1]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.startTime = this.value[0]
      this.endTime = this.value[1]
    })
  },
  methods: {
    mouseover() {
      if (this.startTime || this.endTime) {
        this.closeHide = false
      }
    },
    mouseleave() {
      this.closeHide = true
    },
    clear() {
      this.startTime = undefined
      this.endTime = undefined
      this.$emit('change', [])
    },
    onChange(val) {
      // this.time1 = this.startTime
      if (!this.record.options.showTime) {
        this.$emit('change', [this.startTime, this.endTime])
      }
    },
    startDisabledDate(current) {
      if (this.disabledData) {
        if (!this.endTime) return false
        return current && current.valueOf() > moment(this.endTime).valueOf()
      }
    },
    endDisabledDate(current) {
      if (this.disabledData) {
        if (!this.startTime) return false
        return current && current.valueOf() < moment(this.startTime).valueOf()
      }
    },
    openChange(status) {
      if (!status && this.startTime && this.endTime && this.record.options.showTime) {
        this.$emit('change', [this.startTime, this.endTime])
      }
    }
  }
}
</script>

<style lang="less" scoped>
.timeRangeBox{
  position: relative;
  .clear{
    position: absolute;
    top: 8px;
    right: 8px;
    color: rgb(183, 183, 183);
    &:hover{
      color: rgb(129, 129, 129);
      cursor: pointer;
    }
  }
}
.startTime, .endTime{
  width: 44%;
  text-align: center;
  position: relative;
}
.ant-calendar-range-picker-input{
  width: 100%;
}
</style>
