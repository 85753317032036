import { setFormDesignConfig } from '../components/kFormDesign/packages/index'
import selectIcon from '../components/KFromDesignCustom/selectIcon/index.js'
import selectStaff from '../components/KFromDesignCustom/selectStaff/index.js'
import selectVacation from '../components/KFromDesignCustom/selectVacation/index.js'
import selectDepartment from '../components/KFromDesignCustom/selectDepartment/index.js'
import selectRank from '../components/KFromDesignCustom/selectRank/index.js'
import selectDictionaries from '../components/KFromDesignCustom/selectDictionaries/index.js'
import selectPost from '../components/KFromDesignCustom/selectPost/index.js'
import selectShift from '../components/KFromDesignCustom/selectShift/index.js'
import selectDataRange from '../components/KFromDesignCustom/selectDataRange/index'
// import CbThreeLevel from '../components/KFromDesignCustom/CbThreeLevel/select.vue'
import SelectArea from '../components/CbSelectArea/index.vue'
import workFolowComponents from '../components/kFormDesign/packages/workFolowComponent/index'
export default () => {
  // 使用函数配置
  setFormDesignConfig({
    title: '点击或拖拽到面板添加组件',
    list: [
      ...workFolowComponents,
      {
        type: 'threeLevel', // 组件类型
        label: '地区选择', // 组件名称
        component: SelectArea, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择', // 可选值
          showLimit: ['city', 'area'],
          showSearch: false, // 可选值
          areaShow: true, // 可选  是否展示区级
          governmentAuto: false, // 可选 当只显示 省市 时 直辖市选择完省级自动填写市级  市级与省级的值相同（当areaShow为false时 governmentAuto的值生效 反之 governmentAuto的值恒为false）
          dataList: []
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择图标'
          }
        ]
      },
      {
        type: 'selectIcon', // 组件类型
        label: '选择icon', // 组件名称
        component: selectIcon, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择图标', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择图标'
          }
        ]
      },
      {
        type: 'selectVacation', // 组件类型
        label: '假期类型选择', // 组件名称
        component: selectVacation, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择假期类型', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择假期类型'
          }
        ]
      },
      {
        type: 'selectStaff', // 组件类型
        label: '员工选择', // 组件名称
        component: selectStaff, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择员工', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择员工'
          }
        ]
      },
      {
        type: 'selectDepartment', // 组件类型
        label: '部门选择', // 组件名称
        component: selectDepartment, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择部门', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择部门'
          }
        ]
      },
      {
        type: 'selectPost', // 组件类型
        label: '岗位选择', // 组件名称
        component: selectPost, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择岗位', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择岗位'
          }
        ]
      },
      {
        type: 'selectRank', // 组件类型
        label: '职级选择', // 组件名称
        component: selectRank, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择职级', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择职级'
          }
        ]
      },
      {
        type: 'selectShift', // 组件类型
        label: '班次选择', // 组件名称
        component: selectShift, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          parameter: '', // 必填值
          clearable: true, // 可选值
          placeholder: '请选择', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择'
          }
        ]
      },
      {
        type: 'selectDictionaries', // 组件类型
        label: '字典项查询', // 组件名称
        component: selectDictionaries, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择'
          }
        ]
      },
      {
        type: 'selectDataRange', // 组件类型
        label: '自己的日期区间选择', // 组件名称
        component: selectDataRange, // 组件
        options: {
          defaultValue: undefined, // 可选值
          multiple: false, // 可选值
          disabled: false, // 可选值
          width: '100%',
          clearable: true, // 可选值
          placeholder: '请选择', // 可选值
          showSearch: false // 可选值
        },
        model: '', // 可选值
        key: '',
        rules: [
          // 可选值
          {
            required: true,
            message: '请选择'
          }
        ]
      }
    ]
  })
}
