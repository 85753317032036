<!--
 * @Description: 折叠组件
 * @Author: kcz
 * @Date: 2020-01-13 00:37:54
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-07-15 11:33:28
 -->
<template>
  <draggable
    tag="ul"
    :value="list"
    v-bind="{
      group: { name: 'form-draggable', pull: 'clone', put: false },
      sort: false,
      animation: 180,
      ghostClass: 'moving'
    }"
    @start="handleStart($event, list)"
  >
    <template v-for="(val, index) in list">
      <li
        v-if="env === 'development' || !suiteTypeList.includes(val.type)"
        :key="index"
        @dragstart="$emit('generateKey', list, index)"
        @click="$emit('handleListPush', val)"
      >
        <svg v-if="val.icon" class="icon" aria-hidden="true">
          <use :xlink:href="`#${val.icon}`" />
        </svg>
        {{ val.label }}
      </li>
    </template>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable'
import { suiteTypeList } from '../../core/suite'
export default {
  name: 'CollapseItem',
  components: {
    draggable
  },
  // props: ['list'],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      suiteTypeList: suiteTypeList(),
      env: process.env.NODE_ENV
    }
  },
  methods: {
    handleStart(e, list) {
      this.$emit('start', list[e.oldIndex].type)
    }
  }
}
</script>
